@keyframes quote {
	0% {
		fill: #a149fc
	}

	25% {
		fill: #08f2f2
	}

	50% {
		fill: #a149fc
	}

	75% {
		fill: #08f2f2
	}

	100% {
		fill: #a149fc
	}
}

@keyframes quote1 {
	0% {
		fill: #08f2f2
	}

	25% {
		fill: #a149fc
	}

	50% {
		fill: #08f2f2
	}

	75% {
		fill: #a149fc
	}

	100% {
		fill: #08f2f2
	}
}

.osx-content {
	.creative-text {

		.quote {
			.line-content {
				max-width: 81%;

				&.line-1 {
					.line-dot {
						margin-left: -8px;
					}
				}

				.line-dot {
					margin-left: -10px;
				}

				.horizontal-line {
					margin-left: -10px;
				}
			}
		}

		.line-content {
			display: -webkit-box;
			-webkit-box-align: baseline;
		}

		.text-color {
			background: linear-gradient(45deg,
					#08f2f2 0%,
					#08f2f2 10%,
					$violet-1 25%,
					#08f2f2 37.5%,
					$violet-1 50%,
					#08f2f2 62.5%,
					$violet-1 75%,
					#08f2f2 90%,
					#08f2f2 100%,
				);
			background-size: 600% auto;
			animation: shine 10s linear infinite;
			color: #000;
			background-clip: text;
			-webkit-text-fill-color: transparent;

			.line-content {
				.line-dot {
					margin-right: -2px;
				}
			}

			.row-1 {
				display: -webkit-box;
				-webkit-box-align: baseline;
			}

			.creative {
				display: -webkit-box;
				-webkit-box-align: baseline;
			}
		}
	}
}

.creative-text {
	background: linear-gradient(45deg,
			$violet-1 0%,
			#08f2f2 12.5%,
			$violet-1 25%,
			#08f2f2 37.5%,
			$violet-1 50%,
			#08f2f2 62.5%,
			$violet-1 75%,
			#08f2f2 87.5%,
			$violet-1 100%,
		);
	background-size: 300% auto;
	animation: shine 8s linear infinite;
	color: #000;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0;
	position: relative;

	&.row {
		margin: 0;
	}

	.line-content {
		width: 86%;
		background: linear-gradient(45deg,
				#08f2f2 0%,
				#a149fc 25%,
				#08f2f2 50%,
				#a149fc 75%,
				#08f2f2 100%,
			);
		background-size: 300% auto;
		animation: shine 8s linear infinite;
		color: #000;
		background-clip: text;
		padding: 0;

		.line-dot {
			margin-bottom: 27px;
		}

		.horizontal-line {
			width: 100%;
			height: 2px;
			background: linear-gradient(90deg,
					#a149fc 0%,
					#08f2f2 25%,
					#a149fc 50%,
					#08f2f2 75%,
					#a149fc 100%,
				);
			background-size: 300% auto;
			animation: shine 10s linear infinite;
		}
	}

	.wrapper-text {
		padding: 0;
		flex: 0 0 73.666667%;
		max-width: 73.666667%;
	}

	.quote {
		position: relative;
		top: 20px;
		right: 5px;
		display: flex;
		font-size: 72px;
		align-items: center;
		justify-content: flex-end;
		flex: 0 0 27.333333%;
		max-width: 27.333333%;

		.quote-holder {
			padding: 0;
			margin-bottom: 27px;
			min-width: 12%;
		}

		.line-content {
			max-width: 88%;

			.line-dot {
				margin-left: -6px;
			}
		}

		svg {
			height: 35px;
			animation: quote 10s linear infinite;
		}
	}

	.quote-resever {
		display: flex;
		align-items: center;
		font-size: 72px;
		z-index: 2;
		padding: 0;
		width: 100%;
		position: relative;
		left: 75px;
		top: 40px;

		.quote-holder {
			margin-bottom: 30px;
			padding: 0;
		}

		.line-content {

			.line-dot {
				margin-right: -6px;
			}
		}

		svg {
			height: 25px;
			animation: quote1 10s linear infinite;
		}
	}

	.text-color {
		font-family: 'Montserrat ExtraBold';
		font-size: 30px;
		background: linear-gradient(45deg,
				$violet-1 0%,
				$violet-1 5%,
				#08f2f2 12.5%,
				$violet-1 25%,
				#08f2f2 37.5%,
				$violet-1 50%,
				#08f2f2 62.5%,
				$violet-1 75%,
				#08f2f2 87.5%,
				$violet-1 92%,
				$violet-1 100%,
			);
		background-size: 300% auto;
		animation: shine 10s linear infinite;
		color: #000;
		background-clip: text;
		-webkit-text-fill-color: transparent;

		line-height: 35px;
		padding-top: 48px;
		text-align: left;
		position: relative;

		.row-1 {
			display: flex;
			align-items: baseline;
		}

		.row-2 {
			margin-top: -40px;
		}

		.line-content {
			.line-dot {
				color: white;
				font-size: 70px;
			}
		}

		.we-are {
			min-width: 100px;
		}

		.we-are,
		.agency {
			font-size: 21px;
			font-family: 'Montserrat Bold';
		}

		.experiential {
			font-size: 59px;
		}

		.marketing {
			font-size: 37px;
		}

		.creative {
			display: flex;
			align-items: baseline;
			width: 100%;

			.text {
				font-size: 106px;
				line-height: 1.2;
				letter-spacing: 4.5px;
			}

		}
	}
}

@media screen and (max-width: 1640px) {
	.creative-text {

		.quote {

			.quote-holder {
				padding: 0;
				margin-bottom: 27px;
				min-width: 12%;
			}

			svg {
				height: 30px;
			}
		}

		.quote-resever {
			left: 65px;
			top: 30px;

			svg {
				height: 20px;
			}
		}

		.text-color {
			font-size: 25px;

			.row-2 {
				margin-top: -45px;
			}

			.we-are,
			.agency {
				font-size: 19px;
			}

			.experiential {
				font-size: 46px;
			}

			.marketing {
				font-size: 32px;
			}

			.creative {

				.text {
					font-size: 80px;
				}

			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.creative-text {
		.wrapper-text {
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
		}

		.quote {
			max-width: 30.33%;
			flex: 0 0 30.33%;

			.line-content {
				.line-dot {
					margin-top: -5px;
				}
			}

			.quote-holder {
				padding: 0;
				margin-bottom: 45px;
				min-width: 12%;
			}

			svg {
				height: 30px;
			}
		}

		.quote-resever {
			left: 100px;
			top: 30px;

			svg {
				height: 20px;
				width: 13px;
			}
		}

		.text-color {
			font-size: 25px;

			.row-2 {
				margin-top: -45px;
			}

			.we-are,
			.agency {
				font-size: 18px;
			}

			.we-are {
				min-width: 90px;
			}


			.experiential {
				font-size: 44px;
			}

			.marketing {
				font-size: 32px;
			}

			.creative {

				.text {
					font-size: 70px;
				}

			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.creative-text {

		.text-color {
			.row-2 {
				margin-top: -50px;
			}
		}

		.wrapper-text {
			max-width: 72.66%;
			flex: 0 0 72.66%;
		}

		.quote {
			max-width: 28.33%;
			flex: 0 0 28.33%;

			.line-content {
				max-width: 87%;

				.horizontal-line {
					height: 1px;
				}
			}

			svg {
				width: 16px;
				height: 25px;
			}
		}

		.quote-resever {
			top: 30px;
			left: 80px;

			svg {
				width: 12px;
				height: 18px;
			}
		}

		.text-color {

			.we-are {
				min-width: 80px;
				font-size: 17px;
			}

			.agency {
				font-size: 17px;
			}

			.experiential {
				font-size: 40px;
			}

			.marketing {
				font-size: 27px;
			}

			.creative {
				.text {
					font-size: 65px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.creative-text {

		.text-color {
			.row-2 {
				margin-top: -50px;
			}
		}

		.quote {
			max-width: 22.33%;
			flex: 0 0 22.33%;

			svg {
				width: 12px;
				height: 19px;
			}
		}

		.text-color {

			.we-are {
				font-size: 16px;
			}

			.agency {
				font-size: 16px;
			}
		}
	}

	.osx-content {
		.creative-text {
			.text-color {
				.line-content {
					.line-dot {
						margin-right: -6px;
					}
				}
			}
		}

		.quote {
			.line-content {
				&.line-1 {
					.line-dot {
						margin-left: -7px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.creative-text {
		&.row {
			margin-left: -150px;
			margin-right: -15px;
			align-items: center;
		}

		.text-color {
			padding-top: 0;

			.row-2 {
				margin-top: -50px;
			}
		}

		.quote,
		.quote-resever {
			.line-content {
				.horizontal-line {
					height: 1px;
				}
			}
		}

		.quote {
			max-width: 30.33%;
			flex: 0 0 30.33%;
			align-items: end;

			.quote-holder {
				position: relative;
				top: -40px;
			}

			.line-content {
				max-width: 50%;
			}

			svg {
				width: 16px;
				height: 25px;
			}
		}

		.quote-resever {
			top: 30px;
			left: 40px;

			svg {
				width: 12px;
				height: 18px;
			}
		}

		.quote-holder {
			min-width: 22px;
			text-align: right;
		}

		.text-color {

			.we-are {
				min-width: 75px;
				font-size: 16px;
			}

			.agency {
				font-size: 17px;
			}

			.experiential {
				font-size: 40px;
			}

			.marketing {
				font-size: 27px;
			}

			.creative {
				.text {
					font-size: 70px;
				}
			}

			.line-content {
				width: 82%;
			}
		}
	}

	.osx-content {
		.creative-text {
			.quote {
				.line-content {
					&.line-1 {
						.line-dot {
							margin-left: -6px;
						}
					}
				}
			}

			.text-color {
				.line-content {
					.line-dot {
						margin-right: -5px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	//Inherit form tablet 800
}

@media screen and (max-width: 576px) {
	.creative-text {
		&.row {
			margin-left: -15px;
			margin-right: -15px;
			flex-wrap: nowrap;
		}

		.text-color {
			line-height: 25px;

			.row-2 {
				margin-top: -55px;
			}
		}

		.quote {
			font-size: 38px;
			justify-content: flex-start;
			align-items: baseline;

			.quote-holder {
				top: unset;
			}

			&.row {
				padding-left: 0;
				width: 30%;
			}

			.quote-holder {
				margin-bottom: 42px;
			}

			.line-content {
				max-width: 66%;

				.horizontal-line {
					height: 1px;
				}

				.line-dot {
					margin-left: -3px;
					font-size: 42px;
				}
			}

			svg {
				@include box(10px, 16px);
			}
		}

		.quote-resever {
			top: 15px;
			left: 30px;

			svg {
				@include box(8px, 14px);
			}
		}

		.quote-holder {
			min-width: 25px;
		}

		.text-color {
			padding-top: 25px;

			.we-are {
				min-width: 35px;
				font-size: 8px;
			}

			.agency {
				font-size: 9px;
			}

			.experiential {
				font-size: 20px;
			}

			.marketing {
				font-size: 14px;
			}

			.creative {
				.text {
					font-size: 36px;
				}

				.quote-holder {
					min-width: 25px !important;
				}
			}

			.line-content {
				width: 70%;

				.horizontal-line {
					height: 1px;
				}

				.line-dot {
					font-size: 42px;
					margin-right: -5px;
				}
			}
		}

		.wrapper-text {
			margin-left: -20px;
			margin-top: -5px;
		}
	}

	.osx-content {
		.creative-text {
			.text-color {
				.line-content {
					.line-dot {
						margin-right: -3px;
					}
				}
			}

			.quote {
				.line-content {
					max-width: 68%;

					&.line-1 {
						.line-dot {
							margin-left: -4px;
						}
					}

					.horizontal-line {
						top: -1.5px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 412px) and (min-width: 376px) {
	.creative-text {
		.quote {
			svg {
				@include box(8px, 14px);
			}
		}

		.quote-resever {
			left: 45px;

			svg {
				@include box(7px, 12px);
			}
		}

		.quote-holder {
			min-width: 25px;
		}

		.text-color {
			.we-are {
				min-width: 42px;
			}

			.creative {
				.text {
					font-size: 30px;
					letter-spacing: 3px;
				}
			}

		}
	}
}

@media screen and (max-width: 375px) {
	.creative-text {
		.quote {
			svg {
				@include box(8px, 14px);
			}
		}

		.quote-resever {
			left: 45px;

			svg {
				@include box(7px, 12px);
			}
		}

		.quote-holder {
			min-width: 25px;
		}

		.text-color {
			.we-are {
				min-width: 38px;
			}

			.creative {
				.text {
					font-size: 30px;
					letter-spacing: 3px;
				}
			}

		}
	}
}

@media screen and (max-width: 350px) {
	.creative-text {
		.quote {
			svg {
				@include box(6px, 12px);
			}
		}

		.quote-resever {
			left: 45px;

			svg {
				@include box(6px, 11px);
			}
		}

		.quote-holder {
			min-width: 25px;
		}

		.text-color {
			.we-are {
				min-width: 25px;
				font-size: 8px;
			}

			.agency {
				font-size: 8px;
			}

			.experiential {
				font-size: 18px;
			}

			.marketing {
				font-size: 12px;
			}

			.creative {
				.text {
					font-size: 25px;
					letter-spacing: 3px;
				}
			}
		}
	}
}