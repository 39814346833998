.switch-wrapper {
	margin-left: 108px;
	margin-right: 135px;
	display: flex;
	justify-content: center;
	position: relative;

	.switch-line {
		height: 80px;
		width: 100%;
		border: 2.5px solid #01b5f6;
		border-radius: 50px;
	}

	.list-content {
		display: flex;
		padding: 0;
		color: white;
		font-size: 21px;
		font-family: 'Montserrat Regular';
		position: absolute;
		margin: 0;
		z-index: 1;

		&.top-content {
			top: -10px;
		}

		&.bottom-content {
			bottom: -10px;
		}

		li {
			display: flex;
			background: black;
			margin: 0 100px;
			align-items: center;
			justify-items: center;
			cursor: pointer;

			.circle {
				width: 26px;
				height: 26px;
				border-radius: 50%;
				border: 2px solid #01b5f6;
				margin-right: 20px;
				position: relative;

				&.active {
					&:after {
						content: '';
						position: absolute;
						width: 16px;
						height: 16px;
						top: 3px;
						left: 3px;
						border-radius: 50%;
						background: $yellow-main;
					}
				}
			}

			.text {
				margin-right: 20px;

				&.active {
					color: $yellow-main;
				}
			}
		}
	}
}

@media screen and (max-width: 1880px) and (min-width: 1640px) {
	.switch-wrapper {

		.list-content {
			font-size: 18px;

			li {
				margin: 0 80px !important;
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.switch-wrapper {
		margin-left: 90px;
		margin-right: 90px;

		.switch-line {
			height: 70px;
		}

		.list-content {
			font-size: 16px;

			li {
				margin: 0 60px !important;
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.switch-wrapper {
		margin-left: 72px;
		margin-right: 120px;

		.switch-line {
			height: 55px;
		}

		.list-content {
			font-size: 15px;

			li {
				.text {
					margin-right: 14px;
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.switch-wrapper {

		.list-content {

			&.top-content {
				top: -12px;
			}

			li {
				.text {
					margin-right: 13px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.switch-wrapper {
		margin-left: 40px;
		margin-right: 80px;

		.list-content {
			font-size: 11px;

			&.top-content {
				top: -10px;
			}

			li {
				.circle {
					margin-right: 7px;
					width: 22px;
					height: 22px;

					&.active {
						&:after {
							width: 13px;
							height: 13px;
						}
					}
				}

				.text {
					margin-right: 5px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.switch-wrapper {
		margin-left: 30px;
		margin-right: 30px;

		.switch-line {
			height: 80px;
		}

		.list-content {
			font-size: 14px;

			&.top-content {
				top: -12px;
				text-align: center;
			}

			&.bottom-content {
				bottom: -12px;
				text-align: center;
			}

			li {
				.circle {
					width: 12px;
					height: 12px;
					margin: 0;
					padding: 4px;

					&.active {
						&::after {
							width: 8px;
							height: 8px;
							top: 0;
							left: 0;
						}
					}
				}

				.text {
					margin: 0 5px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {

	/* Inherit from 800 */
	.switch-wrapper {
		.list-content {
			&.bottom-content {
				bottom: -19px;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.switch-wrapper {
		.switch-line {
			height: 45px;
			border: 1px solid #01b5f6;
		}

		.list-content {
			font-size: 8px;

			&.top-content {
				top: -8px;
			}

			&.bottom-content {
				bottom: -7px;
			}

			li {
				.circle {
					width: 7px;
					height: 7px;
					margin: 0;
					padding: 1px;
					border: 1px solid #01b5f6;

					&.active {
						&::after {
							width: 5px;
							height: 5px;
						}
					}
				}

				.text {
					margin: 0 3px;
					text-align: center;
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.switch-wrapper {
		.list-content {
			font-size: 7px;

			&.top-content {
				top: -7px;
			}

			&.bottom-content {
				bottom: -10px;
			}

			li {
				.circle {
					width: 5px;
					height: 5px;

					&.active {
						&::after {
							width: 3px;
							height: 3px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 360px) {
	.switch-wrapper {
		.list-content {

			&.top-content {
				top: -7px;
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.switch-wrapper {
		.list-content {

			&.top-content {
				top: -7px;
			}
		}
	}
}