.award {
	position: relative;

	.award-wrapper {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 120px;
		margin-bottom: 200px;

		img {
			width: 100%;
			height: 100%;
		}

		.icon {
			width: 32px;
			height: 32px;
			z-index: 3;
			position: absolute;
		}

		.icon-normal {
			top: -12px;
			left: -8px;
		}

		.icon-reserve {
			transform: rotate(180deg);
			bottom: -12px;
			right: -8px;

		}

		.slider-wrapper {
			width: 100%;
			z-index: 2;

			.wrapper {
				display: flex !important;
				flex-wrap: wrap;

				&.hidden {
					display: none;
				}
			}

			.award-image {
				height: 552px;
				padding: 0 5px;
				margin: auto;

				.award-loader {
					border: 1px solid;

					.image-loader {
						left: 40%;
					}
				}

				.award-error {
					border: 1px solid;
				}
			}
		}
	}

	.animate-dot {
		position: absolute;
		background: linear-gradient(45deg,
				#bb1ae5 0%,
				rgba(59, 232, 233, 1) 10%,
				#bb1ae5 20%,
				rgba(59, 232, 233, 1) 30%,
				#bb1ae5 40%,
				rgba(59, 232, 233, 1) 50%,
				#bb1ae5 60%,
				rgba(59, 232, 233, 1) 70%,
				#bb1ae5 80%,
				rgba(59, 232, 233, 1) 90%,
				#bb1ae5 100%);


		&.line-next-title {
			@include animate-dot(6s, 500%, 1);
			right: 3%;
			top: 7%;
		}

		&.line-left-slider {
			@include animate-dot(6s, 1000%, 1);
			top: 50%;
			left: 1%;
		}
	}

	.award-circle-left {
		@include animate-circle(165px, 165px, 93%, 80%, 14s, 0.6, 300%);
		background-image: linear-gradient(90deg, rgb(17, 119, 167) 0%, rgb(100, 37, 86) 50%, rgb(17, 119, 167) 100%);
	}

	.award-circle-right {
		@include animate-circle(405px, 405px, 19%, -3%, 16s, 0.5, 350%);
		background-image: linear-gradient(90deg, rgb(1, 47, 63) 0%, rgb(150, 58, 255) 50%, rgb(1, 47, 63) 100%);
	}

	.award-circle-right-small {
		@include animate-circle(55px, 55px, 66%, 1%, 10s, 0.6, 350%);
		background-image: linear-gradient(90deg, rgb(143, 41, 222) 0%, rgb(252, 0, 255) 50%, rgb(143, 41, 222) 100%);
	}
}

@media screen and (max-width: 1640px) {
	.award {

		.award-wrapper {
			margin: 0 90px;
			margin-bottom: 170px;

			.icon {
				width: 32px;
				height: 32px;
			}

			.icon-normal {
				left: -7px;
			}

			.icon-reserve {
				right: -7px;

			}

			.slider-wrapper {
				.award-image {
					height: 492px;

					.award-loader {

						.image-loader {
							left: 35%;
						}
					}
				}
			}
		}

		.animate-dot {
			&.line-next-title {
				right: 2%;
			}

			&.line-left-slider {
				top: 45%;
				left: 0.5%;
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.award {

		.award-wrapper {

			.icon {
				width: 32px;
				height: 32px;
			}

			.slider-wrapper {
				.award-image {
					height: 432px;
				}
			}
		}

		.animate-dot {
			&.line-next-title {
				right: 2%;
				top: 5%;
			}

			&.line-left-slider {
				top: 45%;
			}
		}

		.award-circle-left {
			@include animate-circle(165px, 165px, 93%, 80%, 14s, 0.6, 300%);
		}

		.award-circle-right {
			@include animate-circle(285px, 285px, 19%, -3%, 14s, 0.5, 350%);
		}

		.award-circle-right-small {
			@include animate-circle(35px, 35px, 62%, 1%, 10s, 0.6, 350%);
		}
	}
}

@media screen and (max-width: 1366px) {
	.award {

		.award-wrapper {

			.icon-normal {
				left: -11px;
			}

			.icon-reserve {
				right: -11px;
			}

			.slider-wrapper {

				.award-image {
					height: 396px;
				}
			}
		}

		.award-circle-right-small {
			@include animate-circle(35px, 35px, 66%, 1%, 10s, 0.6, 350%);
		}
	}
}

@media screen and (max-width: 1280px) {
	.award {

		.award-wrapper {

			.slider-wrapper {
				.award-image {
					height: 356px;
				}
			}
		}

		.award-circle-right-small {
			@include animate-circle(35px, 35px, 68%, 1%, 10s, 0.6, 350%);
		}
	}
}

@media screen and (max-width: 1024px) {
	.award {

		.award-wrapper {
			margin: 0 70px;
			margin-bottom: 170px;

			.icon {
				width: 24px;
				height: 24px;
			}

			.icon-normal {
				top: -10px;
			}

			.icon-reserve {
				bottom: -10px;
			}

			.slider-wrapper {
				.award-image {
					height: 302px;

					.award-loader {

						.image-loader {
							left: 30%;
						}
					}
				}
			}
		}

		.animate-dot {
			&.line-next-title {
				div {
					@include line-common(30px, 1px, 15px);
				}
			}

			&.line-left-slider {
				left: 1%;

				div {
					@include line-common(20px, 1px, 15px);
				}
			}
		}

		.award-circle-left {
			@include animate-circle(115px, 115px, 93%, 80%, 14s, 0.6, 300%);
		}

		.award-circle-right {
			@include animate-circle(206px, 206px, 19%, -3%, 14s, 0.5, 300%);
		}

		.award-circle-right-small {
			@include animate-circle(35px, 35px, 66%, 1%, 10s, 0.6, 350%);
		}
	}
}

@media screen and (max-width: 800px) {
	.award {

		.award-wrapper {
			margin: 0 120px;
			margin-bottom: 120px;

			.icon {
				width: 32px;
				height: 32px;
			}

			.icon-normal {
				top: -12px;
				left: -12px;
			}

			.icon-reserve {
				bottom: -3px;
				right: -12px;
			}

			.slider-wrapper {
				.award-image {
					flex: 0 0 48%;
					width: 48%;
					padding: 0;
					margin: auto;
					margin-right: 5px;
					margin-bottom: 10px;
					height: 340px;
				}
			}
		}

		.animate-dot {
			&.line-next-title {
				top: -7%;
				right: 7%;

				div {
					@include line-common(35px, 3px, 25px);
				}
			}

			&.line-left-slider {
				left: 2%;
				top: 65%;

				div {
					@include line-common(30px, 2px, 20px);
				}
			}
		}

		.award-circle-left {
			@include animate-circle(115px, 115px, 90%, 75%, 14s, 0.6, 300%);
		}

		.award-circle-right {
			@include animate-circle(206px, 206px, 2%, -3%, 14s, 0.5, 350%);
		}

		.award-circle-right-small {
			@include animate-circle(35px, 35px, 24%, 4%, 10s, 0.6, 350%);
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.award {

		.award-wrapper {
			margin: 40px 40px 120px 40px;

			.icon {
				width: 20px;
				height: 20px;
			}

			.icon-normal {
				top: -8px;
				left: -8px;
			}

			.icon-reserve {
				bottom: -3px;
				right: -8px;
			}

			.slider-wrapper {
				.award-image {
					height: 230px;
					margin-bottom: 5px;

					.award-loader {

						.image-loader {
							left: 22%;
						}
					}
				}
			}
		}

		.animate-dot {
			&.line-next-title {
				top: 2%;
				right: 1%;
				padding-bottom: 10px;

				div {
					@include line-common(20px, 1px, 12px);
				}
			}

			&.line-left-slider {
				left: 1%;

				div {
					@include line-common(15px, 1px, 10px);
				}
			}
		}

		.award-circle-left {
			@include animate-circle(85px, 85px, 90%, 77%, 14s, 0.6, 300%);
		}

		.award-circle-right {
			@include animate-circle(136px, 136px, 2%, -10%, 14s, 0.5, 350%);
		}

		.award-circle-right-small {
			@include animate-circle(25px, 25px, 23%, 2%, 10s, 0.6, 350%);
		}
	}
}

@media screen and (max-width: 375px) {
	.award {

		.award-wrapper {
			.slider-wrapper {
				.award-image {
					height: 195px;

					.award-loader {

						.image-loader {
							left: 18%;
						}
					}
				}
			}
		}

		.award-circle-right-small {
			@include animate-circle(25px, 25px, 26%, 2%, 10s, 0.6, 350%);
		}
	}
}

@media screen and (max-width: 320px) {
	.award {

		.award-wrapper {

			.slider-wrapper {
				.award-image {
					flex: 0 0 47%;
					width: 47%;
					height: 162px;

					.award-loader {

						.image-loader {
							left: 5%;
						}
					}
				}
			}
		}

		.award-circle-right-small {
			@include animate-circle(25px, 25px, 30%, 2%, 10s, 0.6, 350%);
		}
	}
}