.image-loader {
	height: 50px;
	background: black;
	display: inline-block;
	position: relative;
	left: 50%;

	.loader {
		display: inline-block;
		position: absolute;
		left: 8px;
		width: 16px;
		background: white;
		animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

		&:first-child {
			left: 8px;
			animation-delay: -0.36s;
		}

		&:nth-child(2) {
			left: 32px;
			animation-delay: -0.24s;
		}

		&:nth-child(3) {
			left: 56px;
			animation-delay: -0.12s;
		}

		&:nth-child(4) {
			left: 80px;
			animation-delay: 0;
		}
	}
}

.lazy-load-image-loaded {
	height: 100%;
}

.error-image {
	width: 150px;
	height: 120px;

	img {
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.image-loader {
		left: 45%;

		.loader {
			width: 12px;
		}
	}

	.error-image {
		width: 120px;
		height: 90px;
	}
}

@media screen and (max-width: 800px) {
	.image-loader {
		left: 43%;
	}

	.error-image {
		width: 100px;
		height: 70px;
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.image-loader {
		left: 35%;
	}

	.error-image {
		width: 80px;
		height: 50px;
	}
}