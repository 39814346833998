.osx-content {
	.career-page {
		.title-content {
			display: -webkit-box;
			-webkit-box-align: baseline;
			background: linear-gradient(45deg,
					#08f2f2 0%,
					#08f2f2 10%,
					$violet-1 15%,
					#08f2f2 37.5%,
					$violet-1 50%,
					#08f2f2 62.5%,
					$violet-1 80%,
					#08f2f2 95%,
					#08f2f2 100%,
				);
			background-size: 200% auto;
			animation: shine 6s linear infinite;
			color: #000;
			background-clip: text;
			-webkit-text-fill-color: transparent;

			.line-content {
				display: -webkit-box;
			}

			.right-line {

				.line-dot {
					margin-right: -3px;
				}
			}
		}
	}
}

.career-page {
	position: relative;

	.title-content {
		padding-bottom: 50px;
		display: flex;
		align-items: baseline;
		width: 100%;
		background: linear-gradient(45deg,
				$violet-1 0%,
				$violet-1 5%,
				#08f2f2 12.5%,
				$violet-1 25%,
				#08f2f2 37.5%,
				$violet-1 50%,
				#08f2f2 62.5%,
				$violet-1 75%,
				#08f2f2 87.5%,
				$violet-1 92%,
				$violet-1 100%,
			);
		background-size: 300% auto;
		animation: shine 10s linear infinite;
		color: #000;
		background-clip: text;
		-webkit-text-fill-color: transparent;

		.title {
			font-size: 145px;
			font-family: 'Montserrat ExtraBold';
			color: white;
			margin: 0 15px;
		}

		.left-line,
		.right-line {
			.horizontal-line {
				height: 1px;
				background: white;
				background: linear-gradient(90deg, #a149fc 0%, #08f2f2 25%, #a149fc 50%, #08f2f2 75%, #a149fc 100%);
				background-size: 500% auto;
				animation: shine 8s linear infinite;
			}

			.line-dot {
				font-size: 68px;
			}
		}

		.left-line {
			.horizontal-line {
				width: 240px;
			}

			.line-dot {
				margin-left: -7px;
			}
		}

		.right-line {
			width: 100%;

			.horizontal-line {
				width: 100%;
			}

			.line-dot {
				margin-right: -6px;
			}
		}
	}

	.form-detail {
		max-width: 660px;

		.callback-title {
			color: $yellow-main;
			margin-bottom: 30px;
			text-align: center;
			font-size: 40px;
			font-family: "Montserrat Bold";
		}
	}

	.career-part {
		margin-left: 110px;
		padding-bottom: 265px;
		position: relative;
		z-index: 1;

		.form-desktop {
			right: -117px;
		}

		.line-right-form {
			background: linear-gradient(45deg,
					#6734fa 0%,
					#6734fa 7%,
					#0aedf2 12.5%,
					#6734fa 25%,
					#0aedf2 37.5%,
					#6734fa 50%,
					#0aedf2 62.5%,
					#6734fa 75%,
					#0aedf2 87.5%,
					#6734fa 100%);
			@include animate-dot(10s, 1000%, 1);
			position: absolute;
			top: 54%;
			right: -17%;
		}

		.contact-form {
			height: 100%;

			.contact-cirle {
				z-index: 2 !important;
				@include animate-circle(750px, 750px, 7%, 8%, 20s, 1, 100%);
				background-image: linear-gradient(90deg, rgb(0, 10, 122) 30%, rgb(138, 158, 174) 95%);
				transform: none;
			}

			.form-detail {
				z-index: 3;
				position: absolute;
				width: 90%;
				top: 16%;
				right: 17%;
				margin: auto;


				.btn-holder {
					padding-right: 80px;
				}
			}
		}
	}

	.job-description {
		margin-left: 150px;
		margin-right: 120px;
		color: white;
		position: relative;
		z-index: 1;

		.horizontal-line {
			height: 1px;
			background: white;
			background: linear-gradient(90deg, white 0%, white 100%);
			background-size: 500% auto;
			animation: shine 8s linear infinite;
			width: 100%;
		}

		.line-dot {
			font-size: 55px;
			margin-left: -6px;
			line-height: 1 !important;
		}

		.text {
			color: $yellow-main;
			font-size: 46px;
			font-family: "Montserrat Regular";
			line-height: 1.5;
		}

		.descr {
			margin-top: 32px;
			line-height: 1.5;
			font-size: 25px;
			font-family: 'Montserrat ExtraLight';
			text-align: justify;
			z-index: 1;
			white-space: pre-line;

			p {
				margin-bottom: 2rem;
			}

			sub {
				margin-bottom: 20px;
			}

			ul {
				list-style: unset;
				padding: 0 0 0 20px;

				li {
					word-break: break-word;
					padding-bottom: 10px;
				}
			}
		}

		.career-circle-top {
			@include animate-circle(253px, 253px, 45%, 75%, 15s, 0.3, 250%);
			background-image: linear-gradient(90deg, rgb(117, 11, 85) 0%, rgb(205, 7, 77) 50%, rgb(117, 11, 85) 100%);
		}

		.career-circle-bottom {
			@include animate-circle(750px, 750px, 70%, 5%, 10s, 0.2, 300%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
		}
	}

	.line-below-form {
		background: linear-gradient(45deg,
				#113965 0%,
				#3c4d27 12.5%,
				#113965 25%,
				#3c4d27 37.5%,
				#113965 50%,
				#3c4d27 62.5%,
				#113965 75%,
				#3c4d27 87.5%,
				#113965 100%);
		@include animate-dot(6s, 200%, 1);
		position: relative;
		margin-top: 100px;
		left: 12%;
	}
}

@media screen and (max-width: 1640px) {
	.career-page {

		.career-part {
			margin-left: 110px;
			padding-bottom: 200px;
			position: relative;
			z-index: 1;

			.contact-form {
				.contact-cirle {
					@include animate-circle(600px, 600px, 10%, 12%, 20s, 1, 100%);
				}

				.form-detail {
					width: 85%;
					right: 17%;

					.callback-title {
						font-size: 30px;
						padding-left: 0;
					}

					.btn-holder {
						padding-right: 90px;
					}
				}
			}
		}

		.title-content {

			.title {
				font-size: 120px;
			}
		}

		.job-description {
			.text {
				font-size: 43px;
			}

			.descr {
				font-size: 23px;
			}
		}

		.animate-dot {
			&.line-right-form {
				right: -20%;
			}
		}

		.line-below-form {
			margin-top: 80px;
		}
	}
}

@media screen and (max-width: 1440px) {
	.career-page {

		.career-part {
			padding-bottom: 180px;

			.form-desktop {
				right: -100px;
			}

			.contact-form {
				.contact-cirle {
					@include animate-circle(600px, 600px, 4%, 0, 20s, 1, 100%);
				}

				.form-detail {
					width: 95%;
					top: 14%;
					right: 10%;

					.attachment {
						margin-top: 5px;
					}
				}
			}
		}

		.title-content {

			.title {
				font-size: 120px;
			}
		}

		.job-description {
			.text {
				font-size: 40px;
			}

			.descr {
				font-size: 21px;
			}
		}

		.animate-dot {
			&.line-right-form {
				right: -27%;
			}
		}

		.line-below-form {
			margin-top: 80px;
		}
	}
}

@media screen and (max-width: 1366px) {
	.career-page {
		.title-content {
			.title {
				font-size: 89px;
			}

			.left-line {
				.horizontal-line {
					width: 197px;
				}
			}
		}

		.career-part {
			padding-bottom: 160px;
			margin-left: 40px;

			.contact-form {
				.contact-cirle {
					@include animate-circle(500px, 500px, 4%, 12%, 20s, 1, 100%);
				}

				.form-detail {
					width: 80%;
					right: 7%;
					top: 11%;

					.btn-holder {
						padding-left: 110px;
						padding-right: 0;
					}
				}
			}
		}

		.job-description {
			margin-left: 115px;

			.text {
				font-size: 36px;
			}

			.descr {
				font-size: 18px;
			}

			.career-circle-top {
				@include animate-circle(180px, 180px, 32%, 75%, 15s, 0.3, 250%);
			}

			.career-circle-bottom {
				@include animate-circle(572px, 572px, 74%, 0, 10s, 0.2, 300%);
			}
		}

		.animate-dot {
			&.line-below-form {
				left: 10%;

				div {
					letter-spacing: 5px;
				}
			}
		}

		.animate-dot {
			&.line-right-form {
				div {

					letter-spacing: 1px;
					line-height: 14px;
				}
			}

			&.line-below-form {
				margin-top: 20px;

				div {
					@include line-common(100px, 4px, 70px);
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.career-page {

		.career-part {
			.form-desktop {
				right: -40px;
				top: 20px;
			}

			.contact-form {
				.contact-cirle {
					@include animate-circle(500px, 500px, 3%, -6%, 20s, 1, 100%);
				}

				.form-detail {
					width: 84%;
					right: 3%;
					top: 10%;

					.attachment {
						margin-top: 0;
					}

					.btn-holder {
						padding-left: 140px;
						padding-right: 0;
					}
				}
			}
		}

		.job-description {

			.career-circle-bottom {
				@include animate-circle(572px, 572px, 74%, -3%, 10s, 0.2, 300%);
			}
		}

		.animate-dot {
			&.line-below-form {
				left: 10%;

				div {
					letter-spacing: 5px;
				}
			}

			&.line-right-form {
				right: -31%;

				div {
					letter-spacing: 1px;
					line-height: 14px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.career-page {

		.career-part {
			padding-bottom: 120px;

			.form-desktop {
				right: -10px;
			}

			.contact-form {
				.contact-cirle {
					@include animate-circle(470px, 470px, 2%, -15%, 20s, 1, 100%);
				}

				.form-detail {
					width: 100%;
					right: -6%;
				}
			}
		}

		.title-content {
			.title {
				font-size: 74px;
			}

			.left-line {
				.horizontal-line {
					width: 150px;
				}
			}
		}

		.job-description {
			margin-left: 90px;
			margin-right: 60px;

			.text {
				font-size: 32px;
			}

			.descr {
				font-size: 15px;
			}
		}

		.animate-dot {
			&.line-below-form {

				div {
					@include line-common(90px, 4px, 60px);
				}
			}

			&.line-right-form {
				top: -9%;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.career-page {

		.career-part {
			margin-left: 0;
		}

		.title-content {
			.title {
				font-size: 80px;
			}

			.left-line {
				.horizontal-line {
					width: 90px;
				}
			}
		}

		.form-desktop,
		.form-mobile {
			z-index: 1;
		}

		.form-desktop {
			position: absolute;
			top: 10%;
		}

		.form-mobile {
			position: relative;
			margin-bottom: 100px;

			.contact-form {
				height: 100%;

				.contact-cirle {
					@include animate-circle(580px, 580px, -17%, 15%, 20s, 0.8, 100%);
					background-image: linear-gradient(90deg, rgb(0, 10, 122) 30%, rgb(138, 158, 174) 95%);
					transform: none;
				}

				.form-detail {
					position: relative;
					width: 75%;
					z-index: 1;
					margin: auto;
					right: 8%;

					.form-input {
						margin-bottom: 5px !important;
					}

					.form-input-error {
						margin-bottom: -9px !important;
					}

					.error {
						font-size: 12px !important;
					}

					.btn-holder {
						flex: 0 0 100%;
						max-width: 100%;
						padding-left: 100px;
					}
				}
			}
		}

		.job-description {
			margin: 0 80px 100px 80px;

			.intro,
			.descr {
				padding: 0;
			}

			.career-circle-top {
				display: none;
			}

			.career-circle-bottom {
				display: none;
			}

			.line-content {
				width: 80%;
			}

			.descr {
				margin-top: 10px;
				font-size: 13px;

				ul {
					&.sub-content-1 {
						li {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.career-circle-bottom-1 {
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
			@include animate-circle(500px, 500px, 86%, -14%, 10s, 0.2, 300%);
		}

		.animate-dot {
			&.line-right-form {
				right: -93%;
				padding-bottom: 20px;

				div {
					@include line-common(33px, 1px, 19px);
				}
			}
		}
	}

	.osx-content {
		.career-page {
			.title-content {
				.right-line {
					.line-dot {
						margin-right: -5px;
					}
				}

				.left-line {
					.line-dot {
						margin-left: -6px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.career-page {

		.career-part {

			.mobile-title {
				padding: 0;
				margin-left: -30px;
			}
		}

		.title-content {
			.title {
				font-size: 44px;
			}

			.left-line {
				.horizontal-line {
					width: 40px;
				}
			}
		}

		.job-description {
			margin: 0 50px 70px 50px;

			.descr {
				font-size: 12px;
			}
		}

		.form-desktop {
			right: 47%;
		}

		.form-mobile {
			position: relative;
			margin-bottom: 100px;

			.contact-form {
				height: 100%;

				.contact-cirle {
					@include animate-circle(360px, 360px, -14%, 5%, 20s, 0.8, 100%);
				}

				.form-detail {
					width: 70%;
					right: -4%;

					.callback-title {
						font-size: 27px;
						margin-bottom: 10px;
						padding-right: 15px;
					}

					.form-input {
						margin-bottom: 0 !important;
					}

					.form-input-error {
						margin-bottom: -10px !important;
					}

					.error {
						font-size: 8px !important;
					}

					.btn-holder {
						padding-left: 0;
					}
				}
			}
		}

		.career-circle-bottom-1 {
			@include animate-circle(400px, 400px, 90%, -24%, 10s, 0.2, 300%);
		}

		.animate-dot {
			&.line-below-form {
				right: -45%;
				padding-bottom: 20px;

				div {
					@include line-common(68px, 1px, 42px);
				}
			}

			&.line-right-form {

				div {
					@include line-common(20px, 1px, 12px);
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.osx-content {
		.career-page {
			.title-content {
				.right-line {
					.line-dot {
						margin-right: -5px;
					}
				}
			}
		}
	}

	.career-page {
		.title-content {
			.left-line {
				.line-dot {
					margin-left: -5px;
				}
			}
		}

		.job-description {
			margin: 0 50px 70px 30px;
		}

		.form-desktop {
			right: 44%;
			margin: 0 50px 0 30px;
		}

		.form-mobile {
			position: relative;
			margin-bottom: 100px;

			.contact-form {
				height: 100%;

				.contact-cirle {
					@include animate-circle(355px, 355px, -15%, 1%, 20s, 0.8, 100%);
				}

				.form-detail {
					width: 73%;
					right: -4%;

					.callback-title {
						font-size: 20px;
						padding-right: 30px;
					}

					.btn-holder {
						padding-left: 0;
					}
				}
			}
		}

		.career-circle-bottom-1 {
			@include animate-circle(400px, 400px, 90%, -24%, 10s, 0.2, 300%);
		}

		.animate-dot {
			&.line-right-form {

				div {
					@include line-common(18px, 1px, 11px);
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.career-page {

		.form-mobile {
			position: relative;
			margin-bottom: 100px;

			.contact-form {
				height: 100%;

				.contact-cirle {
					@include animate-circle(310px, 310px, -15%, 2%, 20s, 0.8, 100%);
				}

				.form-detail {
					.callback-title {
						font-size: 18px;
					}

					label {
						margin-bottom: 2px;
					}

					input {
						height: 18px;
					}

					.error {
						font-size: 8px !important;
					}

					.btn-holder {
						padding-left: 0;
					}
				}
			}
		}

		.title-content {
			.title {
				font-size: 36px;
			}

			.left-line {
				.horizontal-line {
					width: 40px;
				}
			}
		}

		.animate-dot {
			&.line-right-form {
				display: none;
			}
		}
	}
}