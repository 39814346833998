header {
	position: relative;

	.header-wrraper {
		margin: 0 108px;
		padding-top: 160px;
		margin-bottom: 54px;
		display: flex;
		justify-content: space-between;

		.logo {
			width: 280px;
			height: 35px;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.menu {
			align-self: flex-end;

			a {
				color: white;
				font-size: 25px;
				text-decoration: none;
				margin-left: 40px;
				transition: all 0.4s;

				&:hover {
					color: $yellow-main;
				}

				&.is-active {
					color: $yellow-main;
				}
			}
		}

		.header-cirle {
			@include animate-circle(74px, 74px, 45%, 47%, 15s, 1, 350%);
			background-image: linear-gradient(90deg, rgb(4, 71, 53) 0%, rgb(5, 171, 226) 50%, rgb(4, 71, 53) 100%);
		}

		.another-page-circle {
			@include animate-circle(74px, 74px, 72%, 47%, 15s, 1, 350%);
			background-image: linear-gradient(90deg, rgb(4, 71, 53) 0%, rgb(5, 171, 226) 50%, rgb(4, 71, 53) 100%);
		}
	}
}

@media screen and (max-width: 1640px) {
	header {
		.header-wrraper {
			margin: 0 90px;

			.menu {

				a {
					font-size: 20px;
					margin-left: 35px;
				}
			}

			.header-cirle {
				@include animate-circle(60px, 60px, 60%, 47%, 15s, 1, 350%);
			}

			.another-page-circle {
				@include animate-circle(60px, 60px, 72%, 47%, 15s, 1, 350%);
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	header {
		.header-wrraper {
			margin: 0 72px;
			padding-top: 120px;


			.logo {
				width: 240px;
				height: 30px;
			}

			.menu {
				a {
					font-size: 16px;
					margin-left: 30px;
				}
			}

			.header-cirle {
				@include animate-circle(51px, 51px, 9%, 40%, 15s, 1, 350%);
			}

			.another-page-circle {
				@include animate-circle(51px, 51px, 17%, 40%, 15s, 1, 350%);
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	header {
		.header-wrraper {
			margin: 0 72px;
			padding-top: 80px;


			.logo {
				width: 200px;
				height: 26px;
			}

			.menu {
				a {
					font-size: 14px;
					margin-left: 20px;
				}
			}

			.header-cirle {
				@include animate-circle(40px, 40px, 47%, 45%, 15s, 1, 350%);
			}

			.another-page-circle {
				@include animate-circle(40px, 40px, 67%, 45%, 15s, 1, 350%);
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	header {
		.header-wrraper {
			margin-left: 30px;
			margin-right: 60px;
			padding-top: 70px;


			.logo {
				width: 200px;
				height: 23px;
			}

			.menu {
				a {
					font-size: 13px;
					margin-left: 18px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	header {
		.header-wrraper {
			margin: 0 35px;
			padding-top: 115px;


			.logo {
				width: 150px;
				height: 20px;
			}

			.menu {
				a {
					font-size: 12px;
					margin-left: 20px;
				}
			}

			.header-cirle {
				@include animate-circle(31px, 31px, 80%, 53%, 12s, 1, 300%);
			}

			.another-page-circle {
				@include animate-circle(31px, 31px, 17%, 46%, 12s, 1, 300%);
			}
		}
	}
}

@media screen and (max-width: 768px) {
	//Inherit from tablet 800px
}

@media screen and (max-width: 576px) {
	header {
		.header-wrraper {
			padding-top: 60px;

			.logo {
				width: 80px;
				height: 12px;
			}

			.menu {
				a {
					font-size: 8px;
					margin-left: 10px;
				}
			}

			.header-cirle {
				@include animate-circle(28px, 28px, 81%, 60%, 12s, 1, 300%);
			}

			.another-page-circle {
				@include animate-circle(14px, 14px, 81%, 59%, 12s, 1, 300%);
			}
		}
	}
}

@media screen and (max-width: 375px) {
	header {
		.header-wrraper {
			.menu {
				a {
					margin-left: 8px;
				}
			}

			.header-cirle {
				@include animate-circle(14px, 14px, 85%, 64%, 12s, 1, 300%);
			}

			.another-page-circle {
				@include animate-circle(14px, 14px, 85%, 64%, 12s, 1, 300%);
			}
		}
	}
}

@media screen and (max-width: 320px) {
	header {
		.header-wrraper {
			.menu {
				a {
					font-size: 7px;
					margin-left: 5px;
				}
			}
		}
	}
}