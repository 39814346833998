.content-wrapper {
	margin-top: 90px;

	.work-wrapper {

		position: relative;
		z-index: 3;
		background: linear-gradient(30deg,
			#650070 0%,
			#5545d3 10%,
			#01eee3 20%,
			#650070 30%,
			#5545d3 40%,
			#01eee3 50%,
			#650070 60%,
			#5545d3 70%,
			#01eee3 80%,
			#650070 90%,
			#5545d3 100%);
		background-size: 1000% auto;
		animation: shine 10s linear infinite;
		padding: 10px 0;
		margin-bottom: 700px;
	}

	.animate-dot {
		position: absolute;
		background: linear-gradient(45deg,
				#414e0f 0%,
				#0c3a5f 12.5%,
				#414e0f 25%,
				#0c3a5f 37.5%,
				#414e0f 50%,
				#0c3a5f 62.5%,
				#414e0f 75%,
				#0c3a5f 87.5%,
				#414e0f 100%);
		@include animate-dot(6s, 700%, 1);

		&.line-below-left-content {
			bottom: 1%;
			left: 6%;
			padding-bottom: 50px;
		}

		&.line-below-right-content {
			bottom: 7.5%;
			right: 7.5%;
			padding-bottom: 30px;
			background: linear-gradient(45deg,
					#c004e0 0%,
					#044b5a 12.5%,
					#c004e0 25%,
					#044b5a 37.5%,
					#c004e0 50%,
					#044b5a 62.5%,
					#c004e0 75%,
					#044b5a 87.5%,
					#c004e0 100%);
			@include animate-dot(8s, 700%, 1);
		}
	}

	.works-content-circle-top {
		@include animate-circle(450px, 450px, 350px, 5.5%, 15s, 0.1, 350%);
		background-image: linear-gradient(90deg, rgb(117, 11, 85) 0%, rgb(205, 7, 77) 50%, rgb(117, 11, 85) 100%);
	}

	.works-content-circle-left {
		@include animate-circle(200px, 200px, 700px, 73%, 15s, 0.4, 300%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}

	.works-content-circle-bottom {
		@include animate-circle(600px, 600px, 88%, -10%, 15s, 0.5, 300%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}
}

@media screen and (max-width: 1440px) {
	.content-wrapper {
		margin-top: 30px;

		.works-content-circle-top {
			@include animate-circle(450px, 450px, 215px, 5.5%, 15s, 0.1, 350%);
			background-image: linear-gradient(90deg, rgb(117, 11, 85) 0%, rgb(205, 7, 77) 50%, rgb(117, 11, 85) 100%);
		}
	
		.works-content-circle-left {
			@include animate-circle(200px, 200px, 465px, 73%, 15s, 0.4, 300%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
		}
	
		.works-content-circle-bottom {
			@include animate-circle(600px, 600px, 88%, -10%, 15s, 0.5, 300%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
		}
	}
}

@media screen and (max-width: 1366px) {
	.content-wrapper {

		.work-wrapper {
			margin-bottom: 450px;
		}


		.animate-dot {
			&.line-below-right-content {
				bottom: 6.5%;
				right: 7%;

				div {
					@include line-common (35px, 5px, 25px);
				}
			}

			&.line-below-left-content {
				bottom: -20px;

				div {
					@include line-common (100px, 6px, 60px);
				}
			}
		}
		


		.works-content-circle-top {
			@include animate-circle(350px, 350px, 215px, 5.5%, 15s, 0.1, 350%);
			background-image: linear-gradient(90deg, rgb(117, 11, 85) 0%, rgb(205, 7, 77) 50%, rgb(117, 11, 85) 100%);
		}
	
		.works-content-circle-left {
			@include animate-circle(150px, 150px, 465px, 73%, 15s, 0.4, 300%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
		}
	
		.works-content-circle-bottom {
			@include animate-circle(550px, 550px, 88%, -10%, 15s, 0.5, 300%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
		}
	}
}

@media screen and (max-width: 1024px) {
	.content-wrapper {
		margin-top: 30px;

		.work-wrapper {
			margin-bottom: 450px;
		}

		.animate-dot {
			&.line-below-right-content {
				bottom: 6.5%;
				right: 7%;

				div {
					@include line-common (35px, 5px, 25px);
				}
			}

			&.line-below-left-content {
				bottom: -20px;

				div {
					@include line-common (100px, 6px, 60px);
				}
			}
		}

		.works-content-circle-left {
			@include animate-circle(140px, 140px, 400px, 69%, 15s, 0.5, 300%);
		}

		.works-content-circle-top {
			@include animate-circle(338px, 338px, 195px, 6%, 15s, 0.2, 350%);
		}

		.works-content-circle-bottom {
			@include animate-circle(390px, 390px, 89%, -10%, 15s, 0.5, 300%);
		}
	}
}

@media screen and (max-width: 800px) {
	.content-wrapper {

		.work-wrapper {
			padding: 5px 0;
			margin-bottom: 350px;
		}

		.animate-dot {
			&.line-below-right-content {
				bottom: 0;
				right: 7%;

				div {
					@include line-common (25px, 5px, 18px);
				}
			}

			&.line-below-left-content {
				bottom: 5%;

				div {
					@include line-common (40px, 2px, 20px);
				}
			}
		}

		.works-content-circle-top {
			@include animate-circle(282px, 282px, 180px, 6%, 15s, 0.2, 350%);
		}

		.works-content-circle-left {
			@include animate-circle(86px, 86px, 390px, 73%, 15s, 0.4, 300%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
		}

		.works-content-circle-bottom {
			@include animate-circle(220px, 220px, 89%, -10%, 15s, 0.5, 300%);
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.content-wrapper {

		.work-wrapper {
			padding: 2px 0;
		}

		.animate-dot {
			&.line-below-right-content {
				bottom: 15%;
				right: 7%;

				div {
					@include line-common (15px, 2px, 10px);
				}
			}

			&.line-below-left-content {
				bottom: 12%;

				div {
					@include line-common (30px, 5px, 20px);
				}
			}
		}

		.works-content-circle-top {
			@include animate-circle(198px, 198px, 225px, 10%, 15s, 0.2, 350%);
		}

		.works-content-circle-left {
			@include animate-circle(40px, 40px, 175px, 74%, 15s, 0.5, 300%);
		}

		.works-content-circle-bottom {
			@include animate-circle(140px, 140px, 92%, -7%, 15s, 0.5, 340%);
		}
	}
}

@media screen and (max-width: 320px) {}