.work-item {

	.title {
		color: $yellow-main;
		font-size: 98px;
		font-family: 'Montserrat Thin';
		margin-bottom: 30px;
	}

	.work-content {
		padding: 10px 0;
		background: linear-gradient(45deg,
				#650070 0%,
				#650070 5%,
				#5545d3 10%,
				#01eee3 20%,
				#650070 30%,
				#5545d3 40%,
				#01eee3 50%,
				#650070 60%,
				#5545d3 70%,
				#01eee3 80%,
				#5545d3 90%,
				#650070 100%, );
		background-size: 1000% auto;
		animation: shine 10s linear infinite;

		.single-event {
			background-size: cover;
			background-position: center;
			background-position-x: right;
			height: 247px;
			display: flex;
			align-items: center;
		}

		.text {
			color: white;
			margin-left: 55px;

			.work-title {
				font-size: 68px;
				font-family: 'Montserrat Bold';

				span {
					cursor: pointer;
				}
			}

			.description {
				font-family: 'Montserrat Light';
				font-size: 24px;
				line-height: 2;

				span {
					cursor: pointer;
				}
			}
		}
	}
}

@media screen and (max-width: 1730px) {
	.work-item {

		.title {
			font-size: 85px;
		}

		.work-content {

			.single-event {
				height: 213px;
			}

			.text {

				.work-title {
					font-size: 58px;
				}

				.description {
					font-size: 22px;
					line-height: 2;
				}
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.work-item {

		.title {
			font-size: 78px;
		}

		.work-content {

			.single-event {
				height: 190px;
			}

			.text {

				.work-title {
					font-size: 54px;
				}

				.description {
					font-size: 20px;
					line-height: 2;
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.work-item {

		.title {
			font-size: 76px;
		}

		.work-content {

			.single-event {
				height: 180px;
			}

			.text {

				.work-title {
					font-size: 52px;
				}

				.description {
					font-size: 19px;
					line-height: 2;
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.work-item {

		.title {
			font-size: 73px;
		}

		.work-content {
			padding: 8px 0;

			.text {
				.work-title {
					font-size: 48px;
				}

				.description {
					font-size: 18px;
				}
			}

			.single-event {
				height: 170px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.work-item {
		.title {
			font-size: 63px;
			margin-bottom: 15px;
		}

		.work-content {
			.text {
				.work-title {
					font-size: 40px;
				}

				.description {
					font-size: 16px;
				}
			}

			.single-event {
				height: 120px;
				background-size: cover;
				background-position: center;
				background-position-x: center;
				background-repeat: no-repeat;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.work-item {
		.title {
			font-size: 53px;
			margin-left: 36px;
		}

		.work-content {
			padding: 3px 0;

			.text {
				margin-left: 40px;

				.work-title {
					font-size: 36px;
				}

				.description {
					font-size: 15px;
				}
			}

			.single-event {
				height: 110px;
				margin: 5px 0;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.work-item {
		.title {
			font-size: 28px;
			margin-left: 28px;
			margin-bottom: 15px;
		}

		.work-content {
			padding: 2px 0;

			.text {
				margin-left: 30px;

				.work-title {
					font-size: 18px;
				}

				.description {
					font-size: 8px;
				}
			}

			.single-event {
				height: 60px;
				margin: 2px 0;
			}
		}
	}
}