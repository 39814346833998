.contact-section {
	z-index: 2;
	position: relative;
	padding-bottom: 100px;

	.contact-content {
		margin: 50px 135px -50px 20%;
		@include z-index(relative, 1);

		.animate-dot {
			margin-top: 300px;
			margin-left: 50px;
			background: linear-gradient(45deg,
					#ba1be5 0%,
					#15c7fc 12.5%,
					#ba1be5 25%,
					#15c7fc 37.5%,
					#ba1be5 50%,
					#15c7fc 62.5%,
					#ba1be5 75%,
					#15c7fc 87.5%,
					#ba1be5 100%,
				);
			@include animate-dot(6s, 300%, 1);
		}
	}

	.contact-cirle {
		@include animate-circle(700px, 700px, 4%, 9%, 20s, 0.8, 100%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 30%, rgb(138, 158, 174) 95%);
		transform: none;
	}

	.contact-cirle-lelt {
		@include animate-circle(476px, 473px, 50%, 65%, 18s, 0.2, 300%);
		background-image: linear-gradient(90deg, rgb(81, 0, 52) 0%, rgb(235, 8, 189) 50%, rgb(81, 0, 52) 100%);
	}

	.contact-cirle-right {
		@include animate-circle(151px, 151px, 80%, 12%, 20s, 0.3, 200%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}
}

@media screen and (max-width: 1640px) {
	.contact-section {
		padding-bottom: 100px;

		.contact-content {
			margin: 50px 100px -50px 24.5%;
			@include z-index(relative, 1);

			.animate-dot {
				margin-top: 230px;
				margin-left: -20px;
				padding-bottom: 5px;

				div {
					@include line-common (90px, 10px, 50px);
				}
			}
		}

		.contact-cirle {
			@include animate-circle(550px, 550px, 5.5%, 9%, 20s, 0.8, 100%);
		}

		.contact-cirle-lelt {
			@include animate-circle(400px, 400px, 50%, 65%, 18s, 0.2, 300%);
		}

		.contact-cirle-right {
			@include animate-circle(130px, 130px, 80%, 12%, 20s, 0.3, 200%);
		}
	}
}

@media screen and (max-width: 1440px) {
	.contact-section {
		.contact-content {
			margin: 50px 100px -50px 23.5%;
		}

		.contact-cirle {
			@include animate-circle(500px, 500px, 5.5%, 9%, 20s, 0.8, 100%);
		}

	}
}

@media screen and (max-width: 1366px) {
	.contact-section {
		.contact-content {
			margin: 10px 135px -50px 22%;

			.animate-dot {
				margin-top: 150px;
				margin-left: -20px;
				padding-bottom: 5px;

				div {
					@include line-common (80px, 2px, 45px);
				}
			}
		}

		//Landing Page
		.contact-cirle {
			@include animate-circle(457px, 457px, 6%, 11%, 20s, 0.8, 100%);
		}

		.contact-cirle-lelt {
			@include animate-circle(340px, 340px, 50%, 65%, 18s, 0.4, 300%);
		}

		.contact-cirle-right {
			@include animate-circle(110px, 110px, 80%, 12%, 20s, 0.4, 200%);
		}

	}
}

@media screen and (max-width: 1280px) {
	.contact-section {
		.contact-content {
			margin: 10px 100px -50px 23.5%;
		}

		//Landing Page
		.contact-cirle {
			@include animate-circle(450px, 450px, 6.5%, 7%, 20s, 0.8, 100%);
		}
	}
}

@media screen and (max-width: 1024px) {
	.contact-section {
		.contact-content {
			margin: -10px 90px -50px 24.5%;

			.animate-dot {

				div {
					@include line-common (67px, 2px, 38px);
				}
			}
		}

		//Landing Page
		.contact-cirle {
			@include animate-circle(420px, 420px, 23px, 3.5%, 20s, 0.8, 100%);
		}

	}
}

@media screen and (max-width: 800px) {
	.contact-section {
		.contact-content {
			margin: -52px 135px -50px 20%;

			.animate-dot {
				margin-top: -150px;
				margin-left: -240px;
				padding-bottom: 5px;

				div {
					@include line-common (55px, 2px, 28px);
				}
			}
		}

		//Landing Page
		.contact-cirle {
			@include animate-circle(446px, 446px, 35.5%, 15%, 20s, 0.8, 100%);
		}

		.contact-cirle-lelt {
			display: none;
		}

		.contact-cirle-right {
			@include animate-circle(110px, 110px, 5%, 12%, 20s, 0.4, 200%);
		}

	}
}

@media screen and (max-width: 768px) {

	/* Inherit from 800 */
	.contact-section {
		.contact-content {
			.animate-dot {
				margin-left: -233px;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.contact-section {

		.contact-content {
			margin: 0px 70px -50px 10%;

			.animate-dot {
				margin-top: 10px;
				margin-left: -80px;
				padding-bottom: 5px;

				div {
					@include line-common (34px, 2px, 18px);
				}
			}
		}

		//Landing Page
		.contact-cirle {
			@include animate-circle(280px, 280px, 32.5%, 13%, 20s, 0.8, 100%);
		}

		.contact-cirle-lelt {
			display: none;
		}

		.contact-cirle-right {
			@include animate-circle(30px, 30px, 5%, 12%, 20s, 0.4, 200%);
		}

	}
}

@media screen and (max-width: 320px) {
	.contact-section {
		.contact-content {
			margin: 0 40px -50px 10%;
		}

		.contact-cirle {
			right: 9%;
		}

		.contact-cirle {
			@include animate-circle(270px, 270px, 29.5%, 6%, 20s, 0.8, 100%);
		}
	}
}