.star-universe {
	z-index: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	.star-white {
		width: 4px;
		height: 4px;
		display: block;
		background: #abbcea;
		border-radius: 50%;
		position: absolute;
		animation-name: blink;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-duration: 3s;
	}

	.star-yellow {
		width: 6px;
		height: 6px;
		display: block;
		background: $yellow-main;
		border-radius: 50%;
		position: absolute;
		animation-name: blink;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-duration: 5s;
	}

	.star-white-medium {
		width: 10px;
		height: 10px;
	}

	.star-white-large {
		width: 18px;
		height: 18px;
	}

	.star-yellow-medium {
		width: 10px;
		height: 10px;
	}

	.star-yellow-large {
		width: 15px;
		height: 15px;
	}
}

@media screen and (max-width: 1366px) {
	.star-universe {
		height: 77%;
	}
}