.osx-content {
  .work-detail-page {

    .wrapper {
      .text-wrapper {
        background: linear-gradient(45deg,
            $blue-1 0%,
            $blue-1 12.5%,
            $violet-1 25%,
            $blue-1 37.5%,
            $violet-1 50%,
            $blue-1 62.5%,
            $violet-1 75%,
            $blue-1 87.5%,
            $blue-1 100%,
          );
        background-size: 400% auto;
        animation: shine 10s linear infinite;
        color: #000;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        .title {
          display: -webkit-box;
        }

        .desc-wrapper {
          display: -webkit-box;
          -webkit-box-align: baseline;

          .line-content {
            display: -webkit-box;

            .line-dot {
              margin-left: -17px;
            }
          }
        }
      }
    }
  }
}

.work-detail-page {
  position: relative;

  .wrapper {
    display: flex;
    align-items: baseline;
    padding-top: 200px;

    .line-content {

      .line-dot {
        color: $yellow-main;
        font-size: 80px;
      }

      .horizontal-line {
        width: 100%;
        min-width: 20px;
        height: 1px;
        background: linear-gradient(90deg,
            $yellow-main 0%,
            $yellow-main 50%,
            $yellow-main 100%);
        background-size: 300% auto;
        animation: shine 8s linear infinite;
        top: -3.5px;
      }
    }

    .text-wrapper {
      background: linear-gradient(45deg,
          $violet-1 0%,
          $violet-1 5%,
          $blue-1 12.5%,
          $violet-1 25%,
          $blue-1 37.5%,
          $violet-1 50%,
          $blue-1 62.5%,
          $violet-1 75%,
          $blue-1 87.5%,
          $violet-1 92%,
          $violet-1 100%,
        );
      background-size: 300% auto;
      animation: shine 10s linear infinite;
      color: #000;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      .title {
        font-size: 91px;
        font-family: 'Montserrat ExtraBold';
        margin-left: calc(108px + 5px);
      }


      .desc-wrapper {
        display: flex;
        align-items: baseline;
        width: 100%;
        margin-top: 5px;

        .description {
          font-size: 31px;
          font-family: 'Montserrat Regular';
          text-transform: uppercase;
          line-height: 1.2;
          letter-spacing: 0.24em;
          white-space: pre-line;
        }

        .line-content {
          width: 108px;
          margin-right: 10px;
          background: linear-gradient(45deg, $yellow-main 0%, $yellow-main 100%);
          background-clip: text;
          flex-grow: unset;
          position: relative;

          .line-dot {
            margin-left: -6px;
          }
        }
      }
    }

    .line-content {

      flex-grow: 2;

      .line-dot {
        margin-right: -6px;
      }
    }

    .detail-wrapper {
      display: flex;
      align-items: center;
      padding-right: 135px;
      position: relative;
      top: -125px;

      .list {
        width: 100%;
        list-style: unset;
        border: 1px solid $yellow-main;
        padding: 10px 50px;
        color: $yellow-main;

        li {
          font-size: 31px;
          font-family: 'Montserrat Thin';
          line-height: 1.8;
        }

        .item-wrapper {
          display: flex;
        }

        .tit-label {
          min-width: 200px;
        }

        .data-text {
          font-family: 'Montserrat Regular';
        }
      }
    }
  }

  .detail-content {
    padding: 0 400px;
    margin-top: 100px;
    margin-bottom: 300px;
    height: 650px;
    position: relative;
    z-index: 1;

    .video {
      .iframe-loader {
        width: 100%;
        height: 100%;
      }

      .iframe-dumb {
        display: none;
      }
    }

    .slider-content {

      .slider-wrapper {
        height: 650px;
        display: flex !important;
        justify-content: center;
        background-color: transparent;

        img {
          max-width: 100%;
          height: 100%;
          margin: auto;
        }
      }
    }

    .arrow-wrapper {
      position: relative;
      display: block;
      justify-content: space-between;
      z-index: 1;
      margin-top: calc(-650px / 2);
      padding: 0 20px;

      .arrow {
        width: 20px;
        height: 40px;
        cursor: pointer;
        float: right;

        &.arrow-left {
          float: left;
          transform: rotate(180deg);
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .animate-dot {
    position: absolute;
    animation: shine 6s linear infinite;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(45deg,
        #084d50 0%,
        #550056 12.5%,
        #084d50 25%,
        #550056 37.5%,
        #084d50 50%,
        #550056 62.5%,
        #084d50 75%,
        #550056 87.5%,
        #084d50 100%);
    background-size: 800% auto;
    background-clip: text;
    -webkit-background-clip: text;

    &.line-below-text {
      top: 27%;
      left: 6.5%;
    }

    &.line-below-content {
      -webkit-text-fill-color: transparent;
      background: linear-gradient(45deg,
          #2f4d20 0%,
          #043552 12.5%,
          #2f4d20 25%,
          #043552 37.5%,
          #2f4d20 50%,
          #043552 62.5%,
          #2f4d20 75%,
          #043552 87.5%,
          #2f4d20 100%);
      background-size: 500% auto;
      background-clip: text;
      -webkit-background-clip: text;
      bottom: 1%;
      left: 37%;
    }

    &.line-right-content {
      bottom: 5%;
      right: 0;
    }
  }

  .works-detail-circle-top {
    @include animate-circle(279px, 279px, 31%, 68%, 15s, 0.4, 250%);
    background-image: linear-gradient(90deg, rgb(117, 11, 85) 0%, rgb(205, 7, 77) 50%, rgb(117, 11, 85) 100%);
  }

  .works-detail-circle-right {
    @include animate-circle(524px, 524px, 60%, -2%, 10s, 0.45, 300%);
    background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
  }

  .works-detail-circle-bottom {
    @include animate-circle(733px, 733px, 82%, 70%, 15s, 0.45, 350%);
    background-image: linear-gradient(90deg, rgb(117, 11, 85) 0%, rgb(205, 7, 77) 50%, rgb(117, 11, 85) 100%);
  }

}

@media screen and (max-width: 1640px) {
  .work-detail-page {

    .wrapper {

      .text-wrapper {

        .title {
          margin-left: calc(75px + 20px);
          font-size: 70px;
        }

        .desc-wrapper {

          .description {
            font-size: 25px;
          }

          .line-content {
            width: 90px;
          }
        }
      }

      .line-content {

        .line-dot {
          margin-right: -6px;
        }
      }

      .detail-wrapper {
        padding-right: 135px;
        margin-top: -70px;

        .list {

          li {
            font-size: 26px;
          }

          .tit-label {
            min-width: 190px;
          }
        }
      }
    }

    .detail-content {
      margin: 150px 0;
      padding: 0 300px;

      .slider-content {

        .slider-wrapper {
          height: 550px;
        }
      }

      .arrow-wrapper {
        margin-top: calc(-550px / 2);
      }
    }

    .animate-dot {
      &.line-below-text {
        div {
          @include line-common (38px, 6px, 30px);
        }
      }
    }

    .works-detail-circle-top {
      @include animate-circle(201px, 201px, 31%, 59%, 15s, 0.4, 250%);
    }

    .works-detail-circle-right {
      @include animate-circle(378px, 378px, 60%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(527px, 527px, 82%, 70%, 15s, 0.45, 350%);
    }

  }
}

@media screen and (max-width: 1440px) {
  .work-detail-page {

    .wrapper {

      .text-wrapper {

        .title {
          margin-left: calc(75px + 20px);
          font-size: 60px;
        }

        .desc-wrapper {

          .description {
            font-size: 25px;
          }

          .line-content {
            width: 90px;
          }
        }
      }

      .line-content {

        .line-dot {
          margin-right: -6px;
        }
      }

      .detail-wrapper {
        padding-right: 110px;
        margin-top: -70px;

        .list {

          li {
            font-size: 26px;
          }

          .tit-label {
            min-width: 170px;
          }
        }
      }
    }

    .detail-content {
      margin: 150px 0;
      padding: 0 250px;

      .slider-content {

        .slider-wrapper {
          height: 500px;
        }
      }

      .arrow-wrapper {
        margin-top: calc(-500px / 2);
      }
    }

    .animate-dot {
      &.line-below-text {
        div {
          @include line-common (38px, 6px, 30px);
        }
      }

      &.line-below-text {
        top: 28%;
      }
    }

    .works-detail-circle-top {
      @include animate-circle(201px, 201px, 31%, 59%, 15s, 0.4, 250%);
    }

    .works-detail-circle-right {
      @include animate-circle(378px, 378px, 60%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(527px, 527px, 82%, 70%, 15s, 0.45, 350%);
    }

  }
}

@media screen and (max-width: 1366px) {
  .work-detail-page {
    .wrapper {
      .text-wrapper {
        .title {
          font-size: 58px;
          margin-left: calc(65px + 10px);
        }

        .desc-wrapper {
          .description {
            font-size: 25px;
          }

          .line-content {
            width: 67px;
          }
        }
      }

      .detail-wrapper {
        padding-right: 115px;

        .list {
          padding: 12px 30px;

          li {
            font-size: 25px;
          }
        }
      }
    }

    .detail-content {
      padding: 0 205px;
      margin-top: 80px;
      margin-bottom: 240px;


      .slider-content {

        .slider-wrapper {
          height: 500px;
        }
      }

      .arrow-wrapper {
        margin-top: calc(-500px / 2);
      }
    }

    .animate-dot {
      &.line-below-text {
        div {
          @include line-common (38px, 6px, 30px);
        }
      }
    }

    .works-detail-circle-top {
      @include animate-circle(201px, 201px, 31%, 59%, 15s, 0.4, 250%);
    }

    .works-detail-circle-right {
      @include animate-circle(378px, 378px, 60%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(527px, 527px, 82%, 70%, 15s, 0.45, 350%);
    }
  }
}

@media screen and (max-width: 1280px) {
  .work-detail-page {
    .wrapper {
      padding-top: 160px;

      .text-wrapper {
        .title {
          margin-left: calc(65px + 10px);
        }

        .desc-wrapper {
          .description {
            font-size: 24px;
          }

          .line-content {
            width: 67px;
          }
        }
      }

      .detail-wrapper {
        padding-right: 115px;

        .list {
          padding: 12px 30px;

          li {
            font-size: 20px;
          }

          .tit-label {
            min-width: 140px;
          }
        }
      }
    }

    .animate-dot {
      &.line-below-text {
        div {
          @include line-common (38px, 6px, 30px);
        }
      }
    }

    .works-detail-circle-top {
      @include animate-circle(201px, 201px, 31%, 59%, 15s, 0.4, 250%);
    }

    .works-detail-circle-right {
      @include animate-circle(378px, 378px, 60%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(527px, 527px, 82%, 70%, 15s, 0.45, 350%);
    }
  }
}

@media screen and (max-width: 1024px) {
  .work-detail-page {
    .wrapper {
      padding-top: 50px;
      align-items: baseline;

      .text-wrapper {
        .title {
          font-size: 60px;
          margin-left: calc(65px + 10px);
        }

        .desc-wrapper {
          .description {
            font-size: 25px;
          }

          .line-content {
            width: 67px;
          }
        }
      }

      .line-content {

        &.addition-line {
          flex-grow: 0;
          width: 200px;
          height: 90px;
          border-right: 1px solid $yellow-main;
          position: relative;
          top: 25px;

          .horizontal-line {
            position: relative;
            top: -26px;
          }

          .line-dot {
            position: relative;
            top: -23px;
          }
        }

      }

      .detail-wrapper {
        padding-right: 85px;
        position: absolute;
        right: 0;
        top: 23%;

        .list {
          padding: 12px 30px;
          max-width: 450px;
          min-width: 350px;

          .tit-label {
            min-width: 135px;
          }
        }
      }
    }

    .detail-content {
      padding: 0 160px;
      height: 460px;
      margin-top: 400px;

      .arrow-wrapper {
        margin-top: calc(-450px / 2);
      }

      .slider-content {
        .slider-wrapper {
          height: 450px;
        }
      }
    }

    .animate-dot {
      &.line-below-text {
        top: 21%;

        div {
          @include line-common (38px, 6px, 30px);
        }
      }

      &.line-right-content {
        bottom: 21%;
        right: 6%;

        div {
          @include line-common(34px, 2px, 25px);
        }
      }

      &.line-below-content {
        left: 34%;

        div {
          @include line-common(66px, 10px, 48px);
        }
      }
    }

    .works-detail-circle-top {
      @include animate-circle(180px, 180px, 39%, 55%, 15s, 0.4, 250%);
    }

    .works-detail-circle-right {
      @include animate-circle(320px, 320px, 53%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(600px, 600px, 73%, 68%, 15s, 0.45, 350%);
    }
  }

  .osx-content {
    .work-detail-page {
      .wrapper {
        .text-wrapper {
          .desc-wrapper {
            .line-content {
              .line-dot {
                margin-left: -15px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .work-detail-page {
    .wrapper {
      .detail-wrapper {
        padding-right: 70px;
      }

      .text-wrapper {
        .title {
          font-size: 52px;
          margin-left: 60px;
        }

        .desc-wrapper {
          margin-top: 0;

          .description {
            font-size: 21px;
            max-width: 530px;
          }

          .line-content {
            width: 55px;
            height: 35px;

            .horizontal-line {
              position: relative;
            }

            .line-dot {
              margin-right: -5px;
            }
          }
        }
      }

      .line-content {

        &.addition-line {
          width: 88px;
          height: 60px;
          top: 33px;
        }

      }

      .detail-wrapper {
        top: 270px;

        .list {
          padding: 5px 30px;
          max-width: 400px;
          min-width: 335px;

          li {
            font-size: 14px;
          }
        }
      }
    }

    .detail-content {
      padding: 0 80px;
      margin: 250px 0 100px 0;

      .arrow-wrapper {
        margin-top: calc(-360px / 2);
      }

      .slider-content {
        .slider-wrapper {
          height: 360px;
        }
      }
    }

    .animate-dot {
      &.line-below-text {
        top: 21%;

        div {
          @include line-common (17px, 4px, 14px);
        }
      }

      &.line-right-content {
        bottom: 28%;
        right: 1%;


        div {
          @include line-common(25px, 2px, 18px);
        }
      }

      &.line-below-content {
        top: 74%;

        div {
          @include line-common(45px, 7px, 38px);
        }
      }
    }

    .works-detail-circle-top {
      display: none;
    }

    .works-detail-circle-right {
      @include animate-circle(230px, 230px, 45%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(320px, 320px, 70%, 68%, 15s, 0.45, 350%);
    }
  }


  .osx-content {
    .work-detail-page {
      .wrapper {
        .text-wrapper {
          .desc-wrapper {
            .line-content {
              .horizontal-line {
                width: 90%;
              }

              .line-dot {
                margin-left: -12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  /* Inherit from 800 */
}

@media screen and (max-width: 576px) {
  .osx-content {
    .work-detail-page {
      .wrapper {
        .text-wrapper {
          .desc-wrapper {
            .line-content {
              .horizontal-line {
                top: -1px;
              }
            }
          }
        }

        .line-content {
          &.addition-line {

            .horizontal-line {
              position: relative;
              top: -14px;
            }

            .line-dot {
              font-size: 35px;
              margin-top: 3px;
            }
          }
        }

      }
    }
  }

  .work-detail-page {
    .wrapper {
      padding-top: 20px;

      .text-wrapper {
        .title {
          font-size: 19px;
          margin-left: 37px;
        }

        .desc-wrapper {
          margin-top: 0;

          .description {
            font-size: 8px;
            max-width: 230px;
          }

          .line-content {
            width: 28px;
            height: 18px;

            .horizontal-line {
              position: relative;
              right: 1px;
              top: -1.5px;
            }

            .line-dot {
              font-size: 35px;
              margin-left: -7px;
            }
          }
        }
      }

      .line-content {

        &.addition-line {
          width: 85px;
          height: 35px;
          top: 20px;

          .line-dot {
            top: -13px;
          }

          .horizontal-line {
            top: -15px;
            left: 1px;
          }
        }

        .line-dot {
          font-size: 45px;
        }
      }

      .detail-wrapper {
        top: 186px;
        padding-right: 50px;

        .list {
          padding: 5px 20px;
          max-width: 340px;
          min-width: 220px;

          li {
            font-size: 9px;
          }

          .tit-label {
            min-width: 60px;
          }
        }
      }
    }

    .detail-content {
      padding: 10px;
      margin: 150px 0 -105px 0;

      .video {
        width: 70%;
      }

      .arrow-wrapper {
        margin-top: calc(-160px / 2);
        margin-left: 35px;
        margin-right: 35px;

        .arrow {
          width: 10px;
          height: 16px;
        }
      }

      .slider-content {
        .slider-wrapper {
          height: 160px;
        }
      }
    }

    .animate-dot {
      &.line-below-text {
        top: 22%;

        div {
          @include line-common (9px, 4px, 10px);
        }
      }

      &.line-right-content {
        bottom: 37%;

        div {
          @include line-common(18px, 2px, 12px);
        }
      }

      &.line-below-content {
        top: 68%;

        div {
          @include line-common(26px, 7px, 30px);
        }
      }
    }

    .works-detail-circle-right {
      @include animate-circle(110px, 110px, 48%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(167px, 167px, 70%, 68%, 15s, 0.45, 350%);
    }
  }

  .osx-content {
    .work-detail-page {
      .wrapper {
        .text-wrapper {
          .desc-wrapper {
            .line-content {
              .line-dot {
                margin-left: -7px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .work-detail-page {
    .wrapper {
      padding-top: 0;

      .text-wrapper {
        .desc-wrapper {
          .line-content {
            .description {
              max-width: 180px;
            }
          }
        }
      }

      .line-content {
        &.addition-line {
          width: 50px;
          top: 23px;
        }
      }

      .detail-wrapper {
        top: 29.8%;

        .list {
          padding: 2px 17px;
          max-width: 290px;
          min-width: 200px;
        }
      }
    }

    .detail-content {
      margin: 130px 0 -105px 0;

      .arrow-wrapper {
        margin-left: 11px;
        margin-right: 11px;
      }
    }

    .animate-dot {
      &.line-below-content {
        top: 68%;

        div {
          @include line-common(16px, 5px, 16px);
        }
      }
    }

    .works-detail-circle-right {
      @include animate-circle(97px, 97px, 44%, -2%, 10s, 0.45, 300%);
    }

    .works-detail-circle-bottom {
      @include animate-circle(127px, 127px, 60%, 68%, 15s, 0.45, 350%);
    }
  }
}