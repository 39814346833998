.event-wrapper {
	width: 100%;
	height: 290px;
	color: white;
	margin: 10px 0;
	background-color: black;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.wrapper {
		display: flex;
		align-items: center;
		height: 100%;
		background-size: cover;
		background-position: center;

		.text-wrapper {
			margin-left: 108px;
			width: 100%;

			.title {
				font-family: 'Montserrat Bold';
				font-size: 76px;
				line-height: 1.3;

				span {
					cursor: pointer;
				}
			}

			.description {
				font-family: 'Montserrat Light';
				font-size: 30px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					cursor: pointer;
				}

				.work-content {
					margin-right: 40px;
					transition: all 0.2s ease;

					&:hover {
						transform: scale(1.2);
					}

					.work-title {
						font-size: 15px;
						font-family: 'Montserrat Regular';
						text-transform: uppercase;
						text-align: right;
						cursor: pointer;
						line-height: 2.5;
					}

					.line-content {
						padding: 0;
						justify-content: flex-end;

						.line-dot {
							font-size: 60px;
							margin-left: -9px;
							margin-top: -33px;
							color: white;
						}

						.horizontal-line {
							top: -2.5px;
							width: 120px;
							height: 1px;
							background: linear-gradient(90deg, white 0%, white 100%);
							background-size: 300% auto;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.event-wrapper {
		height: 230px;

		.wrapper {

			.text-wrapper {
				margin-left: 108px;

				.title {
					font-size: 64px;
					line-height: 1.2;
				}

				.description {
					font-size: 23px;

					.work-content {

						.work-title {
							font-size: 13px;
						}

						.line-content {

							.horizontal-line {
								width: 100px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.event-wrapper {
		height: 200px;

		.wrapper {

			.text-wrapper {
				margin-left: 108px;

				.title {
					font-size: 62px;
					line-height: 1.2;
				}

				.description {
					font-size: 22px;

					.work-content {

						.work-title {
							font-size: 13px;
						}

						.line-content {

							.horizontal-line {
								width: 100px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {

	.event-wrapper {
		height: 180px;

		.wrapper {
			.text-wrapper {
				margin-left: 75px;

				.title {
					font-size: 51px;
				}

				.description {
					font-size: 20px;

					.work-content {

						.work-title {
							font-size: 12px;
						}

						.line-content {

							.horizontal-line {
								width: 80px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {

	.event-wrapper {
		height: 150px;

		.wrapper {
			.text-wrapper {

				.title {
					font-size: 47px;
				}

				.description {
					font-size: 18px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.event-wrapper {
		height: 115px;
		margin: 4px 0;

		.wrapper {
			.text-wrapper {
				margin-left: 40px;

				.title {
					font-size: 37px;
				}

				.description {
					font-size: 16px;

					.work-content {

						.work-title {
							font-size: 7px;
							padding-right: 5px;
						}

						.line-content {

							.horizontal-line {
								width: 45px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.event-wrapper {
		height: 60px;
		margin: 2px 0;

		.wrapper {
			.text-wrapper {
				margin-left: 27px;

				.title {
					font-size: 15px;
				}

				.description {
					font-size: 7px;

					.work-content {
						margin-right: 20px;

						.work-title {
							font-size: 7px;
							margin-right: 5px;
						}

						.line-content {
							.horizontal-line {
								width: 45px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {}