.contact-form {
	width: 100%;
	margin: 0;

	.col-lg-6,
	.col-lg-5 {
		padding: 0;
		z-index: 1;
	}

	.contact-detail {
		margin-top: 55px;
		margin-right: 10px;

		.address {
			margin-bottom: 45px;
		}
	}

	.callback {
		margin-top: -20px;

		.callback-title {
			font-size: 40px;
			font-family: "Montserrat Bold";
			color: $yellow-main;
			text-align: center;
			margin-bottom: 30px;
			text-align: center;
		}
	}

	.btn-holder {
		text-align: center;

		.btn-send {
			margin-top: 20px;
			cursor: pointer;
			color: white;
			background-size: 200% auto;
			background-image: linear-gradient(45deg,
					#6526ef 0%,
					#07c4fe 100%,
				);
			font-family: "Montserrat Bold";
			font-size: 20px;
			border-radius: 3px;
			padding: 5px 15px;
			border: none;
			outline: none;
		}
	}
}

@media screen and (max-width: 1640px) {
	.contact-form {

		.callback {
			.callback-title {
				font-size: 30px;
			}
		}

		.btn-holder {

			.btn-send {
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.contact-form {
		.contact-detail {
			margin-right: 40px;
		}

		.btn-holder {
			max-width: 78%;
			flex: 0 0 78%;
		}
	}
}

@media screen and (max-width: 1024px) {
	.contact-form {
		.contact-detail {
			margin-right: 50px;

			.address {
				margin-bottom: 25px;
			}
		}

		.btn-holder {
			max-width: 78%;
			flex: 0 0 78%;
		}
	}
}

@media screen and (max-width: 800px) {
	.contact-form {
		.contact-detail {
			margin-top: 20px;

			.address {
				margin-bottom: 15px;
			}
		}

		.callback {
			margin-top: 70px;
			margin-left: 90px;

			.title {
				font-size: 15px;
			}

			.callback-title {
				font-size: 30px;
				padding-right: 75px;
			}
		}

		.btn-holder {
			padding-right: 30px;
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.contact-form {
		.contact-detail {
			margin-top: 0;

			.address {
				margin-bottom: 15px;
			}
		}

		.callback {
			margin-top: 70px;
			margin-left: 90px;

			.title {
				font-size: 7px;

				&.title-custom {
					width: 30%;
				}
			}

			.custom {
				width: 70%;
				padding: 0 5px;
			}

			.callback-title {
				font-size: 14px;
				margin-bottom: 10px;
				padding-right: 15px;
			}
		}

		.btn-holder {
			max-width: 90%;
			flex: 0 0 90%;
			padding-left: 55px;

			.btn-send {
				font-size: 10px;
				padding: 3px 10px;
				margin-top: 10px;
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.contact-form {
		.callback {
			margin-left: 70px;
		}
	}
}

@media screen and (max-width: 320px) {
	.contact-form {
		.contact-detail {
			margin-left: 10px;
			margin-right: 16px;
		}

		.callback {
			margin-left: 50px;
		}
	}
}