.osx-content {
	.countdown-page {
		.wrapper-content {
			.creative-text {
				.text-color {
					.row-2 {
						display: -webkit-box;
						-webkit-box-align: baseline;
						-webkit-box-pack: end;
					}
				}

				.line-content {
					&.line-2 {
						.line-dot {
							margin-right: -4px;
							margin-left: -4px;
						}
					}
				}
			}
		}
	}
}

.countdown-page {
	text-align: center;
	padding-top: 0;

	.wrapper-content {
		position: relative;
		z-index: 1;
		margin: 50px 300px 0px 300px;

		.per-logo {
			width: 500px;
			height: 55px;
			margin: auto;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.creative-text {
			margin-bottom: -19px;

			.text-color {
				text-align: center;
				padding-top: 0;
				margin: 0;

				.creative {
					margin: 0;

					.text {
						font-size: 38px;
						letter-spacing: 0.5px;
					}
				}

				.row-2 {
					display: flex;
					justify-content: flex-end;
					align-items: baseline;
				}

				.experiential {
					font-size: 28px;
				}

				.marketing {
					font-size: 22px;
				}

				.agency {
					font-size: 13px;
				}
			}

			.line {
				border-left: 1px solid #08f2f2;
				height: 30px;
				position: relative;
				top: 26px;
			}

			.line-content {
				position: relative;

				&.line-1 {
					top: -2px;
					width: 85%;
					padding-right: 10px;

					.line-dot {
						margin-left: -8px;
					}
				}

				&.line-2 {
					top: -3px;
					width: 34.5%;

					.horizontal-line {
						width: 98%;
					}

					.line-dot {
						margin-right: -8px;
					}
				}
			}
		}

		.intro-wrapper {
			border: 1px solid #08f2f2;
			border-top: none;
			padding-bottom: 100px;
		}

		.introduce-text {
			color: $yellow-main;
			padding: 50px 0;

			.amazing-size {
				font-size: 106px;
				font-family: 'Montserrat ExtraBold';
				letter-spacing: 14px;
			}

			.comming-soon,
			.our {
				font-size: 50px;
				letter-spacing: 41px;
				padding-left: 20px;
				font-family: 'Montserrat Light';
			}
		}

		.timer-wrapper {
			display: flex;
			justify-content: center;
			color: $yellow-main;

			.countdown {
				margin: 0 67px;

				&.days {
					.time-cirlce {
						background-image: linear-gradient(to right, #320447, #320447);
					}
				}

				&.hours {
					.time-cirlce {
						background-image: linear-gradient(to right, #2c0649, #2c0649);
					}
				}

				&.minutes {
					.time-cirlce {
						background-image: linear-gradient(to right, #24074b, #21074c);
					}
				}

				&.seconds {
					.time-cirlce {
						background-image: linear-gradient(to right, #1c084d, #19094e);
					}
				}

				.circle {
					border: 1px;
					width: 150px;
					height: 150px;
					background-color: white;
					background-image: linear-gradient(135deg,
							#08f2f2 0%,
							#a149fc 25%,
							#08f2f2 50%,
							#a149fc 75%,
							#08f2f2 100%);
					border-radius: 50%;
					animation: rotate-countdown 2s infinite;
					position: absolute;
					z-index: 0;
				}

				.wrapper {
					position: relative;
					width: 150px;
					z-index: 1;
				}

				.time-cirlce {
					border-radius: 50%;
					background-image: linear-gradient(to right, #a306aa, #1e1bca);
					width: 145px;
					height: 145px;
					margin-bottom: 20px;
					position: relative;
					left: 2.4px;
					top: 2.5px;
				}

				.time-value {
					font-size: 65px;
					padding: 30px;
					padding-top: 40px;
					font-family: 'Montserrat Medium';
				}

				.time-label {
					font-size: 25px;
					letter-spacing: 2px;
					font-family: 'Montserrat Light';
				}
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.countdown-page {

		.wrapper-content {

			.per-logo {
				width: 450px;
				height: 50px;
			}

			.creative-text {

				.text-color {

					.creative {

						.text {
							font-size: 36px;
						}
					}

					.experiential {
						font-size: 26px;
					}

					.marketing {
						font-size: 20px;
					}

					.agency {
						font-size: 11px;
					}
				}

				.line-content {

					&.line-1 {
						flex: 0 0 40.666667%;
						max-width: 40.666667%;
					}

					&.line-2 {
						width: 32%;
					}
				}
			}

			.intro-wrapper {
				border: 1px solid #08f2f2;
				border-top: none;
				padding-bottom: 100px;
			}

			.introduce-text {

				.amazing-size {
					font-size: 70px;
				}

				.comming-soon,
				.our {
					font-size: 40px;
					letter-spacing: 26px;
					padding-left: 15px;
				}
			}

			.timer-wrapper {

				.countdown {
					margin: 0 28px;

					.circle {
						width: 140px;
						height: 140px;
					}

					.wrapper {
						width: 140px;
					}

					.time-cirlce {
						width: 135px;
						height: 135px;
					}

					.time-value {
						font-size: 60px;
						padding-top: 35px;
					}

					.time-label {
						font-size: 22px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.countdown-page {

		.wrapper-content {
			margin: 50px 140px 0px 140px;

			.creative-text {

				.text-color {

					.creative {

						.text {
							font-size: 31px;
						}
					}

					.row-2 {
						margin-top: -45px;
					}

					.experiential {
						font-size: 24px;
					}

					.marketing {
						font-size: 18px;
					}

					.agency {
						font-size: 10px;
					}
				}

				.line-content {

					&.line-1 {
						flex: 0 0 41.666667%;
						max-width: 41.666667%;
					}

					&.line-2 {
						width: 36.3%;
					}
				}
			}

			.intro-wrapper {
				padding-bottom: 70px;
			}

			.introduce-text {
				padding: 30px 0;

				.amazing-size {
					font-size: 57px;
				}

				.comming-soon,
				.our {
					font-size: 33px;
					letter-spacing: 23px;
					padding-left: 12px;
				}
			}

			.timer-wrapper {

				.countdown {
					margin: 0 16px;

					.circle {
						width: 130px;
						height: 130px;
					}

					.wrapper {
						width: 130px;
					}

					.time-cirlce {
						width: 125px;
						height: 125px;
					}

					.time-value {
						font-size: 48px;
						padding-top: 40px;
					}

					.time-label {
						font-size: 20px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.countdown-page {

		.wrapper-content {
			margin: 50px 140px 0px 140px;

			.per-logo {
				width: 350px;
				height: 40px;
			}

			.creative-text {

				.line-content {

					&.line-1 {
						flex: 0 0 39.666667%;
						max-width: 39.666667%;
					}

					&.line-2 {
						width: 26%;
					}
				}
			}

			.introduce-text {

				.amazing-size {
					font-size: 49px;
				}

				.comming-soon,
				.our {
					font-size: 27px;
					letter-spacing: 23px;
					padding-left: 8px;
				}
			}

			.timer-wrapper {

				.countdown {
					margin: 0 16px;

					.circle {
						width: 120px;
						height: 120px;
					}

					.wrapper {
						width: 120px;
					}

					.time-cirlce {
						width: 115px;
						height: 115px;
					}

					.time-value {
						font-size: 40px;
					}

					.time-label {
						font-size: 18px;
					}
				}
			}
		}
	}
}