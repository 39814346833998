.footer {
	position: absolute;
	width: 100%;

	.copy-right {
		color: #86868b;
		text-align: center;
		padding-top: 10px;
		padding-bottom: 50px;
		font-size: 15px;
		font-family: "Montserrat Regular";
	}

	.footer-content {
		margin: 0 108px !important;
		padding-top: 135px;
		padding-bottom: 20px;

		.logo-content {
			padding-left: 0;
			flex: 0 0 30%;
			max-width: 30%;

			.logo {
				width: 280px;
				height: 35px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.description {
				font-size: 14px;
				font-family: "Montserrat Light";
				color: $yellow-main;
				line-height: 30px;
			}
		}

		.map-content {
			.title {
				text-transform: uppercase;
				font-family: "Montserrat Bold";
				font-size: 20px;
				margin-bottom: 20px;
			}

			color: white;
			flex: 0 0 40%;
			max-width: 40%;
		}

		.text-content {
			color: white;
			flex: 0 0 15%;
			max-width: 15%;

			.title {
				text-transform: uppercase;
				font-family: "Montserrat Bold";
				font-size: 20px;
				margin-bottom: 20px;
			}

			.list-text {
				padding: 0;

				a {
					color: white;
					text-transform: capitalize;
					font-family: "Montserrat Regular";
					font-size: 19px;
					line-height: 60px;
					text-decoration: none;
					transition: all 0.2s ease;

					&:hover {
						color: $yellow-main;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.footer {

		.footer-content {
			margin: 0 90px;

			.map-content {
				.title {
					font-size: 18px;
					margin-bottom: 15px;
				}
			}

			.text-content {

				.title {
					font-size: 18px;
					margin-bottom: 15px;
				}

				.list-text {

					a {
						font-size: 16px;
						line-height: 50px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.footer {
		.copy-right {
			font-size: 14px;
		}

		.footer-content {
			margin: 0 72px !important;
			padding-top: 96px;

			.logo-content {
				.logo {
					width: 240px;
					height: 30px;
				}

				.description {
					font-size: 13px;
				}
			}

			.text-content {
				.title {
					font-size: 15px;
				}

				.list-text {
					a {
						font-size: 14px;
						line-height: 3;
					}
				}
			}

			.map-content {
				.title {
					font-size: 15px;
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.footer {
		.copy-right {
			font-size: 13px;
		}

		.footer-content {

			.logo-content {
				.logo {
					width: 200px;
					height: 26px;
				}

				.description {
					font-size: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.footer {

		.footer-content {

			.logo-content {
				.logo {
					width: 200px;
					height: 23px;
				}

				.description {
					font-size: 10px;
				}
			}

			.text-content {
				.title {
					font-size: 12px;
				}

				.list-text {
					a {
						font-size: 11px;
						line-height: 3;
					}
				}
			}

			.map-content {
				.title {
					font-size: 12px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.footer {
		.copy-right {
			font-size: 15px;
		}

		.footer-content {
			margin: 0 35px !important;

			.logo-content {
				flex: 0 0 100%;
				max-width: 100%;
				margin-bottom: 50px;

				.logo {
					width: 150px;
					height: 19px;
				}

				.description {
					font-size: 7.7px;
				}
			}

			.text-content {
				flex: 0 0 40%;
				max-width: 40%;

				.title {
					font-size: 23px;
				}

				.list-text {
					a {
						font-size: 21px;
					}
				}
			}

			.map-content {
				flex: 0 0 100%;
				max-width: 100%;
				margin-top: 40px;

				.title {
					font-size: 23px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.footer {
		.copy-right {
			font-size: 10px;
		}

		.footer-content {

			.logo-content {
				margin-bottom: 30px;

				.logo {
					width: 80px;
					height: 10px;
				}

				.description {
					font-size: 4.1px;
					line-height: 9px;
				}
			}

			.text-content {

				.title {
					font-size: 10px;
					margin-bottom: 5px;
				}

				.list-text {
					a {
						font-size: 10px;
					}
				}
			}

			.map-content {
				margin-top: 40px;

				.title {
					font-size: 10px;
				}

				iframe {
					height: 150px !important;
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.footer {

		.copy-right {
			font-size: 9px;
		}
	}
}