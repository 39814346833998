@keyframes polygon-animate {
	0% {
		fill: #1bf988
	}

	20% {
		fill: #5a59e5
	}

	50% {
		fill: #1bf988
	}

	75% {
		fill: #5a59e5
	}

	100% {
		fill: #1bf988
	}
}

.osx-content {
	.job-title-wrapper {
		display: -webkit-box;
		-webkit-box-align: center;

		.title-wrapper {
			.job-title {
				display: -webkit-box;
				-webkit-box-pack: justify;
			}

			.line-content {
				display: -webkit-box;

				.horizontal-line {
					top: -1px !important;
				}

				.line-dot {
					margin-right: -2px;
				}
			}
		}
	}
}

.job-title-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 28px;
	cursor: pointer;

	&.active {
		color: #ffc000;
		padding-left: 50px;
		transition: all 0.1s;

		.no {
			font-size: 110px;
			transition: all 0.3s;
		}

		.title {
			font-size: 47px;
			transition: all 0.3s;
		}

		.arrow-down {
			margin-bottom: 15px;
		}

		.horizontal-line {
			background: linear-gradient(90deg, #ffc000 0%, #ffc000 50%, #ffc000 100%);
		}
	}

	&.unactive {
		background: linear-gradient(45deg,
				#5a59e5 0%,
				#1bf988 12.5%,
				#5a59e5 25%,
				#1bf988 37.5%,
				#5a59e5 50%,
				#1bf988 62.5%,
				#5a59e5 75%,
				#1bf988 87.5%,
				#5a59e5 100%,
			);
		background-size: 150% auto;
		animation: shine 10s linear infinite;
		color: #000;
		background-clip: text;
		-webkit-text-fill-color: transparent;

		.no {
			font-size: 70px;
		}

		.title {
			font-size: 25px;
		}

		svg {
			animation: polygon-animate 6s linear infinite;
		}

		.horizontal-line {
			background: white;
			background: linear-gradient(90deg, #5a59e5 0%, #1bf988 25%, #5a59e5 50%, #1bf988 75%, #5a59e5 100%);
		}
	}

	.no {
		margin-right: 30px;
		min-width: 80px;
		text-align: right;
		font-family: 'Montserrat Bold';
	}

	.title-wrapper {
		line-height: 1.2;

		.job-title {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.title {
				font-family: 'Montserrat ExtraBold';
				max-width: 300px;
			}

			.arrow-down {
				width: 10px;
				height: 10px;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.line-content {
			.horizontal-line {
				height: 1px;
				width: 470px;
				background-size: 300% auto;
				animation: shine 5s linear infinite;
				top: -2px !important;
			}

			.line-dot {
				margin-top: -27px;
				font-size: 45px;
				margin-right: -3px;
				line-height: 1.1 !important;
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.job-title-wrapper {
		margin-bottom: 24px;

		&.active {
			padding-left: 40px;

			.no {
				font-size: 80px;
			}

			.title {
				font-size: 36px;
			}
		}

		&.unactive {

			.no {
				font-size: 65px;
			}

			.title {
				font-size: 23px;
			}
		}

		.title-wrapper {

			.line-content {
				.horizontal-line {
					width: 345px;
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.job-title-wrapper {
		margin-bottom: 20px;

		&.active {

			.no {
				font-size: 75px;
			}

			.title {
				font-size: 34px;
			}
		}

		&.unactive {

			.no {
				font-size: 60px;
			}

			.title {
				font-size: 20px;
			}
		}

		.title-wrapper {

			.line-content {
				.horizontal-line {
					width: 320px;
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.job-title-wrapper {
		margin-bottom: 20px;

		&.active {
			.no {
				font-size: 75px;
			}

			.title {
				font-size: 36px;
			}
		}

		&.unactive {
			.no {
				font-size: 50px;
			}

			.title {
				font-size: 18px;
			}
		}

		.title-wrapper {
			line-height: 1;

			.line-content {
				.horizontal-line {
					width: 350px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.job-title-wrapper {
		margin-bottom: 20px;

		.no {
			margin-right: 15px;
		}

		&.active {
			padding-left: 20px;

			.no {
				font-size: 57px;
			}

			.title {
				font-size: 29px;
			}
		}

		&.unactive {
			.no {
				font-size: 43px;
			}

			.title {
				font-size: 16px;
			}
		}

		.title-wrapper {
			line-height: 1;

			.line-content {
				.horizontal-line {
					width: 275px;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.job-title-wrapper {

		&.active {
			padding-left: 20px;

			.no {
				font-size: 47px;
			}

			.title {
				font-size: 21px;
			}

			.arrow-down {
				width: 7px;
				height: 7px;
				margin-bottom: 10px;
			}
		}

		&.unactive {
			.no {
				font-size: 37px;
			}

			.title {
				font-size: 14px;
			}
		}

		.title-wrapper {

			.line-content {
				.horizontal-line {
					width: 200px;
				}
			}
		}
	}

	.osx-content {
		.job-title-wrapper {
			.title-wrapper {
				.line-content {
					.line-dot {
						margin-right: -4px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.job-title-wrapper {

		.no {
			margin-right: 10px;
		}

		&.active {
			padding-left: 20px;

			.no {
				font-size: 40px;
			}

			.title {
				font-size: 18px;
			}

			.arrow-down {
				width: 7px;
				height: 7px;
				margin-bottom: 10px;
			}
		}

		&.unactive {
			.no {
				font-size: 32px;
			}

			.title {
				font-size: 13px;
			}
		}

		.title-wrapper {

			.line-content {
				.horizontal-line {
					width: 170px;
				}
			}
		}
	}
}