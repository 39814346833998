.client-feedback-section {
	position: relative;

	.animate-dot {
		@include z-index(absolute, 1);
		top: -120px;
		right: 8%;
		background: linear-gradient(45deg,
				#bb1ae5 0%,
				#15c8fc 25%,
				#bb1ae5 50%,
				#15c8fc 75%,
				#bb1ae5 100%);
		@include animate-dot(3s, 300%, 1);
	}

	.client-feedback-cirle {
		@include animate-circle(195px, 195px, 4%, -1%, 17s, 0.6, 300%);
		background-image: linear-gradient(90deg, rgb(30, 54, 235) 0%, rgb(57, 129, 223) 50%, rgb(30, 54, 235) 100%);
	}

	.client-feedback-large-cirle {
		@include animate-circle(1616px, 1616px, 7%, -50%, 10s, 0.1, 350%);
		background-image: linear-gradient(90deg, rgb(50, 73, 254) 0%, rgb(77, 153, 255) 50%, rgb(50, 73, 254) 100%);
	}

	.client-feedback-left-cirle {
		@include animate-circle(350px, 350px, 60%, 86%, 18s, 0.4, 350%);
		background-image: linear-gradient(90deg, rgb(50, 73, 254) 0%, rgb(143, 41, 222) 50%, rgb(50, 73, 254) 100%);
	}

	.client-feedback-cirle-small {
		@include animate-circle(80px, 80px, 12%, 70%, 10s, 0.6, 350%);
		background-image: linear-gradient(90deg, rgb(143, 41, 222) 0%, rgb(252, 0, 255) 50%, rgb(143, 41, 222) 100%);
	}

	.client-cirle {
		@include animate-circle(1297px, 1297px, 95%, 75%, 16s, 0.6, 250%);
		background-image: linear-gradient(90deg, rgb(103, 0, 107) 0%, rgb(112, 52, 181) 50%, rgb(103, 0, 107) 100%);
		z-index: 1;
	}
}

@media screen and (max-width: 1640px) {
	.client-feedback-section {

		.animate-dot {
			top: -9%;
			right: 8%;

			div {
				@include line-common (34px, 3px, 25px);
			}
		}

		.client-feedback-cirle {
			@include animate-circle(165px, 165px, 1.4%, -1.5%, 17s, 0.6, 300%);
		}

		.client-feedback-large-cirle {
			@include animate-circle(1400px, 1400px, 7%, -45%, 10s, 0.1, 350%);
		}

		.client-feedback-left-cirle {
			@include animate-circle(310px, 310px, 61%, 84%, 18s, 0.4, 350%);
		}

		.client-feedback-cirle-small {
			@include animate-circle(60px, 60px, 10%, 70%, 10s, 0.6, 350%);
		}

		.client-cirle {
			@include animate-circle(1100px, 1100px, 97%, 71%, 18s, 0.6, 400%);
			z-index: 1;
		}
	}
}

@media screen and (max-width: 1366px) {
	.client-feedback-section {

		.animate-dot {
			top: -8%;
			right: 6%;

			div {
				@include line-common (29px, 3px, 20px);
			}
		}

		.client-feedback-cirle {
			@include animate-circle(135px, 135px, 1.4%, -1.5%, 17s, 0.6, 300%);
		}

		.client-feedback-large-cirle {
			@include animate-circle(1145px, 1145px, 7%, -50%, 10s, 0.1, 350%);
		}

		.client-feedback-left-cirle {
			@include animate-circle(253px, 253px, 46%, 86%, 18s, 0.4, 350%);
		}

		.client-feedback-cirle-small {
			@include animate-circle(60px, 60px, 10%, 70%, 10s, 0.6, 350%);
		}

		.client-cirle {
			@include animate-circle(926px, 926px, 72%, 75%, 18s, 0.6, 400%);
			z-index: 1;
		}
	}
}

@media screen and (max-width: 1024px) {
	.client-feedback-section {

		.animate-dot {
			top: -8%;
			right: 6%;

			div {
				@include line-common (29px, 3px, 20px);
			}
		}

		.client-feedback-cirle {
			@include animate-circle(78px, 78px, 1.4%, -1.5%, 17s, 0.6, 300%);
		}

		.client-feedback-large-cirle {
			@include animate-circle(640px, 640px, 7%, -54%, 10s, 0.1, 350%);
		}

		.client-feedback-left-cirle {
			@include animate-circle(141px, 141px, 46%, 86%, 18s, 0.4, 350%);
		}

		.client-feedback-cirle-small {
			@include animate-circle(33px, 33px, 10%, 70%, 10s, 0.6, 350%);
		}

		.client-cirle {
			@include animate-circle(619px, 619px, 119%, 63%, 18s, 0.6, 400%);
		}
	}
}

@media screen and (max-width: 800px) {
	.client-feedback-section {
		display: none;
	}
}

@media screen and (max-width: 576px) {}

@media screen and (max-width: 320px) {}