@mixin box($width, $height) {
	width: $width;
	height: $height;
}

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin font-size($size, $base: 16) {
	font-size: ($size / $base) * 1rem;
}

@mixin z-index($position, $z) {
	position: $position;
	z-index: $z;
}

@mixin animate-circle($width, $height, $top, $right, $animation-time, $opacity, $percent) {
	width: $width;
	height: $height;
	top: $top;
	right: $right;
	opacity: $opacity;
	animation: shine $animation-time linear infinite;
	background-size: $percent auto;
	z-index: 0;
}

@mixin animate-dot($animation-time, $percent, $z-index) {
	animation: shine $animation-time linear infinite;
	-webkit-text-fill-color: transparent;
	background-size: $percent auto;
	background-clip: text;
	-webkit-background-clip: text;
	z-index: $z-index;
}

@mixin line-common($fs, $lp, $lh) {
	font-size: $fs;
	letter-spacing: $lp;
	line-height: $lh;
}