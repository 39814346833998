.works-page {
	position: relative;
	padding-top: 50px;

	.bottom-content {
		padding-left: 35px;

		li {
			margin: 0 55px !important;
		}
	}

	.top-content {
		li {
			margin: 0 65px !important;
		}
	}

	.works-wrapper {
		margin-top: 120px;
		z-index: 2;
		position: relative;

		.work-item {
			margin-left: 108px;
			margin-right: 135px;
			position: relative;
			z-index: 1;
		}

		.button-wrapper {
			margin: 90px 25px 140px 0;
			align-items: center;

			.line-content {
				padding-left: 0;

				.horizontal-line {
					height: 1px;
					background: linear-gradient(90deg, white 0%, white 50%, white 100%);
					width: 100%;
					background-size: 300% auto;
				}

				.line-dot {
					display: none;
				}
			}

			.btn-more-work {
				width: 10%;
				color: $yellow-main;
				z-index: 1;

				.text {
					font-size: 17px;
				}

				.icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		.animate-dot {
			position: absolute;
			background: linear-gradient(45deg,
					#5189f3 0%,
					#15c7fb 12.5%,
					#5189f3 25%,
					#15c7fb 37.5%,
					#5189f3 50%,
					#15c7fb 62.5%,
					#5189f3 75%,
					#15c7fb 87.5%,
					#5189f3 100%);
			@include animate-dot(6s, 300%, 0);

			&.line-below-exhibition {
				top: 910px;
				left: 25%;
			}

			&.line-above-mega {
				top: 1034px;
				right: 20%;
			}

			&.line-right-mega {
				top: 1252px;
				right: 4%;
				background: linear-gradient(45deg,
						#fb08a6 0%,
						#15c8fc 12.5%,
						#fb08a6 25%,
						#15c8fc 37.5%,
						#fb08a6 50%,
						#15c8fc 62.5%,
						#fb08a6 75%,
						#15c8fc 87.5%,
						#fb08a6 100%);
				@include animate-dot(6s, 500%, 0);
			}

			&.line-right-launch {
				top: 2412px;
				right: 3%;
				background: linear-gradient(70deg,
						#fb08a6 0%,
						#15c8fc 12.5%,
						#fb08a6 25%,
						#15c8fc 37.5%,
						#fb08a6 50%,
						#15c8fc 62.5%,
						#fb08a6 75%,
						#15c8fc 87.5%,
						#fb08a6 100%);
				@include animate-dot(6s, 600%, 0);
			}

			&.line-below-launch {
				top: 3296px;
				right: 7%;
				background: linear-gradient(45deg,
						#b91be4 0%,
						#a72fe7 12.5%,
						#b91be4 25%,
						#a72fe7 37.5%,
						#b91be4 50%,
						#a72fe7 62.5%,
						#b91be4 75%,
						#a72fe7 87.5%,
						#b91be4 100%);
				@include animate-dot(6s, 600%, 0);
			}

			&.line-abow-private {
				top: 3227px;
				left: 0%;
				background: linear-gradient(30deg,
						#fb08a6 0%,
						#15c8fc 12.5%,
						#fb08a6 25%,
						#15c8fc 37.5%,
						#fb08a6 50%,
						#15c8fc 62.5%,
						#fb08a6 75%,
						#15c8fc 87.5%,
						#fb08a6 100%);
				@include animate-dot(6s, 800%, 0);
			}

			&.line-below-private {
				top: 4533px;
				left: 5.5%;
				background: linear-gradient(45deg,
						#b91be4 0%,
						#a72fe7 12.5%,
						#b91be4 25%,
						#a72fe7 37.5%,
						#b91be4 50%,
						#a72fe7 62.5%,
						#b91be4 75%,
						#a72fe7 87.5%,
						#b91be4 100%);
				@include animate-dot(6s, 300%, 0);
			}

			&.line-below-special {
				top: 4465px;
				right: 7%;
				background: linear-gradient(45deg,
						#b91be4 0%,
						#a72fe7 12.5%,
						#b91be4 25%,
						#a72fe7 37.5%,
						#b91be4 50%,
						#a72fe7 62.5%,
						#b91be4 75%,
						#a72fe7 87.5%,
						#b91be4 100%);
				@include animate-dot(6s, 300%, 0);
			}

			&.line-above-marketing {
				top: 5687px;
				right: 33%;
				background: linear-gradient(20deg,
						#fb08a6 0%,
						#15c8fc 12.5%,
						#fb08a6 25%,
						#15c8fc 37.5%,
						#fb08a6 50%,
						#15c8fc 62.5%,
						#fb08a6 75%,
						#15c8fc 87.5%,
						#fb08a6 100%);
				@include animate-dot(6s, 800%, 0);
			}

			&.line-above-creative {
				top: 8295px;
				right: 7%;
				background: linear-gradient(20deg,
						#fb08a6 0%,
						#15c8fc 12.5%,
						#fb08a6 25%,
						#15c8fc 37.5%,
						#fb08a6 50%,
						#15c8fc 62.5%,
						#fb08a6 75%,
						#15c8fc 87.5%,
						#fb08a6 100%);
				@include animate-dot(6s, 800%, 0);
			}
		}
	}

	.works-circle-top {
		@include animate-circle(385px, 385px, 9%, -5%, 15s, 0.6, 300%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}

	.works-circle-left {
		@include animate-circle(550px, 550px, 31%, 79%, 18s, 0.4, 350%);
		background-image: linear-gradient(90deg, rgb(50, 73, 254) 0%, rgb(143, 41, 222) 50%, rgb(50, 73, 254) 100%);
	}

	.works-circle-right {
		@include animate-circle(1480px, 1480px, 64%, -22%, 15s, 0.6, 300%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}

	.works-circle-bottom {
		@include animate-circle(1142px, 1142px, 92%, 60%, 18s, 0.4, 350%);
		background-image: linear-gradient(90deg, rgb(103, 0, 107) 0%, rgb(112, 52, 181) 50%, rgb(103, 0, 107) 100%);
	}
}

@media screen and (max-width: 1730px) and (min-width: 1640px) {
	.works-page {
		.top-content {
			li {
				margin: 0 55px !important;
			}
		}

		.bottom-content {
			padding: 0;

			li {
				margin: 0 45px !important;
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.works-page {

		.top-content {
			li {
				margin: 0 37px !important;
			}
		}

		.bottom-content {
			padding: 0;

			li {
				margin: 0 25px !important;
			}
		}

		.works-wrapper {
			margin-top: 80px;

			.work-item {
				margin-right: 140px;
			}

			.button-wrapper {
				margin: 60px 80px 120px 0;

				.btn-more-work {
					width: 11%;

					.text {
						font-size: 16px;
					}

					.icon {
						width: 13px;
						height: 13px;
					}
				}
			}

			.animate-dot {
				&.line-below-exhibition {
					top: 750px;

					div {
						@include line-common (45px, 1px, 30px);
					}
				}

				&.line-above-mega {
					top: 834px;

					div {
						@include line-common (60px, 9px, 50px);
					}
				}

				&.line-right-mega {
					top: 950px;
					right: 3%;
				}

				&.line-right-launch {
					right: 5%;
					top: 1834px;

					div {
						@include line-common (60px, 3px, 42px);
					}
				}

				&.line-below-launch {
					top: 2585px;
					right: 9.3%;

					div {
						@include line-common (30px, 6px, 20px);
					}
				}

				&.line-abow-private {
					div {
						@include line-common (40px, 7px, 27px);
					}
				}

				&.line-below-private {
					top: 3519px;
					left: 7.5%;
				}

				&.line-below-special {
					right: 9.5%;

					div {
						@include line-common (19px, 4px, 8px);
					}
				}

				&.line-above-marketing {
					top: 5487px;
					right: 30%;
				}

				&.line-above-creative {
					top: 6480px;
					right: 7.5%;
				}
			}
		}

		.works-circle-top {
			@include animate-circle(260px, 260px, 8.8%, -2%, 15s, 0.6, 300%);
		}

		.works-circle-left {
			@include animate-circle(394px, 394px, 31%, 79%, 18s, 0.4, 350%);
		}

		.works-circle-right {
			@include animate-circle(1050px, 1050px, 64%, -22%, 15s, 0.6, 300%);
		}

		.works-circle-bottom {
			@include animate-circle(811px, 811px, 92%, 60%, 18s, 0.4, 350%);
		}
	}
}

@media screen and (max-width: 1440px) {
	.works-page {
		.works-wrapper {
			.animate-dot {
				&.line-below-exhibition {
					top: 720px;
				}

				&.line-below-launch {
					top: 2490px;
				}

				&.line-abow-private {
					top: 2427px;
				}

				&.line-below-private {
					top: 3400px;
				}

				&.line-below-special {
					top: 4306px;
				}

				&.line-above-marketing {
					top: 5116px;
				}

				&.line-above-creative {
					top: 6280px;
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.works-page {
		padding-top: 60px;

		.bottom-content {
			li {
				margin: 0 30px !important;
			}
		}

		.works-wrapper {
			margin-top: 35px;

			.work-item {
				margin-left: 70px;
				margin-right: 100px;
			}

			.button-wrapper {
				margin: 40px 40px 110px 0;

				.btn-more-work {
					.text {
						font-size: 15px;
					}

					.icon {
						width: 12px;
						height: 12px;
					}
				}
			}
		}

		.works-circle-top {
			@include animate-circle(241px, 241px, 9%, -2%, 15s, 0.6, 300%);
		}
	}
}

@media screen and (max-width: 1280px) {
	.works-page {
		.top-content {
			li {
				margin: 0 30px !important;
			}
		}

		.works-wrapper {
			.animate-dot {

				&.line-below-exhibition {
					top: 654px;
				}

				&.line-above-mega {
					top: 691px;
				}

				&.line-right-mega {
					top: 840px;
				}

				&.line-abow-private {
					padding-bottom: 10px;
					top: 1723px;

					div {
						@include line-common (30px, 5px, 23px);
					}
				}

				&.line-right-launch {
					top: 1652px;
				}

				&.line-below-special {
					div {
						@include line-common (19px, 4px, 8px);
					}
				}

				&.line-above-marketing {
					top: 4689px;
					right: 28%;

					div {
						@include line-common (45px, 3px, 30px);
					}
				}

				&.line-below-private {
					top: 2412px;

					div {
						@include line-common (40px, 7px, 24px);
					}
				}

				&.line-above-creative {
					top: 4582px;
					right: 2.5%;

					div {
						@include line-common (35px, 5px, 22px);
					}
				}
			}
		}

	}
}

@media screen and (max-width: 1024px) {
	.works-page {

		.top-content {
			li {
				margin: 0 39px !important;
			}
		}

		.bottom-content {
			li {
				margin: 0 33px !important;
			}
		}

		.works-wrapper {

			.button-wrapper {
				margin: 40px 50px 100px 0;

				.btn-more-work {
					margin-left: 0;

					.text {
						font-size: 12px;
					}

					.icon {
						width: 12px;
						height: 12px;
					}
				}
			}

			.animate-dot {
				&.line-below-exhibition {
					top: 485px;
				}

				&.line-above-mega {
					top: 545px;
					right: 24%;

					div {
						@include line-common (50px, 7px, 40px);
					}
				}

				&.line-right-mega {
					top: 628px;

					div {
						@include line-common(20px, 2px, 14px);
					}
				}

				&.line-right-launch {
					right: 2%;

					div {
						@include line-common (50px, 3px, 38px);
					}
				}

				&.line-below-launch {
					top: 1740px;
				}

				&.line-below-private {
					div {
						@include line-common (40px, 7px, 30px);
					}
				}

				&.line-below-special {
					right: 7.5%;
					top: 3015px;

					div {
						@include line-common (22px, 4px, 10px);
					}
				}

				&.line-above-marketing {
					top: 3680px;

					div {
						@include line-common (30px, 2px, 19px);
					}
				}
			}
		}

		.works-circle-top {
			@include animate-circle(241px, 241px, 465px, -2%, 15s, 0.6, 300%);
		}

		.works-circle-left {
			@include animate-circle(314px, 314px, 1711px, 55%, 18s, 0.4, 350%);
		}

		.works-circle-right {
			@include animate-circle(750px, 750px, 65.2%, -22%, 15s, 0.6, 300%);
		}

		.works-circle-bottom {
			@include animate-circle(611px, 611px, 92%, 60%, 18s, 0.4, 350%);
		}
	}
}

@media screen and (max-width: 800px) {
	.works-page {
		.top-content {
			li {
				margin: 0 33px !important;
			}
		}

		.works-wrapper {
			.work-item {
				margin: 0;
			}

			.button-wrapper {
				margin: 20px 40px 110px 0;

				.btn-more-work {
					width: 16%;

					.text {
						font-size: 12px;
					}

					.icon {
						width: 18px;
						height: 10px;
					}
				}
			}

			.animate-dot {
				&.line-below-exhibition {
					display: none;
				}

				&.line-above-mega {
					top: 421px;
					right: 50%;

					div {
						@include line-common (40px, 6px, 30px);
					}
				}

				&.line-right-mega {
					top: 487px;
					right: 15%;

					div {
						@include line-common (20px, 1px, 12px);
					}

				}

				&.line-right-launch {
					right: 86.9%;
					top: 1005px;
					transform: rotate(90deg);

					div {
						@include line-common (25px, 3px, 25px);
					}
				}

				&.line-below-launch {
					top: 1623px;
					right: 45.3%;

					div {
						@include line-common (30px, 6px, 22px);
					}
				}

				&.line-abow-private {
					top: 1690px;
					left: 83%;
					transform: rotate(90deg);

					div {
						@include line-common (25px, 4px, 17px);
					}
				}

				&.line-below-private {
					top: 2281px;
					left: 5.3%;

					div {
						@include line-common (40px, 7px, 30px);
					}
				}

				&.line-below-special,
				&.line-above-marketing {
					display: none;
				}

				&.line-above-creative {
					top: 3490px;
					right: 0.5%;

					div {
						@include line-common (15px, 1px, 12px);
					}
				}
			}
		}

		.works-circle-top {
			@include animate-circle(241px, 241px, 405px, -7%, 15s, 0.6, 300%);
		}

		.works-circle-left {
			@include animate-circle(266px, 266px, 1590px, 53%, 18s, 0.4, 350%);
		}

		.works-circle-right {
			@include animate-circle(604px, 604px, 65.3%, -22%, 15s, 0.6, 300%); //3640px
		}

		.works-circle-bottom {
			@include animate-circle(546px, 546px, 95%, 60%, 18s, 0.4, 350%);
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.works-page {
		.top-content {
			li {
				margin: 0 12px !important;
			}
		}

		.bottom-content {
			li {
				margin: 0 11px !important;
			}
		}

		.works-wrapper {
			.button-wrapper {
				margin: 20px 40px 85px 0;

				&.row {
					flex-wrap: nowrap;
				}

				.btn-more-work {
					width: 50%;
				}
			}

			.animate-dot {
				&.line-above-mega {
					top: 246px;
					right: 50%;

					div {
						@include line-common (20px, 5px, 17px);
					}
				}

				&.line-right-mega {
					top: 290px;
					right: 15%;

					div {
						@include line-common (20px, 1px, 12px);
					}

				}

				&.line-right-launch {
					right: 79%;
					top: 630px;

					div {
						@include line-common (19px, 3px, 15px);
					}
				}

				&.line-below-launch {
					top: 982px;
					right: 45.3%;

					div {
						@include line-common (25px, 6px, 22px);
					}
				}

				&.line-abow-private {
					top: 1360px;
					left: 85%;
					transform: rotate(90deg);

					div {
						@include line-common (19px, 5px, 10px);
					}
				}

				&.line-below-private {
					top: 1755px;
					left: 5.3%;

					div {
						@include line-common (25px, 6px, 24px);
					}
				}

				&.line-above-creative {
					top: 2560px;
					right: -4.5%;

					div {
						@include line-common (17px, 1px, 9px);
					}
				}
			}
		}

		.works-circle-top {
			@include animate-circle(126px, 126px, 285px, -7%, 15s, 0.6, 300%);
		}

		.works-circle-left {
			@include animate-circle(120px, 120px, 1045px, 65%, 18s, 0.4, 350%);
		}

		.works-circle-right {
			@include animate-circle(317px, 317px, 2183px, -32%, 15s, 0.6, 300%);
		}

		.works-circle-bottom {
			@include animate-circle(222px, 222px, 93%, 60%, 18s, 0.4, 350%);
		}
	}
}

@media screen and (max-width: 412px) and (min-width: 376px) {

	.works-page {

		.top-content {
			li {
				margin: 0 7px !important;
			}
		}

		.bottom-content {
			li {
				margin: 0 8px !important;
			}
		}

		.works-wrapper {
			.button-wrapper {
				.btn-more-work {
					.text {
						font-size: 8px;
					}
				}
			}

			.animate-dot {
				&.line-below-launch {
					div {
						@include line-common(20px, 6px, 20px);
					}
				}

				&.line-below-private {
					div {
						@include line-common(16px, 5px, 20px);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.works-page {
		.works-wrapper {
			.animate-dot {
				&.line-right-launch {

					div {
						@include line-common (17px, 3px, 15px);
					}
				}

				&.line-abow-private {
					top: 1360px;
					left: 83%;
					transform: rotate(90deg);

					div {
						@include line-common (19px, 5px, 10px);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.works-page {

		.top-content {
			li {
				margin: 0 7px !important;
			}
		}

		.bottom-content {
			li {
				margin: 0 8px !important;
			}
		}

		.works-wrapper {
			.button-wrapper {
				.btn-more-work {
					.text {
						font-size: 8px;
					}
				}
			}

			.animate-dot {
				&.line-below-launch {
					div {
						@include line-common(20px, 6px, 20px);
					}
				}

				&.line-below-private {
					div {
						@include line-common(16px, 5px, 20px);
					}
				}
			}
		}
	}
}