@media screen and (max-width: 576px) {
	.osx-content {
		.section-title {
			.line-content {
				.line-dot {
					margin-top: -10px !important;
				}

				.horizontal-line {
					position: relative;
					top: -1px;
				}
			}
		}
	}
}