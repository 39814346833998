.osx-content {
	.contact-page {
		.title-content {
			display: -webkit-box;
			-webkit-box-align: baseline;
			background: linear-gradient(45deg,
					#08f2f2 0%,
					#08f2f2 10%,
					$violet-1 15%,
					#08f2f2 37.5%,
					$violet-1 50%,
					#08f2f2 62.5%,
					$violet-1 80%,
					#08f2f2 95%,
					#08f2f2 100%,
				);
			background-size: 200% auto;
			animation: shine 6s linear infinite;
			color: #000;
			background-clip: text;
			-webkit-text-fill-color: transparent;

			.line-content {
				display: -webkit-box;

				.line-dot {
					margin-left: -5px;
				}
			}
		}
	}
}

.contact-page {
	position: relative;

	.title-content {
		padding-bottom: 20px;
		display: flex;
		align-items: baseline;
		width: 100%;
		background: linear-gradient(45deg,
				$violet-1 0%,
				$violet-1 5%,
				#08f2f2 12.5%,
				$violet-1 25%,
				#08f2f2 37.5%,
				$violet-1 50%,
				#08f2f2 62.5%,
				$violet-1 75%,
				#08f2f2 87.5%,
				$violet-1 92%,
				$violet-1 100%,
			);
		background-size: 300% auto;
		animation: shine 10s linear infinite;
		color: #000;
		background-clip: text;
		-webkit-text-fill-color: transparent;

		.contact-title {
			font-size: 145px;
			font-family: 'Montserrat ExtraBold';
			color: white;
			margin: 0 15px;
		}

		.left-line {
			.horizontal-line {
				height: 1px;
				background: white;
				background: linear-gradient(90deg, #a149fc 0%, #08f2f2 25%, #a149fc 50%, #08f2f2 75%, #a149fc 100%);
				background-size: 500% auto;
				animation: shine 8s linear infinite;
				width: 240px;
			}

			.line-dot {
				font-size: 68px;
				margin-left: -6px;
			}
		}
	}

	.contact-content {
		position: relative;
		margin-left: 230px;
		margin-right: 5%;
		z-index: 1;

		.contact-form {
			margin: 0;

			.address {
				border-bottom: 1px solid #454f54;
				width: 520px;
				padding-bottom: 25px;
				margin-bottom: 25px;
			}
		}

		.animate-dot {
			background: linear-gradient(45deg,
					#00eee6 0%,
					#ea01cf 12.5%,
					#00eee6 25%,
					#ea01cf 37.5%,
					#00eee6 50%,
					#ea01cf 62.5%,
					#00eee6 75%,
					#ea01cf 87.5%,
					#00eee6 100%);
			@include animate-dot(6s, 200%, 1);

			&.line-below-address {
				position: absolute;
				background-size: 800% auto;
				top: 37%;
			}

			&.line-below-form {
				margin-top: 200px;
				margin-right: 200px;
				text-align: right;
			}
		}

		.contact-cirle {
			@include animate-circle(720px, 720px, -12.5%, 3.4%, 20s, 0.8, 100%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 30%, rgb(138, 158, 174) 95%);
			transform: none;
		}
	}

	.contact-cirle-lelt {
		@include animate-circle(426px, 426px, 37%, 84%, 18s, 0.2, 300%);
		background-image: linear-gradient(90deg, rgb(81, 0, 52) 0%, rgb(235, 8, 189) 50%, rgb(81, 0, 52) 100%);
	}

	.contact-cirle-right {
		@include animate-circle(145px, 145px, 77%, 9%, 20s, 0.3, 200%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}
}

@media screen and (max-width: 1640px) {
	.contact-page {

		.title-content {

			.contact-title {
				font-size: 120px;
			}
		}

		.contact-content {

			.contact-form {

				.address {
					width: 450px;
				}
			}

			.animate-dot {

				&.line-below-address {
					top: 37%;
				}

				&.line-below-form {
					margin-top: 200px;
					margin-right: 200px;
				}
			}

			.contact-cirle {
				@include animate-circle(550px, 550px, -9.5%, 5.4%, 20s, 0.8, 100%);
				background-image: linear-gradient(90deg, rgb(0, 10, 122) 30%, rgb(138, 158, 174) 95%);
				transform: none;
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.contact-page {

		.title-content {

			.contact-title {
				font-size: 100px;
			}
		}

		.contact-content {

			.animate-dot {

				&.line-below-address {
					top: 33%;
				}

				&.line-below-form {
					margin-top: 200px;
					margin-right: 200px;
				}
			}

			.contact-cirle {
				@include animate-circle(550px, 550px, -10.5%, 2%, 20s, 0.8, 100%);
				background-image: linear-gradient(90deg, rgb(0, 10, 122) 30%, rgb(138, 158, 174) 95%);
				transform: none;
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.contact-page {
		.title-content {
			.contact-title {
				font-size: 89px;
			}

			.left-line {
				.horizontal-line {
					width: 197px;
				}
			}
		}

		.contact-content {
			.contact-form {
				.address {
					width: 70%;
					padding-bottom: 19px;
					margin-bottom: 12px;
				}
			}

			.form-detail {
				margin-left: -50px;
			}

			.animate-dot {
				&.line-below-form {
					margin-right: 140px;
					padding-bottom: 40px;

					div {
						@include line-common(75px, 8px, 56px);
					}
				}
			}

			//Contact Page
			.contact-cirle {
				@include animate-circle(490px, 490px, -9.5%, 6%, 20s, 0.8, 100%);
			}
		}

		.contact-cirle-lelt {
			@include animate-circle(342px, 342px, 37%, 84%, 18s, 0.2, 300%);
		}

		.contact-cirle-right {
			@include animate-circle(108px, 108px, 77%, 9%, 20s, 0.3, 200%);
		}
	}
}

@media screen and (max-width: 1280px) {
	.contact-page {

		.contact-content {
			.contact-form {

				.contact-detail {
					margin-right: 0;
				}

				.address {
					width: 80%;
				}
			}

			.form-detail {
				margin-left: 0;
			}

			//Contact Page
			.contact-cirle {
				@include animate-circle(480px, 480px, -9.5%, 2.5%, 20s, 0.8, 100%);
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.contact-page {

		.title-content {
			.contact-title {
				font-size: 74px;
			}

			.left-line {
				.horizontal-line {
					width: 150px;
				}
			}
		}

		.contact-content {
			margin-left: 150px;

			//Contact Page
			.contact-cirle {
				@include animate-circle(430px, 430px, -8.5%, 1.4%, 20s, 0.8, 100%);
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.contact-page {
		.title-content {
			.contact-title {
				font-size: 80px;
			}

			.left-line {
				.horizontal-line {
					width: 90px;
				}
			}
		}

		.contact-content {
			margin-left: 100px;

			.contact-form {
				.address {
					width: 45%;
				}

				.callback {
					margin: 48px 110px 0 90px;

					.callback-title {
						padding-right: 75px;
					}

					.form-input,
					.form-input-error {

						.input-text {
							width: 75%;
							height: 90%;
							display: block;

							&.input-error {
								height: 80%;
							}
						}
					}
				}

				.btn-holder {
					.btn-send {
						margin-right: 65px;
					}
				}
			}

			.animate-dot {
				&.line-below-address {
					display: none;
				}

				&.line-below-form {
					margin-top: 50px;

					div {
						@include line-common(40px, 5px, 40px)
					}
				}
			}

			//Contact Page
			.contact-cirle {
				@include animate-circle(400px, 400px, 26%, 22%, 20s, 0.8, 100%);
			}
		}

		.contact-cirle-lelt {
			@include animate-circle(226px, 226px, -6%, -5%, 18s, 0.1, 300%);
		}

		.contact-cirle-right {
			@include animate-circle(75px, 75px, 13%, 93%, 20s, 0.3, 200%);
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.contact-page {
		.title-content {
			.contact-title {
				font-size: 44px;
			}

			.left-line {
				.horizontal-line {
					width: 40px;
				}
			}
		}

		.contact-content {
			margin-left: 50px;

			.contact-form {
				.address {
					width: 60%;
				}

				.callback {
					margin: 48px 72px 0 56px;

					.title {
						&.title-custom {
							width: 22%;
						}
					}

					.custom {
						width: 75%;
					}

					.callback-title {
						padding-right: 35px;
					}

					.form-input,
					.form-input-error {
						.input-text {
							width: 100%;
						}
					}
				}

				.btn-holder {
					padding-left: 35px;

					.btn-send {
						margin-right: 0;
					}
				}
			}

			.animate-dot {
				&.line-below-address {
					top: 24%;

					div {
						@include line-common(10px, 1px, 8px)
					}
				}

				&.line-below-form {
					margin-top: 35px;
					margin-right: 10px;

					div {
						@include line-common(20px, 5px, 25px)
					}
				}
			}

			//Contact Page
			.contact-cirle {
				@include animate-circle(270px, 270px, 25%, 17%, 20s, 0.8, 100%);
			}
		}

		.contact-cirle-lelt {
			@include animate-circle(111px, 111px, -6%, -5%, 18s, 0.1, 300%);
		}

		.contact-cirle-right {
			@include animate-circle(48px, 48px, 13%, 93%, 20s, 0.3, 200%);
		}
	}

	.osx-content {
		.contact-page {
			.title-content {
				.left-line {
					.line-dot {
						margin-left: -5px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.contact-page {
		.contact-content {
			.contact-form {
				.address {
					width: 65%;
				}

				.callback {
					.callback-title {
						padding-right: 35px;
					}

					.title {
						&.title-custom {
							width: 25%;
						}
					}
				}

				.btn-holder {
					margin-top: 10px;
				}
			}

			//Contact Page
			.contact-cirle {
				@include animate-circle(260px, 260px, 25%, 13%, 20s, 0.8, 100%);
			}
		}

		.contact-cirle-lelt {
			@include animate-circle(111px, 111px, -6%, -5%, 18s, 0.1, 300%);
		}

		.contact-cirle-right {
			@include animate-circle(48px, 48px, 13%, 93%, 20s, 0.3, 200%);
		}
	}
}

@media screen and (max-width: 320px) {
	.contact-page {
		.title-content {
			.contact-title {
				font-size: 36px;
			}
		}

		.contact-content {
			margin-left: 30px;

			.contact-form {
				.address {
					width: 75%;
				}

				.callback {
					margin: 48px 44px 0 45px;

					.callback-title {
						padding-right: 35px;
					}

					.title {
						&.title-custom {
							width: 24%;
						}
					}
				}

				.btn-holder {
					.btn-send {
						margin-right: 0;
					}
				}
			}

			.animate-dot {
				&.line-below-address {
					left: 4%;
				}

				&.line-below-form {
					margin-top: 35px;
					margin-right: 10px;

					div {
						@include line-common(18px, 4px, 22px)
					}
				}
			}

			//Contact Page
			.contact-cirle {
				@include animate-circle(250px, 250px, 27%, 7.5%, 20s, 0.8, 100%);
			}
		}

		.contact-cirle-lelt {
			@include animate-circle(111px, 111px, -6%, -5%, 18s, 0.1, 300%);
		}

		.contact-cirle-right {
			@include animate-circle(48px, 48px, 16%, 85%, 20s, 0.3, 200%);
		}
	}
}