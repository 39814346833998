.switch-management {
	margin-left: 108px;
	margin-right: 135px;
	display: flex;
	justify-content: center;
	position: relative;

	.switch-line {
		height: 80px;
		width: 100%;
		border: 2.5px solid $blue-1;
		border-radius: 50px;
		border-bottom-style: none;
	}

	.list-content {
		display: flex;
		padding: 0;
		color: white;
		font-size: 21px;
		font-family: 'Montserrat Regular';
		position: absolute;
		width: 98.4%;
		justify-content: center;
		margin: 0;
		z-index: 1;

		&::after,
		&::before {
			content: '';
			display: inline-block;
			border-bottom: 2.5px solid $blue-1;
			margin-bottom: 0.5em;
			width: 100%;
		}

		&::before {
			border-bottom-left-radius: 50px;
		}

		&::after {
			border-bottom-right-radius: 50px;
		}

		&.bottom-content {
			bottom: -10px;
		}

		li {
			display: flex;
			align-items: center;
			justify-items: center;
			cursor: pointer;

			&:nth-child(2) {

				&::after,
				&::before {
					content: '';
					display: inline-block;
					border-bottom: 2px solid $blue-1;
					width: 200px;
					margin-top: 0.2em;
				}
			}

			.circle {
				width: 26px;
				height: 26px;
				border-radius: 50%;
				border: 2px solid $blue-1;
				margin-right: 20px;
				position: relative;

				&.active {
					&:after {
						content: '';
						position: absolute;
						width: 16px;
						height: 16px;
						top: 3px;
						left: 3px;
						border-radius: 50%;
						background: $yellow-main;
					}
				}
			}

			.text {
				margin-right: 20px;

				&.active {
					color: $yellow-main;
				}
			}
		}
	}
}

@media screen and (max-width: 1730px) and (min-width: 1640px) {
	.switch-management {

		.list-content {
			font-size: 18px;

			&::after,
			&::before {
				margin-bottom: 0.55em;
			}

			li {
				&:nth-child(2) {

					&::before,
					&::after {
						width: 160px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.switch-management {
		margin: 0 90px;

		.switch-line {
			height: 70px;
		}

		.list-content {
			font-size: 16px;

			&::after,
			&::before {
				margin-bottom: 0.6em;
			}

			li {
				&:nth-child(2) {

					&::before,
					&::after {
						width: 120px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.switch-management {
		margin-left: 75px;
		margin-right: 120px;

		.switch-line {
			height: 55px;
		}

		.list-content {
			font-size: 15px;

			&::after,
			&::before {
				margin-bottom: 0.65em;
			}

			li {
				.text {
					margin-right: 14px;
				}

				&:nth-child(2) {

					&::before,
					&::after {
						width: 120px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.switch-management {
		margin-left: 40px;
		margin-right: 80px;

		.list-content {
			font-size: 11px;

			&::after,
			&::before {
				margin-bottom: 0.95em;
			}

			li {
				.text {
					margin-right: 5px;
				}

				.circle {
					margin-right: 7px;
					width: 22px;
					height: 22px;

					&.active {
						&:after {
							width: 13px;
							height: 13px;
						}
					}
				}

				&:nth-child(2) {

					&::before,
					&::after {
						margin-top: -0.1em;
						border-bottom: 3px solid #01b5f6;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.switch-management {
		margin-left: 30px;
		margin-right: 30px;

		.switch-line {
			height: 80px;
		}

		.list-content {
			font-size: 14px;

			&::before {
				border-bottom-left-radius: 80%;
			}

			&::after {
				border-bottom-right-radius: 80%;
			}

			&::before,
			&::after {
				margin-bottom: 0.5em;
				width: 59px;
			}

			&.top-content {
				top: -16px;
			}

			&.bottom-content {
				bottom: -11px;
			}

			li {
				&:nth-child(2) {

					&::after,
					&::before {
						margin-top: -0.5px;
						border-bottom: 3px solid #01b5f6;
					}
				}

				.circle {
					width: 12px;
					height: 12px;
					margin: 0;
					padding: 4px;

					&.active {
						&::after {
							width: 8px;
							height: 8px;
							top: 0;
							left: 0;
						}
					}
				}

				.text {
					margin: 0 5px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {

	/* Inherit from 800 */
	.switch-management {
		.list-content {
			&.bottom-content {
				bottom: -7px;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.switch-management {

		.switch-line {
			height: 45px;
			border: 1px solid #01b5f6;
			border-bottom-style: none;
		}

		.list-content {
			font-size: 8px;

			&::before,
			&::after {
				width: 15px;
				border-bottom: 1px solid #01b5f6;
			}

			&.bottom-content {
				bottom: -2px;
			}

			li {
				&:nth-child(2) {

					&::after,
					&::before {
						margin-top: 0px;
						width: 60px;
						border-bottom: 1px solid #01b5f6;
					}
				}

				.circle {
					width: 7px;
					height: 7px;
					padding: 1px;
					border: 1px solid #01b5f6;

					&.active {
						&::after {
							width: 5px;
							height: 5px;
						}
					}
				}

				.text {
					margin: 0 3px;
				}
			}
		}
	}
}

@media screen and (max-width: 412px) and (min-width: 376px) {
	.switch-management {

		.list-content {

			&::before,
			&::after {
				width: 9px;
			}

			&.bottom-content {
				bottom: -3px;
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.switch-management {

		.switch-line {
			height: 45px;
		}

		.list-content {
			font-size: 7px;

			&::before,
			&::after {
				width: 9px;
			}

			li {

				.circle {
					width: 5px;
					height: 5px;
					padding: 1px;

					&.active {
						&::after {
							width: 3px;
							height: 3px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 360px) and (min-width: 321px) {
	.switch-management {

		.list-content {

			&::before,
			&::after {
				width: 0px;
			}

			&.bottom-content {
				bottom: -2.5px;
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.switch-management {

		.list-content {

			li {
				&:nth-child(2) {

					&::after,
					&::before {
						width: 32px;
					}
				}
			}
		}
	}
}