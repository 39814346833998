.introduce {
	margin-left: 225px;

	.introduce__main {
		@include z-index(relative, 2);
		margin-right: 135px;
		height: 680px;
	}

	.work-links {
		z-index: 2;

		.circle-text {

			span {
				color: white;
				text-shadow: 3px 2px 20px black;
				font-size: 25px;
				cursor: pointer;
				transition: ease-in-out 0.5s;

				&.active {
					color: $yellow-main;
					font-size: 34px;
					font-family: 'Montserrat ExtraBold';
				}
			}

			.spin-circle {
				width: 28px;
				height: 28px;
				display: inline-block;
				margin-right: 6px;

				.outer-ring {
					position: absolute;
					height: 28px;
					width: 28px;
					background-image: linear-gradient(135deg,
							#feed07 0%,
							#fe6a50 5%,
							#ed00aa 15%,
							#2fe3fe 50%,
							#8900ff 100%);
					border-radius: 50%;
					animation: rotate 1.5s infinite;
				}

				.inner-ring {
					position: absolute;
					height: 22px;
					width: 22px;
					background: black;
					border-radius: 50%;
					margin-left: 3px;
					margin-top: 3px;
				}
			}
		}
	}

	.video-wrapper {
		position: absolute;
		right: 0;
		flex: 0 0 89%;
		max-width: 89%;
		min-width: 89%;

		.introduce-image {
			height: 660px;
			z-index: 0;
		}
	}

	.vertical-line {
		border-left: 0.62px solid grey;
		height: 27.5px;
		margin: 14px 5px 9px 5px;
	}

	.animate-text {
		margin-top: -80px;
		text-align: center;
		@include z-index(relative, 1);


		.animate-dot {
			background: linear-gradient(45deg,
					rgba(129, 83, 208, 1) 0%,
					rgba(59, 232, 233, 1) 25%,
					rgba(129, 83, 208, 1) 50%,
					rgba(59, 232, 233, 1) 75%,
					rgba(129, 83, 208, 1) 100%);
			@include animate-dot(8s, 300%, 1);
			padding: 10px;

			&.line-1 {
				position: relative;
				left: 9%;
				margin-right: 30%;
				margin-top: -55px;
				padding-bottom: 50px;
			}

			&.line-2 {
				position: absolute;
				right: 8%;
				top: 7%;
				background: linear-gradient(45deg,
						$violet-1 0%,
						#0e84a6 25%,
						$violet-1 50%,
						#0e84a6 75%,
						$violet-1 100%);
				background-size: 400% auto;
				animation: shine 6s linear infinite;
				color: #000;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}

	.introduce-cirle {
		@include animate-circle(385px, 385px, 5.8%, 78%, 15s, 0.3, 300%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}
}

@media screen and (max-width: 1640px) {
	.introduce {

		.introduce__main {
			height: 535px;
		}

		.video-wrapper {
			flex: 0 0 91%;
			max-width: 91%;
			min-width: 91%;

			.introduce-image {
				height: 540px;
			}
		}

		.vertical-line {
			height: 18.5px;
		}

		.animate-text {
			margin-top: -50px;

			.animate-dot {

				&.line-1 {
					left: 3%;
					margin-right: 35%;
					margin-top: -55px;
					padding-bottom: 50px;

					div {
						@include line-common (140px, 4px, 70px);
					}
				}

				&.line-2 {
					right: 8%;
					top: 10%;

					div {
						@include line-common (33px, 2px, 16px);
					}
				}
			}
		}


		.work-links {
			.circle-text {
				span {
					font-size: 20px;

					&.active {
						font-size: 26px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.introduce {
		margin-left: 190px;

		.introduce__main {
			height: 500px;
			margin-right: 95px;
		}

		.video-wrapper {

			.introduce-image {
				height: 485px;
			}
		}

		.vertical-line {
			height: 15.5px;
		}

		.animate-text {
			margin-top: -50px;

			.animate-dot {

				&.line-1 {
					margin-right: 49%;
				}

				&.line-2 {
					right: 6%;
					top: 8.5%;
				}
			}
		}

		.work-links {
			.circle-text {
				span {
					font-size: 18px;

					&.active {
						font-size: 24px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.introduce {
		margin-left: 175px;

		.introduce__main {
			height: 415px;
		}

		.video-wrapper {

			.introduce-image {
				height: 440px;
			}
		}

		.vertical-line {
			height: 14.5px;
			margin: 10px 5px 9px 5px;
		}

		.work-links {
			.circle-text {
				span {
					font-size: 18px;

					&.active {
						font-size: 24px;
					}
				}

				.spin-circle {
					width: 20px;
					height: 20px;

					.outer-ring {
						height: 20px;
						width: 20px;
					}

					.inner-ring {
						height: 14px;
						width: 14px;
					}
				}
			}
		}

		.animate-text {
			margin-top: -15px;

			.animate-dot {
				&.line-1 {
					margin-right: 44%;
					padding-bottom: 20px;
					margin-top: -15px;

					div {
						@include line-common (100px, 10px, 62px);
					}
				}
			}
		}

		.introduce-cirle {
			@include animate-circle(280px, 280px, 5.7%, 78%, 15s, 0.6, 300%);
		}

	}
}

@media screen and (max-width: 1280px) {
	.introduce {
		margin-left: 175px;

		.introduce__main {
			height: 390px;
		}

		.video-wrapper {
			max-width: 88%;
			min-width: 88%;

			.introduce-image {
				height: 380px;
			}
		}

		.vertical-line {
			height: 10.5px;
		}

		.work-links {
			.circle-text {
				span {
					font-size: 16px;

					&.active {
						font-size: 20px;
					}
				}
			}
		}

		.animate-text {

			.animate-dot {
				&.line-2 {
					top: 9.5%;
				}

				&.line-1 {
					left: 9%;
					margin-right: 42%;
					padding-bottom: 10px;

					div {
						@include line-common (80px, 8px, 52px);
					}
				}
			}
		}

		.introduce-cirle {
			@include animate-circle(280px, 280px, 5.7%, 78%, 15s, 0.6, 300%);
		}

	}
}

@media screen and (max-width: 1024px) {
	.introduce {
		margin-left: 100px;

		.introduce__main {
			margin-right: 80px;
		}

		.video-wrapper {
			max-width: 90%;
			min-width: 90%;

			.introduce-image {
				height: 360px;
			}
		}

		.vertical-line {
			height: 8.5px;
		}

		.work-links {
			.circle-text {
				span {
					font-size: 14px;

					&.active {
						font-size: 18px;
					}
				}
			}
		}

		.animate-text {
			margin-top: -50px;

			.animate-dot {
				&.line-2 {
					top: 10.5%;
				}

				&.line-1 {
					margin-right: 42%;
					padding-bottom: 10px;

					div {
						@include line-common (68px, 6px, 45px);
					}
				}
			}
		}

		.introduce-cirle {
			@include animate-circle(280px, 280px, 5.7%, 78%, 15s, 0.6, 300%);
		}

	}
}

@media screen and (max-width: 800px) {
	.introduce {
		margin-left: 30px;

		.introduce__main {
			height: 100%;
			margin-right: 15px;
			flex-wrap: wrap-reverse;
		}

		.work-links {
			height: 375px;
		}

		.video-wrapper {
			display: contents;
			max-width: 100%;
			min-width: 100%;

			.introduce-image {
				height: 410px;
				width: 100%;
			}
		}

		.vertical-line {
			height: 12px;
			margin: 8px 5px 8px 5px;
		}

		.work-links {

			.circle-text {
				span {
					font-size: 15px;

					&.active {
						font-size: 19px;
					}
				}

				.spin-circle {
					width: 20px;
					height: 20px;

					.outer-ring {
						height: 20px;
						width: 20px;
					}

					.inner-ring {
						height: 14px;
						width: 14px;
					}
				}
			}
		}

		.animate-text {
			margin-top: 50px;

			.animate-dot {
				&.line-1 {
					margin-right: 35%;
					margin-top: 35px;

					div {
						@include line-common (40px, 7px, 35px);
					}
				}

				&.line-2 {
					div {
						@include line-common (18px, -1px, 8px);
					}
				}
			}
		}

		.introduce-cirle {
			@include animate-circle(233px, 233px, 40%, 21%, 15s, 0.6, 300%);
		}

	}
}

@media screen and (max-width: 768px) {
	//Inherit from tablet 800
}

@media screen and (max-width: 576px) {
	.introduce {
		.video-wrapper {
			.introduce-image {
				height: 230px;
				margin-top: -30px;
			}
		}

		.work-links {

			.circle-text {
				span {
					font-size: 10px;

					&.active {
						font-size: 13px;
					}
				}

				.spin-circle {
					width: 14px;
					height: 14px;

					.outer-ring {
						height: 13px;
						width: 13px;
					}

					.inner-ring {
						height: 7px;
						width: 7px;
					}
				}
			}
		}

		.animate-text {
			margin-top: -50px;

			.animate-dot {
				&.line-1 {
					margin-top: -5px;

					div {
						@include line-common (27px, 3px, 32px);
					}
				}

				&.line-2 {
					top: -52%;
					right: 15%;

					div {
						@include line-common (10px, 2px, 8px);
					}
				}
			}
		}

		.introduce-cirle {
			@include animate-circle(130px, 130px, 37%, 21%, 15s, 0.6, 300%);
		}

	}
}

@media screen and (max-width: 375px) {}

@media screen and (max-width: 320px) {}