.clients-section {
	position: relative;
	overflow: hidden;
	z-index: 1;

	.client-content {
		display: flex;
		margin-bottom: 150px;
		position: relative;
		z-index: 2;

		.content {
			width: 78%;
			margin-left: 370px;

			.wrapper {
				display: flex;
				flex-wrap: wrap;

				.img-wrapper {
					flex: 0 0 10%;
					margin-right: 54px;
					margin-bottom: 54px;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			.arrows {
				display: flex;
				justify-content: space-between;
				margin-right: 8%;
				margin-top: -15px;
				position: relative;
				z-index: 1;

				img {
					width: 100%;
					height: 100%;
				}

				.icon {
					cursor: pointer;

					&.visible {
						visibility: visible;
					}

					&.hidden {
						visibility: hidden;
					}

					&.icon-left {
						transform: rotate(180deg);
					}

					width: 67px;
					height: 34px;
				}

			}
		}
	}

	.animate-dot {
		position: absolute;
		background: linear-gradient(45deg,
				#bb1ae5 0%,
				#15c8fc 25%,
				#bb1ae5 50%,
				#15c8fc 75%,
				#bb1ae5 100%);
		@include animate-dot(6s, 400%, 1);

		&.animate-dot-1 {
			bottom: 8.5%;
			left: 2%;
		}

		&.animate-dot-2 {
			bottom: 7%;
			left: 52%;
			padding-bottom: 5px;

			div {
				@include line-common (45px, 2px, 25px);
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.clients-section {

		.client-content {
			margin-bottom: 120px;

			.content {
				width: 72%;
				margin-left: 320px;

				.wrapper {

					.img-wrapper {
						margin-right: 44px;
						margin-bottom: 44px;
					}
				}

				.arrows {
					margin-right: 7%;
					margin-top: -15px;

					.icon {
						width: 62px;
						height: 29px;
					}

				}
			}
		}

		.animate-dot {

			&.animate-dot-1 {
				bottom: 8.5%;
				left: 2%;
			}

			&.animate-dot-2 {
				bottom: 5%;
				left: 53.5%;

				div {
					@include line-common (45px, 2px, 25px);
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.clients-section {

		.client-content {
			margin-bottom: 140px;

			.content {
				width: 73%;

				.wrapper {

					.img-wrapper {
						margin-right: 42px;
						margin-bottom: 42px;
					}
				}

				.arrows {
					margin-right: 7%;
					margin-top: -15px;

					.icon {
						width: 62px;
						height: 29px;
					}

				}
			}
		}

		.animate-dot {

			&.animate-dot-1 {
				bottom: 5.5%;
				left: 2%;

				div {
					@include line-common (53px, 2px, 35px);
				}
			}

			&.animate-dot-2 {
				bottom: 8%;
				left: 53.5%;
				z-index: 0;

				div {
					@include line-common (45px, 2px, 25px);
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.clients-section {
		.client-content {
			.content {
				margin-left: 281px;

				.arrows {
					.icon {
						width: 55px;
						height: 25px;
					}
				}

				.wrapper {
					.img-wrapper {
						margin-right: 36px;
						margin-bottom: 42px;
					}
				}
			}
		}

		.animate-dot {
			&.animate-dot-1 {
				bottom: 15.5%;

				div {
					@include line-common (45px, 4px, 30px);
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.clients-section {
		padding: 100px 0;

		.client-content {
			.content {
				margin-left: 235px;

				.arrows {
					margin-right: 9%;
				}

				.wrapper {
					.img-wrapper {
						margin-right: 26px;
						margin-bottom: 27px;
					}
				}
			}
		}

		.animate-dot {
			&.animate-dot-1 {
				bottom: 12.5%;

				div {
					@include line-common (32px, 3px, 24px);
				}
			}
		}

		.client-cirle-mobile {
			@include animate-circle(619px, 619px, 7%, 72%, 16s, 0.6, 250%);
			background-image: linear-gradient(90deg, rgb(103, 0, 107) 0%, rgb(112, 52, 181) 50%, rgb(103, 0, 107) 100%);
		}
	}
}

@media screen and (max-width: 800px) {
	.clients-section {
		padding: 0;

		.client-content {
			.content {
				width: 85%;
				margin-left: 150px;

				.arrows {
					margin-right: 22%;
					margin-top: -5px;
				}

				.wrapper {
					.img-wrapper {
						flex: 0 0 16%;
						margin-right: 30px;
						margin-bottom: 30px;
					}
				}
			}
		}

		.animate-dot {
			&.animate-dot-1 {
				top: 50%;
				z-index: 1;

				div {
					@include line-common (25px, 1px, 20px);
				}
			}

			&.animate-dot-2 {
				bottom: 8%;
				left: 45%;

				div {
					@include line-common (30px, 1px, 18px);
				}
			}
		}

		.client-cirle-mobile {
			@include animate-circle(619px, 619px, 20%, 60%, 16s, 0.6, 250%);
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.clients-section {
		.client-content {
			.content {
				width: 90%;
				margin-left: 50px;

				.arrows {
					margin-right: 15%;
					margin-top: 7px;

					.icon {
						width: 35px;
						height: 15px;
					}
				}

				.wrapper {
					.img-wrapper {
						flex: 0 0 16%;
						margin-right: 25px;
						margin-bottom: 25px;
					}
				}
			}
		}

		.animate-dot {
			&.animate-dot-1 {
				left: 5%;
				bottom: 40%;

				div {
					@include line-common (10px, 1px, 8px);
				}
			}

			&.animate-dot-2 {
				left: 44%;
				bottom: 18%;
				z-index: 0;

				div {
					@include line-common (20px, 1px, 20px);
				}
			}
		}

		.client-cirle-mobile {
			@include animate-circle(448px, 448px, 14%, 60%, 16s, 0.6, 250%);
		}
	}
}

@media screen and (max-width: 375px) {
	.clients-section {
		.client-content {
			.content {
				margin-left: 40px;
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.clients-section {
		.client-content {
			.content {
				margin-left: 34px;

				.arrows {
					margin-top: 10px;
					margin-right: 10%;
				}
			}
		}
	}
}