.osx-content {
	.service-content {
		.service-description {
			.content-right {

				.line-content {

					.horizontal-line {
						top: -2px !important;
					}
				}
			}
		}
	}
}

.service-content {
	position: relative;

	.connect-section {
		margin-top: 100px;
		margin-bottom: 100px;
		position: relative;
		z-index: 1;

		.animate-line {
			position: relative;
			padding-bottom: 20px;

			.animate-dot {
				background: linear-gradient(45deg,
						#fb08a6 0%,
						rgba(59, 232, 233, 1) 10%,
						#fb08a6 20%,
						rgba(59, 232, 233, 1) 30%,
						#fb08a6 40%,
						rgba(59, 232, 233, 1) 50%,
						#fb08a6 60%,
						rgba(59, 232, 233, 1) 70%,
						#fb08a6 80%,
						rgba(59, 232, 233, 1) 90%,
						#fb08a6 100%);
				@include animate-dot(8s, 250%, 1);

				div {
					font-size: 50px;
					line-height: 0.65;
					letter-spacing: 6px;
				}
			}
		}
	}

	.service-description {
		margin-left: 235px;
		margin-right: 135px;
		padding-bottom: 65px;
		position: relative;
		z-index: 2;

		.service-content {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.content-left {
			padding: 0;
			font-family: 'Montserrat Light';
			font-size: 23px;
			color: white;
			text-align: justify;
			word-spacing: -1px;
			line-height: 43px;
			z-index: 2;
			flex: 0 0 36%;
			max-width: 36%;
		}

		.content-right {
			color: white;
			flex: 0 0 58.333333%;
			max-width: 58.333333%;

			.line-content {
				position: relative;
				z-index: 1;

				.line-dot {
					font-size: 50px;
					margin-right: -4px;
					color: $yellow-main;
				}

				.horizontal-line {
					margin-top: 20px;
					margin-bottom: 5px;
					width: 100%;
					height: 1px;
					background: linear-gradient(90deg,
							$yellow-main 0%,
							$yellow-main 50%,
							$yellow-main 100%);
					background-size: 300% auto;
					animation: shine 3s linear infinite;
					top: -2px !important;
				}
			}

			.features {
				margin-top: 20px;
				align-items: baseline;

				.sub-title {
					font-family: 'Montserrat Bold';
					font-size: 34px;
					padding-left: 20px;
					z-index: 1;
				}

				.feature-list {
					font-family: 'Montserrat Light';
					font-size: 24px;

					ul {
						list-style: unset;

						li {
							padding: 3px 0;
							white-space: pre-wrap;
						}
					}
				}
			}

		}

		.service-desc__circle {
			@include animate-circle(635px, 635px, 50%, 85%, 15s, 0.4, 350%);
			background-image: linear-gradient(90deg, #340414 0%, #c23523 50%, #340414 100%);
		}
	}
}

@media screen and (max-width: 1640px) {
	.service-content {

		.service-description {
			padding-bottom: 65px;

			.content-left {
				font-size: 20px;
				flex: 0 0 40%;
				max-width: 40%;
			}

			.content-right {
				flex: 0 0 57%;
				max-width: 57%;

				.line-content {

					.horizontal-line {
						top: -2px !important;
					}
				}

				.features {
					margin-top: 20px;

					.sub-title {
						font-size: 28px;
					}

					.feature-list {
						font-size: 22px;
					}
				}

			}

			.service-desc__circle {
				@include animate-circle(635px, 635px, 50%, 85%, 15s, 0.4, 350%);
				background-image: linear-gradient(90deg, #340414 0%, #c23523 50%, #340414 100%);
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.service-content {

		.connect-section {
			margin-bottom: 0;
		}

		.service-description {
			margin-left: 190px;
			padding-bottom: 65px;

			.content-left {
				font-size: 16px;
				flex: 0 0 40%;
				max-width: 40%;
			}

			.content-right {
				flex: 0 0 57%;
				max-width: 57%;

				.line-content {

					.horizontal-line {
						top: -2px !important;
					}
				}

				.features {

					.sub-title {
						font-size: 25px;
					}

					.feature-list {
						font-size: 19px;
					}
				}

			}

			.service-desc__circle {
				@include animate-circle(455px, 455px, 70%, 85%, 15s, 0.4, 350%);
				background-image: linear-gradient(90deg, rgb(143, 8, 31) 0%, rgb(235, 8, 189) 50%, rgb(143, 8, 31) 100%);
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.service-content {
		.connect-section {
			.animate-line {
				padding-top: 20px;
				padding-bottom: 20px;

				.animate-dot {

					div {
						font-size: 50px;
						line-height: 0.65;
						letter-spacing: 6px;
					}
				}
			}
		}

		.service-description {
			margin: 0 70px 0 175px;

			.content-left {
				line-height: 40px;
			}

			.content-right {
				.features {
					.sub-title {
						font-size: 24px;
					}

					.feature-list {
						font-size: 18px;
					}
				}
			}

			.service-desc__circle {
				@include animate-circle(455px, 455px, 70%, 85%, 15s, 0.4, 350%);
				background-image: linear-gradient(90deg, rgb(143, 8, 31) 0%, rgb(235, 8, 189) 50%, rgb(143, 8, 31) 100%);
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.service-content {
		.service-description {

			.content-left {
				flex: 0 0 37%;
				max-width: 37%;
				font-size: 17px;
			}

			.content-right {
				flex: 0 0 60%;
				max-width: 60%;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.service-content {
		.connect-section {
			.animate-line {
				.animate-dot {

					div {
						font-size: 40px;
						line-height: 0.7;
						letter-spacing: 6px;
					}
				}
			}
		}

		.service-description {
			margin: 0 70px 0 130px;

			.content-left {
				flex: 0 0 46%;
				max-width: 46%;
				font-size: 16px;
				line-height: 30px;
			}

			.content-right {
				flex: 0 0 51%;
				max-width: 51%;

				.features {
					.sub-title {
						font-size: 20px;
					}

					.feature-list {
						font-size: 15px;
					}
				}
			}

			.service-desc__circle {
				@include animate-circle(455px, 455px, 70%, 85%, 15s, 0.4, 350%);
				background-image: linear-gradient(90deg, rgb(143, 8, 31) 0%, rgb(235, 8, 189) 50%, rgb(143, 8, 31) 100%);
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.service-content {
		.service-description {
			margin-left: 100px;

			.service-content {
				display: unset;
			}

			.content-left,
			.content-right {
				max-width: 100%;
			}

			.content-right {
				.features {
					.sub-title {
						font-size: 18px;
					}
				}
			}
		}

		.connect-section {
			.animate-line {
				.animate-dot {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.service-content {
		.service-description {
			margin: 0 44px 0 50px;

			.content-right {
				.features {
					.sub-title {
						width: 35%;
					}

					.feature-list {
						width: 65%;
					}
				}
			}

			.content-left {
				font-size: 14px;
			}

			.service-desc__circle {
				@include animate-circle(162px, 162px, 94%, 45%, 15s, 0.4, 350%);
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.service-content {
		.service-description {
			margin: 0 31px 0 30px;
		}
	}
}

@media screen and (max-width: 320px) {
	.service-content {
		.service-description {
			margin: 0 35px 0 50px;
		}
	}
}