@keyframes quote-connect {
	0% {
		fill: #f00dd0
	}

	12.5% {
		fill: #4b9af6
	}

	25% {
		fill: #f00dd0
	}

	37.5% {
		fill: #4b9af6
	}

	50% {
		fill: #f00dd0
	}

	62.5% {
		fill: #4b9af6
	}

	75% {
		fill: #f00dd0
	}

	87.5% {
		fill: #4b9af6
	}

	100% {
		fill: #f00dd0
	}
}

@keyframes quote-resever-connect {
	0% {
		fill: #b502ef
	}

	20% {
		fill: #2486eb
	}

	50% {
		fill: #b502ef
	}

	75% {
		fill: #2486eb
	}

	100% {
		fill: #b502ef
	}
}

.osx-content {
	.connect-section {
		.connect-text {
			display: -webkit-box;
			-webkit-box-align: center;

			.line-content {
				display: -webkit-box;

				.line-dot {
					margin-left: -9px;
				}
			}
		}

		.text-content {
			.description {
				display: -webkit-box;
			}
		}
	}
}

.connect-section {
	margin-top: 20px;
	margin-left: 225px;
	margin-bottom: 230px;

	.logo-content {
		display: flex;
		align-items: center;
		@include z-index(relative, 1);
		padding-bottom: 5px;

		.quote {
			position: absolute;
			left: -75px;
			top: -15px;

			svg {
				height: 66px;
				margin-right: 7px;
				animation: quote-connect 15s linear infinite;
			}
		}

		.logo {
			width: 755px;
			height: 95px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.logo-text {
			line-height: 60px;

			.creates {
				font-size: 50px;
				color: #0382fd;
				background: linear-gradient(45deg,
						#0382fd 0%, #0382fd 100%);
				background-size: 300% auto;
				animation: shine 6s linear infinite;
				color: #000;
				background-clip: text;
				-webkit-text-fill-color: transparent;
				font-weight: 700;
				line-height: 40px;
			}

			.extraodinarily {
				font-size: 83px;
				background: linear-gradient(45deg,
						#147ce9 0%,
						#58adfe 12.5%, #147ce9 25%,
						#58adfe 37.5%, #147ce9 50%,
						#58adfe 62.5%, #147ce9 75%,
						#58adfe 87.5%, #147ce9 100%,
					);
				background-size: 300% auto;
				animation: shine 6s linear infinite;
				color: #000;
				background-clip: text;
				-webkit-text-fill-color: transparent;
				font-family: 'Montserrat Thin';
				line-height: 0.9;
				padding-bottom: 10px;
			}
		}

		.vertical-line {
			border-radius: 3px;
			width: 130px;
			margin: 5px -30px 0 -30px;
			height: 3.5px;
			transform: rotate(90deg);
			background: linear-gradient(90deg,
					$violet-1 0%,
					$blue-1 12.5%,
					$violet-1 25%,
					$blue-1 37.5%,
					$violet-1 50%,
					$blue-1 62.5%,
					$violet-1 75%,
					$blue-1 87.5%,
					$violet-1 100%);
			background-size: 2000% auto;
			animation: shine 10s linear infinite;
		}
	}

	.text-content {
		font-family: 'Montserrat ExtraBold';
		line-height: 70px;

		.create-marketing {
			font-size: 75px;
			color: white;
			line-height: 1.2;
			@include z-index(relative, 1);

			.marketing {
				letter-spacing: 1.5px;
				background: linear-gradient(45deg,
						#bb07fd 0%,
						#392a93 12.5%,
						#bb07fd 25%,
						#392a93 37.5%,
						#bb07fd 50%,
						#392a93 62.5%,
						#bb07fd 75%,
						#392a93 87.5%,
						#bb07fd 100%);
				background-size: 300% auto;
				animation: shine 8s linear infinite;
				color: #000;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.exp {
				letter-spacing: 10px;
				background: linear-gradient(45deg,
						#818cc7 0%,
						#75a8e5 12.5%,
						#6fb6fc 25%,
						#85c1fe 37.5%,
						#9ecdfd 50%,
						#85c1fe 62.5%,
						#6fb6fc 75%,
						#75a8e5 87.5%,
						#818cc7 100%,
					);
				background-size: 300% auto;
				animation: shine 6s linear infinite;
				color: #000;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.description {
			font-size: 50px;
			color: white;
			letter-spacing: 1.5px;
			position: relative;
			z-index: 1;
			background: linear-gradient(45deg,
					#83c1fe 0%,
					#bb07fd 20%,
					#83c1fe 40%,
					#bb07fd 60%,
					#83c1fe 80%,
					#bb07fd 90%,
					#83c1fe 100%);
			background-size: 300% auto;
			animation: shine 6s linear infinite;
			color: #000;
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.connect-text {
		display: flex;
		@include z-index(relative, 1);
		align-items: center;
		line-height: 210px;
		background: linear-gradient(45deg,
				#b502ef 0%,
				#1b5ec4 12.5%,
				#b502ef 25%,
				#1b5ec4 37.5%,
				#b502ef 50%,
				#1b5ec4 62.5%,
				#b502ef 75%,
				#1b5ec4 87.5%,
				#b502ef 100%,
			);
		background-size: 300% auto;
		animation: shine 8s linear infinite;
		color: #000;
		background-clip: text;
		-webkit-text-fill-color: transparent;

		.reserver-quote {
			margin-top: -80px;
			margin-left: -15px;

			svg {
				height: 25px;
				animation: quote-resever-connect 7s linear infinite;
			}
		}

		.line-content {
			margin-right: 20px;
			width: 800px;

			.line-dot {
				font-size: 80px;
				margin-left: -6px;
			}

			.horizontal-line {
				top: -2px;
				width: 100%;
				height: 2px;
				background: linear-gradient(90deg,
						rgba(129, 83, 208, 1) 0%,
						rgba(59, 232, 233, 1) 50%,
						rgba(129, 83, 208, 1) 100%);
				background-size: 400% auto;
				animation: shine 3s linear infinite;
			}
		}

		.text {
			font-family: 'Montserrat ExtraBold';
			font-size: 135px;
		}
	}

	.animate-line {
		position: relative;

		.animate-dot {
			background: linear-gradient(45deg,
					rgba(129, 83, 208, 1) 0%,
					rgba(59, 232, 233, 1) 10%,
					rgba(129, 83, 208, 1) 20%,
					rgba(59, 232, 233, 1) 30%,
					rgba(129, 83, 208, 1) 40%,
					rgba(59, 232, 233, 1) 50%,
					rgba(129, 83, 208, 1) 60%,
					rgba(59, 232, 233, 1) 70%,
					rgba(129, 83, 208, 1) 80%,
					rgba(59, 232, 233, 1) 90%,
					rgba(129, 83, 208, 1) 100%);
			@include animate-dot(8s, 250%, 1);
			margin-top: -90px;
		}
	}

	.connect-cirle {
		@include animate-circle(232px, 232px, 13.6%, 7.8%, 15s, 0.6, 350%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}

	.connect-cirle-bottom {
		@include animate-circle(405px, 405px, 15.5%, 36%, 20s, 0.2, 350%);
		background-image: linear-gradient(90deg, rgb(81, 0, 52) 0%, rgb(235, 8, 189) 50%, rgb(81, 0, 52) 100%);
	}
}

@media screen and (max-width: 1640px) {
	.connect-section {
		position: relative;
		margin-bottom: 200px;
		margin-left: 190px;

		.logo-content {

			.logo {
				width: 600px;
				height: 75px;
			}

			.logo-text {

				.creates {
					font-size: 45px;
				}

				.extraodinarily {
					margin-top: -10px;
					font-size: 67px;
					line-height: 1.2;
				}
			}

			.vertical-line {
				width: 110px;
				margin: 5px -20px 0 -20px;
			}
		}

		.text-content {
			line-height: 65px;

			.create-marketing {
				font-size: 60px;
				line-height: 1;

				.marketing {
					letter-spacing: 1.5px;
				}

				.exp {
					letter-spacing: 8.5px;
				}
			}

			.description {
				font-size: 41px;
				letter-spacing: 0.8px;
			}
		}

		.connect-text {
			line-height: 180px;

			.line-content {
				margin-right: 20px;
				width: 590px;
			}

			.text {
				font-size: 120px;
			}
		}

		.connect-cirle {
			@include animate-circle(220px, 220px, 6.9%, 8%, 15s, 0.6, 350%);
		}

		.connect-cirle-bottom {
			@include animate-circle(350px, 350px, 37%, 42%, 20s, 0.2, 350%);
		}
	}
}

@media screen and (max-width: 1440px) {
	.connect-section {
		position: relative;
		margin-bottom: 200px;
		margin-left: 190px;

		.logo-content {

			.logo {
				width: 560px;
				height: 70px;
			}

			.logo-text {

				.creates {
					font-size: 40px;
				}

				.extraodinarily {
					margin-top: -10px;
					font-size: 64px;
					line-height: 1.2;
				}
			}

			.vertical-line {
				width: 110px;
				margin: 5px -20px 0 -20px;
			}
		}

		.text-content {
			line-height: 65px;

			.create-marketing {
				font-size: 56px;
				line-height: 1;

				.marketing {
					letter-spacing: 1.5px;
				}

				.exp {
					letter-spacing: 8.5px;
				}
			}

			.description {
				font-size: 39px;
				letter-spacing: 0.5px;
			}
		}

		.connect-text {
			line-height: 180px;

			.line-content {
				margin-right: 20px;
				width: 590px;
			}

			.text {
				font-size: 105px;
			}
		}

		.connect-cirle {
			@include animate-circle(220px, 220px, 6.9%, 8%, 15s, 0.4, 350%);
		}

		.connect-cirle-bottom {
			@include animate-circle(350px, 350px, 37%, 42%, 20s, 0.2, 350%);
		}
	}
}

@media screen and (max-width: 1366px) {
	.connect-section {
		margin-top: 45px;
		margin-left: 170px;
		margin-bottom: 190px;

		.logo-content {
			padding-bottom: 0;

			.logo {
				width: 555px;
				height: 60px;
			}

			.logo-text {
				.creates {
					font-size: 38px;
				}

				.extraodinarily {
					font-size: 62px;
					line-height: 70px;
				}
			}
		}

		.text-content {
			.create-marketing {
				font-size: 55px;
			}

			.description {
				font-size: 37px;
				letter-spacing: 1.2px;
			}
		}

		.connect-text {
			line-height: 180px;

			.line-content {
				width: 598px;
			}

			.text {
				font-size: 95px;
			}
		}

		.connect-cirle {
			@include animate-circle(167px, 167px, -3.7%, 5.1%, 15s, 0.6, 350%);
		}

		.connect-cirle-bottom {
			@include animate-circle(290px, 290px, 33.2%, 38.5%, 18s, 0.3, 350%);
		}
	}
}

@media screen and (max-width: 1280px) {
	.connect-section {
		margin-bottom: 145px;

		.logo-content {
			padding-bottom: 0;

			.vertical-line {
				width: 95px;
				margin: 5px -20px 0 -20px;
			}

			.logo {
				width: 500px;
				height: 55px;
			}

			.logo-text {
				.creates {
					font-size: 35px;
				}

				.extraodinarily {
					font-size: 58px;
					line-height: 65px;
				}
			}
		}

		.text-content {
			.create-marketing {
				font-size: 49px;
				line-height: 0.8;
			}

			.description {
				font-size: 33px;
				letter-spacing: 1.4px;
			}
		}

		.connect-text {
			line-height: 140px;

			.reserver-quote {
				top: -60px;
			}

			.line-content {
				width: 540px;
			}

			.text {
				font-size: 90px;
			}
		}

		.animate-line {
			.animate-dot {
				margin-top: -70px;
			}
		}

		.connect-cirle {
			@include animate-circle(167px, 167px, -5.3%, 4.1%, 15s, 0.6, 350%);
		}

		.connect-cirle-bottom {
			@include animate-circle(290px, 290px, 29.2%, 39.5%, 18s, 0.3, 350%);
		}
	}
}

@media screen and (max-width: 1024px) {
	.connect-section {
		margin-bottom: 145px;

		.logo-content {
			padding-bottom: 0;

			.quote {
				svg {
					height: 44px;
					margin-right: 4px;
				}
			}

			.vertical-line {
				width: 95px;
				margin: 5px -30px 0 -20px;
			}

			.logo {
				width: 350px;
				height: 40px;
			}

			.logo-text {
				.creates {
					font-size: 30px;
				}

				.extraodinarily {
					font-size: 45px;
					line-height: 53px;
				}
			}
		}

		.text-content {
			.create-marketing {
				font-size: 36.5px;
				line-height: 1.3;

				.marketing {
					letter-spacing: 0.5px;
				}

				.exp {
					letter-spacing: 7px;
				}
			}

			.description {
				font-size: 27px;
				letter-spacing: -0.5px;
			}
		}

		.connect-text {
			line-height: 140px;

			.reserver-quote {
				top: -60px;
			}

			.line-content {
				width: 340px;
			}

			.text {
				font-size: 78px;
			}
		}

		.animate-line {
			.animate-dot {
				margin-top: -70px;
			}
		}

		.connect-cirle {
			@include animate-circle(167px, 167px, -5.3%, 5.1%, 15s, 0.6, 350%);
		}

		.connect-cirle-bottom {
			@include animate-circle(290px, 290px, 29.2%, 39.5%, 18s, 0.3, 350%);
		}
	}
}

@media screen and (max-width: 800px) {
	.connect-section {
		margin: 45px 100px 300px 135px;

		.logo-content {
			flex-wrap: wrap;

			.quote {
				left: -60px;

				svg {
					height: 38px;
					margin-right: 0px;
				}
			}

			.logo {
				width: 555px;
				height: 66px;
				margin-bottom: 10px;

				img {
					object-fit: cover;
				}
			}

			.vertical-line {
				margin: 0 -10px 0 -33px;
				height: 2px;
				width: 70px;
			}

			.logo-text {
				.creates {
					font-size: 29px;
				}

				.extraodinarily {
					font-size: 45px;
					line-height: 55px;
				}
			}
		}

		.text-content {
			.create-marketing {
				display: flex;
				flex-wrap: wrap;
				font-size: 51.5px;
				line-height: 1.1;

				.marketing {
					letter-spacing: 1.1px;
				}

				.exp {
					letter-spacing: 16px;
					font-size: 60px;
				}

				.space {
					display: none;
				}
			}

			.description {
				font-size: 32px;
				line-height: 35px;
				letter-spacing: 0.6px;
			}
		}

		.connect-text {
			margin-left: -100px;
			line-height: 90px;

			.line-content {
				margin-right: 0px;
				width: 275px;
				margin-bottom: 20px;

				.horizontal-line {
					width: 90%;
				}

				.line-dot {
					margin-left: -5px;
				}
			}

			.text {
				font-size: 84px;
			}
		}

		.animate-line {
			.animate-dot {
				position: absolute;
				right: 0;
				margin-top: 68px;

				div {
					@include line-common(10px, 2px, 9px);
				}
			}
		}

		.connect-cirle {
			@include animate-circle(93px, 93px, 103.3%, 16%, 15s, 0.6, 350%);
		}

		.connect-cirle-bottom {
			@include animate-circle(390px, 390px, 36%, 36.5%, 18s, 0.3, 350%);
		}
	}

	.osx-content {
		.connect-section {
			.connect-text {
				.line-content {
					width: 285px;

					.line-dot {
						margin-left: -6px;
					}
				}

				.reserver-quote {
					margin-left: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {

	/* Inherit from 800 */
	.connect-section {
		margin: 45px 100px 300px 100px;
	}
}

@media screen and (max-width: 576px) {
	.connect-section {
		margin: 111px 38px 300px 38px;

		.logo-content {
			.quote {
				left: -15px;

				svg {
					height: 25px;
					margin-right: -7px;
				}
			}

			.logo {
				width: 300px;
				height: 35px;
				margin-left: 25px;
			}

			.vertical-line {
				margin: 10px 7px 7px 7px;
				height: 1px;
				width: 36px;
			}

			.logo-text {
				margin-left: -15px;

				.creates {
					font-size: 14px;
				}

				.extraodinarily {
					font-size: 25px;
					line-height: 25px;
				}
			}
		}

		.text-content {
			padding: 0 15px;
			text-align: justify;

			.create-marketing {
				font-size: 26px;

				.marketing {
					letter-spacing: 2px;
				}

				.space {
					display: none;
				}

				.exp {
					letter-spacing: 7px;
					font-size: 36px;
				}
			}

			.description {
				font-size: 17px;
				line-height: 26px;
			}
		}

		.connect-text {
			margin-left: -50px;
			line-height: 45px;

			.line-content {
				width: 100px;
				margin-bottom: 20px;

				.horizontal-line {
					width: 90%;
				}
			}

			.text {
				font-size: 55px;
			}

			.reserver-quote {
				margin-left: 0;
				margin-top: -30px;

				svg {
					height: 15px;
					margin-left: -5px;
				}
			}
		}

		.animate-line {
			.animate-dot {
				right: 10%;
			}
		}

		.connect-cirle {
			display: none;
		}

		.connect-cirle-bottom {
			@include animate-circle(190px, 190px, 39%, 39%, 18s, 0.3, 350%);
		}
	}

	.osx-content {
		.connect-section {
			.connect-text {
				.line-content {
					width: 120px;

					.line-dot {
						margin-left: -6px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 412px) and (min-width: 376px) {
	.connect-section {
		.logo-content {
			.logo {
				width: 270px;
				height: 32px;
				margin-bottom: 0;
			}

			.logo-text {

				.creates {
					font-size: 10px;
				}

				.extraodinarily {
					font-size: 29px;
					line-height: 28px;
				}
			}
		}

		.text-content {
			padding-left: 20px;
			padding-right: 45px;

			.create-marketing {
				font-size: 21px;

				.exp {
					letter-spacing: 5px;
					font-size: 31px;
				}

				.marketing {
					letter-spacing: 1.7px;
				}
			}

			.description {
				font-size: 15px;
				line-height: 21px;
				letter-spacing: -0.5px;
			}
		}

		.connect-text {
			.text {
				font-size: 40px;
				letter-spacing: 3px;
			}
		}

		.connect-cirle-bottom {
			@include animate-circle(190px, 190px, 39%, 33%, 18s, 0.3, 350%);
		}
	}

	.osx-content {
		.connect-section {
			.text-content {
				.description {
					margin-right: 4px;
				}
			}

			.connect-text {
				.line-content {
					width: 135px;
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.connect-section {
		.logo-content {
			.logo {
				width: 250px;
				height: 28px;
			}

			.logo-text {

				.creates {
					font-size: 10px;
				}

				.extraodinarily {
					font-size: 29px;
					line-height: 28px;
				}
			}
		}

		.text-content {
			padding: 0 20px;

			.create-marketing {
				font-size: 22px;

				.exp {
					letter-spacing: 5px;
					font-size: 32px;
				}

				.marketing {
					letter-spacing: 1.7px;
				}
			}

			.description {
				font-size: 15px;
				line-height: 21px;
			}
		}

		.connect-text {
			.text {
				font-size: 40px;
				letter-spacing: 3px;
			}
		}

		.connect-cirle-bottom {
			@include animate-circle(190px, 190px, 39%, 33%, 18s, 0.3, 350%);
		}
	}

	.osx-content {
		.connect-section {
			.text-content {
				.description {
					margin-right: 4px;
				}
			}

			.connect-text {
				.line-content {
					width: 135px;
				}
			}
		}
	}
}

@media screen and (max-width: 360px) {
	.connect-section {
		.text-content {
			.create-marketing {
				.marketing {
					letter-spacing: 0.9px;
				}

				.exp {
					letter-spacing: 4px;
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.connect-section {
		.logo-content {
			.logo {
				width: 200px;
				height: 23px;
			}

			.logo-text {
				.extraodinarily {
					font-size: 24px;
					line-height: 30px;
				}
			}
		}

		.text-content {
			padding: 0 10px 0 23px;

			.create-marketing {
				font-size: 18px;

				.marketing {
					letter-spacing: 1px;
				}

				.exp {
					letter-spacing: 5px;
					font-size: 24px;
				}
			}

			.description {
				font-size: 13px;
				line-height: 19px;
			}
		}

		.connect-text {
			.text {
				font-size: 36px;
				letter-spacing: 3px;
			}

			.line-content {
				width: 72px;
			}

			.reserver-quote {
				margin-top: -20px;
			}
		}

		.connect-cirle-bottom {
			@include animate-circle(170px, 170px, 39%, 23%, 18s, 0.3, 350%);
		}
	}
}