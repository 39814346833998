@keyframes shine {
	to {
		background-position: 150% center;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotate-countdown {
	0% {
		transform: rotate(0deg);
	}

	10% {
		transform: rotate(40deg);
	}

	20% {
		transform: rotate(80deg);
	}

	30% {
		transform: rotate(120deg);
	}

	40% {
		transform: rotate(160deg);
	}

	50% {
		transform: rotate(200deg);
	}

	60% {
		transform: rotate(240deg);
	}

	70% {
		transform: rotate(280deg);
	}

	80% {
		transform: rotate(320deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes orbit {
	0% {
		transform: rotateZ(0);
	}

	100% {
		transform: rotateZ(-360deg);
	}
}

@keyframes suborbit {
	0% {
		transform: rotateX(90deg) rotateZ(0);
	}

	100% {
		transform: rotateX(90deg) rotateZ(-360deg);
	}
}

@keyframes invert {
	0% {
		transform: rotateX(-90deg) rotateY(360deg) rotateZ(0);
	}

	100% {
		transform: rotateX(-90deg) rotateY(0) rotateZ(0);
	}
}

@keyframes scale-fontsize {
	0% {
		transform: scale(.5) rotateX(5deg);
	}

	50% {
		transform: scale(1) rotateX(20deg);
	}

	100% {
		transform: scale(.5) rotateX(5deg);
	}
}

@keyframes scale-fontsize-2 {
	0% {
		transform: scale(.75) rotateX(12.5deg);
	}

	25% {
		transform: scale(1) rotateX(20deg);
	}

	50% {
		transform: scale(.75) rotateX(12.5deg);
	}

	75% {
		transform: scale(.5) rotateX(5deg);
	}

	100% {
		transform: scale(.75) rotateX(12.5deg);
	}
}

@keyframes scale-fontsize-3 {
	0% {
		transform: scale(.75) rotateX(12.5deg);
	}

	25% {
		transform: scale(.5) rotateX(5deg);
	}

	50% {
		transform: scale(.75) rotateX(12.5deg);
	}

	75% {
		transform: scale(1) rotateX(20deg);
	}

	100% {
		transform: scale(.75) rotateX(12.5deg);
	}
}

@keyframes scale-fontsize-4 {
	0% {
		transform: scale(1) rotateX(20deg);
	}

	50% {
		transform: scale(.5) rotateX(5deg);
	}

	100% {
		transform: scale(1) rotateX(20deg);
	}
}

@keyframes blink {
	0% {
		opacity: 0;
	}

	40% {
		opacity: 1;
	}

	60% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes lds {
	0% {
		top: 8px;
		height: 64px;
	}

	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(19px, 0);
	}
}

@media screen and (max-width: 576px) {
	@keyframes lds {
		0% {
			top: 6px;
			height: 32px;
		}

		50%,
		100% {
			top: 22px;
			height: 16px;
		}
	}
}