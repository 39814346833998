.our-works {
	.work-wrapper {
		background: linear-gradient(45deg,
				#650070 0%,
				#5545d3 10%,
				#01eee3 20%,
				#650070 30%,
				#5545d3 40%,
				#01eee3 50%,
				#650070 60%,
				#5545d3 70%,
				#01eee3 80%,
				#650070 90%,
				#5545d3 100%);
		background-size: 1000% auto;
		animation: shine 10s linear infinite;
		padding: 10px 0;
	}

	.more-work-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 75px;
		margin-bottom: 200px;
		cursor: pointer;
		color: $yellow-main;
		align-items: flex-end;

		.line-content {
			padding: 0;

			.line-dot {
				font-size: 80px;
				margin-left: -9px;
				color: white;
			}

			.horizontal-line {
				width: 100%;
				height: 2px;
				background: linear-gradient(90deg,
						white 0%,
						white 50%,
						white 100%);
				background-size: 300% auto;
			}

			&.first-dot {
				.line-dot {
					margin-left: unset;
					margin-right: -6px;
				}
			}
		}

	}
}

@media screen and (max-width: 1640px) {
	.our-works {

		.more-work-wrapper {
			margin-top: 60px;
			margin-bottom: 170px;

			.line-content {
				padding: 0;

				.line-dot {
					font-size: 60px;
					margin-left: -9px;
					color: white;
				}

				.horizontal-line {
					width: 100%;
					height: 2px;
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.our-works {
		.more-work-wrapper {
			margin-bottom: 145px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.our-works {
		.more-work-wrapper {
			.button-wrapper {
				padding: 0;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.our-works {
		.work-wrapper {
			padding: 4px 0;
		}
		.more-work-wrapper {
			.line-content {
				.horizontal-line {
					height: 1px;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.our-works {
		.work-wrapper {
			padding: 2px 0;
		}

		.more-work-wrapper {
			margin-top: 20px;
		}
	}
}

@media screen and (max-width: 320px) {}