@font-face {
	font-family: 'Montserrat Regular';
	src: local('Montserrat-Regular'), url('../assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'Montserrat ExtraBold';
	src: local('Montserrat-ExtraBold'), url('../assets/fonts/Montserrat-ExtraBold.ttf');
}

@font-face {
	font-family: 'Montserrat Thin';
	src: local('Montserrat-Thin'), url('../assets/fonts/Montserrat-Thin.ttf');
}

@font-face {
	font-family: 'Montserrat Bold';
	src: local('Montserrat-Bold'), url('../assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
	font-family: 'Montserrat Light';
	src: local('Montserrat-Light'), url('../assets/fonts/Montserrat-Light.ttf');
}

@font-face {
	font-family: 'Montserrat Italic';
	src: local('Montserrat-Italic'), url('../assets/fonts/Montserrat-Italic.ttf');
}

@font-face {
	font-family: 'Montserrat Thin Italic';
	src: local('Montserrat-ThinItalic'), url('../assets/fonts/Montserrat-ThinItalic.ttf');
}

@font-face {
	font-family: 'Montserrat SemiBold';
	src: local('Montserrat-SemiBold'), url('../assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
	font-family: 'Montserrat SemiBoldItalic';
	src: local('Montserrat-SemiBoldItalic'), url('../assets/fonts/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
	font-family: 'Montserrat MediumItalic';
	src: local('Montserrat-MediumItalic'), url('../assets/fonts/Montserrat-MediumItalic.ttf');
}

@font-face {
	font-family: 'Montserrat ExtraLight';
	src: local('Montserrat-ExtraLight'), url('../assets/fonts/Montserrat-ExtraLight.ttf');
}

@font-face {
	font-family: 'Montserrat Medium';
	src: local('Montserrat-Medium'), url('../assets/fonts/Montserrat-Medium.ttf');
}