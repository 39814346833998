.osx-content {
	.compentencies-content {
		.feature-title {
			.line-content {
				.horizontal-line {
					top: -3.5px;
				}
			}
		}
	}
}

.compentencies-content {
	position: relative;

	.feature-wrapper {
		margin-top: 90px;
		margin-left: 225px;
	}

	.feature-content {
		margin-bottom: 30px;
		align-items: baseline;

		&:last-child {
			margin-bottom: 90px;
		}

		.number-content {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 153px;

			.text {
				font-size: 110px;
				font-family: 'Montserrat Bold';
				color: white;
				z-index: 1;
				animation: shine 5s linear infinite;
				color: #000;
				-webkit-text-fill-color: transparent;
				background: linear-gradient(45deg,
						#bb1ae5 0%,
						#5f80f9 50%,
						#15c8fc 100%);
				background-size: 100% auto;
				background-clip: text;
				-webkit-background-clip: text;
			}

			.animate-dot {
				position: absolute;
				background: linear-gradient(45deg,
						#fb08a6 0%,
						#15c8fc 25%,
						#fb08a6 50%,
						#15c8fc 75%,
						#fb08a6 100%);
				@include animate-dot(6s, 600%, 0);
			}
		}

		.feature-title {
			display: flex;
			align-items: baseline;
			padding-left: 50px;
			padding-top: 40px;
			padding-bottom: 40px;

			.title {
				font-family: 'Montserrat SemiBold';
				font-size: 82px;
				color: $yellow-main;

				&.core-value {
					min-width: 500px;
				}
			}

			.line-content {
				width: 100%;
				@include z-index(relative, 2);

				.line-dot {
					font-size: 100px;
					margin-right: -9px;
					color: $blue-1;
				}

				.horizontal-line {
					width: 100%;
					height: 1.5px;
					background: linear-gradient(90deg, $blue-1 0%, $blue-1 50%, $blue-1 100%);
					background-size: 300% auto;
					animation: shine 3s linear infinite;
				}
			}
		}

		.feature-description {
			font-family: 'Montserrat Thin';
			font-size: 21px;
			color: white;
			text-align: justify;
			margin-right: 135px;
			line-height: 1.5;
			padding-left: 55px;
			@include z-index(relative, 2);
		}

	}

	.slogan {
		padding: 30px 0;
		height: 240px;
		position: relative;
		z-index: 1;

		.slogan-wrapper {
			margin-left: 270px;
		}

		.slogan-content {
			align-items: center;

			.slogan-list {
				padding-left: 35px;
				color: white;
				font-family: 'Montserrat MediumItalic';
				font-size: 23px;

				&.ltr-list {
					padding-left: 0;
				}

				ul {
					line-height: 2;

					li::before {
						content: '•';
						position: relative;
						top: 5px;
						left: -0.5em;
						color: white;
						font-size: 50px;
						line-height: 1;
					}

					.label {
						position: relative;
						margin-left: -13px;
					}
				}
			}

			.vertical-line {
				margin-left: 70px;

				&.ltr-line {
					margin-left: 0;
				}
			}

			.slogan-icon {

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.seven-dots {
		margin-left: 315px;
		margin-top: 20px;
		@include z-index(relative, 3);

		.animate-dot {
			&.right {
				text-align: right;
				position: relative;
				right: -17%;
				padding-bottom: 3rem;
			}

			background: linear-gradient(45deg,
				#fb08a6 0%,
				#15c8fc 12.5%,
				#fb08a6 25%,
				#15c8fc 37.5%,
				#fb08a6 50%,
				#15c8fc 62.5%,
				#fb08a6 75%,
				#15c8fc 87.5%,
				#fb08a6 100%);
			@include animate-dot(10s, 300%, 0);
			margin-top: 20px;
		}
	}

	.compentencies-content__circle-top {
		@include animate-circle(490px, 490px, -1%, 7%, 17s, 0.5, 300%);
		background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
	}

	.compentencies-content__circle-bottom {
		@include animate-circle(860px, 860px, 84%, 46%, 18s, 0.3, 250%);
		background-image: linear-gradient(90deg, #5a0823 0%, #ec1e04 50%, #5a0823 100%);
		z-index: 1;
	}

	.compentencies-content__circle-right {
		@include animate-circle(300px, 300px, 85%, 4%, 16s, 0.5, 250%);
		background-image: linear-gradient(90deg, #042e2e 0%, #02526b 50%, #042e2e 100%);
		z-index: 2;
	}
}

@media screen and (max-width: 1640px) {
	.compentencies-content {
		.feature-wrapper {
			margin-top: 90px;
			margin-left: 200px;
		}

		.feature-content {

			&:last-child {
				margin-bottom: 80px;
			}

			.number-content {

				.text {
					font-size: 100px;
				}

				.animate-dot {
					div {
						line-height: 14px;
						letter-spacing: 0;
					}
				}
			}

			.feature-title {
				padding-bottom: 30px;

				.title {
					font-size: 70px;
					z-index: 1;

					&.core-value {
						min-width: 450px;
					}
				}
			}

			.feature-description {
				font-size: 19px;
			}

		}

		.slogan {

			.slogan-content {

				.slogan-list {
					font-size: 19px;

					ul {
						line-height: 1.5;
					}
				}
			}
		}

		.seven-dots {
			margin-left: 240px;
			max-width: 940px;
		}

		.compentencies-content__circle-top {
			@include animate-circle(348px, 348px, 2%, 7%, 17s, 0.5, 300%);
		}

		.compentencies-content__circle-bottom {
			@include animate-circle(632px, 632px, 90%, 48%, 18s, 0.4, 250%);
		}

		.compentencies-content__circle-right {
			@include animate-circle(193px, 193px, 90%, 5%, 16s, 0.6, 250%);

		}
	}
}

@media screen and (max-width: 1440px) {
	.compentencies-content {
		.feature-wrapper {
			margin-top: 80px;
			margin-left: 180px;
		}

		.feature-content {
			.number-content {

				.text {
					font-size: 80px;
				}

				.animate-dot {
					padding-bottom: 10px;

					div {
						line-height: 10px;
						letter-spacing: 0;
					}
				}
			}

			.feature-title {

				.title {
					font-size: 60px;
					z-index: 1;

					&.core-value {
						min-width: 365px;
					}
				}
			}

			.feature-description {
				font-size: 18px;
			}

		}

		.slogan {

			.slogan-content {

				.slogan-list {
					font-size: 17px;

					ul {
						line-height: 1.5;
					}
				}
			}
		}

		.seven-dots {
			margin-left: 240px;
			max-width: 940px;
		}

		.compentencies-content__circle-top {
			@include animate-circle(348px, 348px, 2%, 7%, 17s, 0.5, 300%);
		}

		.compentencies-content__circle-bottom {
			@include animate-circle(632px, 632px, 90%, 48%, 18s, 0.4, 250%);
		}

		.compentencies-content__circle-right {
			@include animate-circle(193px, 193px, 90%, 5%, 16s, 0.6, 250%);

		}
	}
}

@media screen and (max-width: 1366px) {
	.compentencies-content {
		.feature-wrapper {
			margin: 70px 0 0 140px;
		}

		.feature-content {
			.number-content {
				.text {
					font-size: 85px;
				}
			}

			.feature-title {
				.title {
					font-size: 63px;

					&.core-value {
						min-width: 385px;
					}
				}
			}

			.feature-description {
				font-size: 16px;
			}
		}

		.slogan {
			.slogan-wrapper {
				margin-left: 182px;
			}
		}

		.seven-dots {
			margin-left: 240px;
			max-width: 940px;
			height: 330px;

			.animate-dot {
				position: relative;
				right: 12%;

				&.right {
					bottom: 30%;
					right: 3%;

					div {
						@include line-common(100px, 4px, 60px);
					}
				}
			}
		}

		.compentencies-content__circle-top {
			@include animate-circle(348px, 348px, 2%, 7%, 17s, 0.5, 300%);
		}

		.compentencies-content__circle-bottom {
			@include animate-circle(632px, 632px, 90%, 48%, 18s, 0.4, 250%);
		}

		.compentencies-content__circle-right {
			@include animate-circle(193px, 193px, 90%, 5%, 16s, 0.6, 250%);

		}
	}
}

@media screen and (max-width: 1280px) {
	.compentencies-content {

		.feature-content {

			.number-line {
				top: 18%;
			}
		}

		.seven-dots {
			margin-left: 240px;
			max-width: 940px;
			height: 330px;

			.animate-dot {
				position: relative;
				right: 12%;

				div {
					@include line-common(35px, 3px, 23px);
				}

				&.right {
					bottom: 5%;
					right: -54.5%;

					div {
						@include line-common(80px, 3px, 50px);
					}
				}
			}
		}

		.slogan {
			.slogan-wrapper {
				margin-left: 182px;
			}

			.slogan-content {
				.slogan-list {
					font-size: 14px;
				}
			}
		}

		.compentencies-content__circle-right {
			@include animate-circle(185px, 185px, 90%, 5%, 16s, 0.6, 250%);
		}
	}
}

@media screen and (max-width: 1024px) {
	.compentencies-content {
		.feature-wrapper {
			margin: 50px 0 0 100px;
		}

		.feature-content {
			.number-content {
				.text {
					font-size: 75px;
				}

				.animate-dot {
					right: 7%;

					div {
						@include line-common(19px, 0, 10px);
					}
				}
			}

			.feature-title {
				padding-bottom: 20px;
				padding-left: 10px;

				.title {
					font-size: 52px;

					&.core-value {
						min-width: 320px;
					}
				}
			}

			.feature-description {
				padding-left: 10px;
				font-size: 14px;
			}
		}

		.slogan {
			.slogan-wrapper {
				margin-left: 85px;
			}

			.slogan-content {
				.slogan-list {
					font-size: 13px;

					ul {
						li::before {
							font-size: 36px;
						}
					}
				}

				//--end
			}
		}

		.seven-dots {
			margin-left: 240px;
			max-width: 940px;
			height: 330px;

			.animate-dot {
				position: relative;
				right: 12%;

				&.right {
					bottom: 6%;
					right: 32%;

					div {
						@include line-common(80px, 0, 42px);
					}
				}
			}
		}

		.compentencies-content__circle-top {
			@include animate-circle(348px, 348px, -1%, 6%, 17s, 0.5, 300%);
		}

		.compentencies-content__circle-bottom {
			@include animate-circle(500px, 500px, 90%, 48%, 18s, 0.4, 250%);
		}

		.compentencies-content__circle-right {
			@include animate-circle(150px, 150px, 90%, 5%, 16s, 0.6, 250%);

		}
	}
}

@media screen and (max-width: 800px) {
	.compentencies-content {

		.feature-content {
			.number-content {
				.animate-dot {
					top: 27%;

					div {
						@include line-common(14px, 0, 7px);
					}
				}
			}

			.feature-title {
				.title {
					font-size: 48px;

					&.core-value {
						min-width: 295px;
					}
				}
			}

			.feature-description {
				margin-right: 85px;
			}
		}

		.slogan {
			.slogan-content {
				.slogan-icon {

					img {
						object-fit: contain;
					}

					// TODO: adjust width height

				}

			}
		}

		.seven-dots {
			margin-left: 240px;
			max-width: 940px;
			height: 330px;

			.animate-dot {
				position: relative;
				right: 12%;

				&.right {
					display: none;
				}
			}
		}

		.compentencies-content__circle-top {
			@include animate-circle(263px, 263px, 2%, 6%, 17s, 0.5, 300%);
		}

		.compentencies-content__circle-bottom {
			@include animate-circle(342px, 342px, 92%, 45%, 18s, 0.4, 250%);
		}

		.compentencies-content__circle-right {
			@include animate-circle(104px, 104px, 90%, 5%, 16s, 0.6, 250%);
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.osx-content {
		.compentencies-content {
			.feature-wrapper {
				.feature-content {
					.feature-title {
						.line-content {
							.horizontal-line {
								top: -1.5px;
							}
						}
					}
				}
			}
		}
	}

	.compentencies-content {
		.feature-wrapper {
			margin: 0 50px;

			.feature-content {
				flex-wrap: unset;
				margin-bottom: 0;

				&:last-child {
					margin-bottom: 15px;
				}

				.number-content {
					.animate-dot {
						top: 38%;

						div {
							@include line-common(7px, 0, 4px);
						}
					}

					.text {
						font-size: 35px;
					}
				}

				.feature-title {
					.title {
						font-size: 20px;

						&.core-value {
							min-width: 125px;
						}
					}

					.line-content {
						.line-dot {
							font-size: 55px;
						}
					}
				}

				.feature-description {
					margin-right: 15px;
					font-size: 10px;
				}
			}
		}

		.slogan {
			padding: 10px 0;
			height: auto;
			min-height: 250px;

			.slogan-wrapper {
				margin-left: 50px;

				.col-1.col-md-1 {
					display: none;
				}

				&.right {
					.slogan-content {
						flex-direction: column-reverse;
					}
				}

				&.left {
					.slogan-content {
						flex-direction: column;
					}
				}

				.slogan-content {
					padding: 0;

					.slogan-list {
						font-size: 10px;

						ul {
							li {
								&::before {
									font-size: 25px;
									left: -15px;
								}

								.label {
									margin-left: -8px;
								}
							}
						}
					}

					.vertical-line {
						transform: rotate(90deg);
						margin-left: 0;
						height: 50px;
					}

				}
			}
		}

		.seven-dots {
			margin-left: 30px;
			max-width: 100%;
			height: 160px;

			.animate-dot {
				position: relative;
				right: -10%;
				top: 12%;

				div {
					@include line-common(10px, 1px, 7px);
				}

				&.right {
					right: -50%;
					bottom: 0;

					div {
						@include line-common(30px, 0, 20px);
					}
				}
			}
		}

		.compentencies-content__circle-top {
			@include animate-circle(109px, 109px, 4%, 16%, 17s, 0.5, 300%);
		}

		.compentencies-content__circle-bottom {
			@include animate-circle(142px, 142px, 96%, 45%, 18s, 0.4, 250%);
		}

		.compentencies-content__circle-right {
			@include animate-circle(44px, 44px, 97%, 5%, 16s, 0.6, 250%);
		}
	}
}

@media screen and (max-width: 412px) and (min-width: 376px) {
	.compentencies-content {
		.feature-wrapper {

			.feature-content {

				.number-content {
					.animate-dot {
						top: 38%;

						div {
							@include line-common(6px, 0, 4px);
						}
					}
				}
			}
		}
	}
}