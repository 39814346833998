.side-nav {
	position: fixed;
	right: 70px;
	z-index: 10;

	.list-circle {
		padding: 0;
		margin-left: 7px;

		.circle-item {
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 3px solid #494949;
			margin-top: 10px;
			position: relative;

			&.active {
				border: 3px solid white;

				&::after {
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background: $yellow-main;
				}
			}
		}
	}


	.vertical-line-1 {
		border-left: 1px solid grey;
		height: 135px;
		margin: 20px 0;
		margin-left: 15px;
	}

	.vertical-line-2 {
		border-left: 1px solid grey;
		height: 100px;
		margin-top: 20px;
		margin-bottom: 40px;
		margin-left: 15px;
	}

	.srcoll-down {
		font-family: 'Montserrat Regular';
		font-size: 11px;
		color: #696969;
		transform: rotate(-90deg);
		margin-left: -18px;
		margin-top: 10px;
	}

	.arrow {
		color: white;
		transform: rotate(90deg);
		text-align: right;
		font-size: 11px;
		margin-left: -18px;
	}

	.icon {
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	.icon-fb {
		width: 10px;
		height: 18px;
		margin-bottom: 30px;
		margin-left: 11.5px;
	}

	.icon-in {
		width: 17px;
		height: 17px;
		margin-bottom: 30px;
		margin-left: 8.5px;
	}

	.icon-utube {
		width: 31px;
		height: 25px;
		margin-bottom: 30px;
	}

	.icon-vimeo {
		width: 17px;
		height: 15px;
		margin-left: 8.5px;
	}
}

.scroll-up {
	background-color: white;
	padding: 10px;
	border-radius: 50%;
	position: fixed;
	right: 70px;
	bottom: 50px;
	cursor: pointer;
	box-shadow: 3px 2px 20px black;
	z-index: 10;

	.arrow-icon {
		width: 32px;
		height: 32px;
		transform: rotate(-90deg);

		img {
			width: 100%;
			height: 100%;
		}
	}
}

@media screen and (max-width: 1640px) {
	.scroll-up {
		right: 30px;
	}

	.side-nav {
		right: 30px;

		.vertical-line-1 {
			height: 90px;
		}

		.vertical-line-2 {
			height: 70px;
		}
	}
}

@media screen and (max-width: 1440px) {
	.scroll-up {
		bottom: 10px;
		right: 45px;

		.arrow-icon {
			width: 16px;
			height: 16px;
		}
	}
}

@media screen and (max-width: 1366px) {
	.side-nav {

		.list-circle {
			.circle-item {
				width: 12px;
				height: 12px;

				&.active {

					&::after {
						width: 6px;
						height: 6px;
					}
				}
			}
		}

		.vertical-line-1 {
			height: 70px;
		}

		.vertical-line-2 {
			height: 50px;
		}
	}
}

@media screen and (max-width: 1280px) {
	.scroll-up {
		right: 15px;

		.arrow-icon {
			width: 24px;
			height: 24px;
		}
	}

	.side-nav {
		right: 10px;
	}
}

@media screen and (max-width: 800px) {
	.scroll-up {
		right: 10px;
		padding: 5px;

		.arrow-icon {
			width: 16px;
			height: 16px;
		}
	}

	.side-nav {
		right: -5px;
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.side-nav {
		right: -15px;

		.list-circle {
			margin-left: 12px;

			.circle-item {
				width: 7px;
				height: 7px;
				border: 1px solid #494949;

				&.active {
					border: 1px solid white;

					&::after {
						width: 5px;
						height: 5px;
					}
				}
			}
		}

		.icon-fb {
			width: 5px;
			height: 12px;
			margin-bottom: 15px;
		}

		.icon-in {
			width: 10px;
			height: 10px;
			margin-bottom: 15px;
			margin-left: 11.5px;
		}

		.icon-utube {
			width: 16px;
			height: 12px;
			margin-bottom: 15px;
			margin-left: 8px;
		}

		.icon-vimeo {
			width: 10px;
			height: 8px;
			margin-left: 11.5px;
		}

		.arrow {
			margin-left: -14px;
		}
	}
}