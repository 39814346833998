.about-page {
	padding-top: 50px;

	.connect-section {

		.connect-text {
			z-index: 3;
		}

		.text-content {
			.create-marketing {
				z-index: 3;
			}
		}

		.connect-cirle {
			@include animate-circle(232px, 232px, -5%, 11%, 15s, 0.6, 350%);
			background-image: linear-gradient(90deg, rgb(0, 10, 122) 0%, rgb(150, 58, 255) 50%, rgb(0, 10, 122) 100%);
		}

		.connect-cirle-bottom {
			@include animate-circle(405px, 405px, 15%, 38%, 18s, 0.2, 350%);
			background-image: linear-gradient(90deg, rgb(143, 8, 31) 0%, rgb(235, 8, 189) 50%, rgb(143, 8, 31) 100%);
			z-index: 2;
		}
	}
}

@media screen and (max-width: 1366px) {
	.about-page {
		.connect-section {
			margin-bottom: 0;

			.connect-cirle {
				@include animate-circle(128px, 128px, -2%, 8.5%, 15s, 0.6, 250%);
			}

			.connect-cirle-bottom {
				@include animate-circle(247px, 247px, 26%, 36%, 18s, 0.3, 350%);
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.about-page {
		.connect-section {
			.connect-cirle {
				@include animate-circle(128px, 128px, -4%, 9.5%, 15s, 0.6, 250%);
			}

			.connect-cirle-bottom {
				@include animate-circle(247px, 247px, 26%, 40%, 18s, 0.3, 350%);
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.about-page {
		.connect-section {
			margin-bottom: 0;
			margin-left: 130px;

			.connect-cirle {
				@include animate-circle(128px, 128px, -2%, 12.5%, 15s, 0.6, 250%);
			}

			.connect-cirle-bottom {
				@include animate-circle(247px, 247px, 26%, 36%, 18s, 0.3, 350%);
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.about-page {
		.switch-wrapper {
			.list-content {
				&.bottom-content {
					bottom: -7px;
				}
			}
		}

		.connect-section {

			.text-content {
				.create-marketing {
					.marketing {
						letter-spacing: 1px;
					}

					.space {
						display: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.about-page {

		.connect-section {
			margin-right: 80px;
		}
	}
}

@media screen and (max-width: 576px) {
	.about-page {
		.switch-wrapper {
			.list-content {
				&.bottom-content {
					bottom: -3px;

					li {
						margin: 0 30px !important;
					}
				}
			}
		}

		.connect-section {
			margin-left: 35px;
			margin-right: 40px;

			.animate-line {
				.animate-dot {
					top: -160%;

					div {
						@include line-common(12px, 2px, 0.65);
					}
				}
			}

			.text-content {
				.create-marketing {
					.marketing {
						letter-spacing: 1.5px;
					}
				}
			}

			.connect-cirle-bottom {
				@include animate-circle(171px, 171px, 39%, 39%, 18s, 0.3, 350%);
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.about-page {
		.connect-section {
			.text-content {
				padding: 0 20px;

				.create-marketing {
					font-size: 22px;

					.exp {
						letter-spacing: 5px;
						font-size: 32px;
					}

					.marketing {
						letter-spacing: 1.7px;
					}
				}

				.description {
					font-size: 15px;
					line-height: 21px;
				}
			}
		}
	}
}

@media screen and (max-width: 360px) {
	.about-page {
		.connect-section {
			.text-content {
				.create-marketing {
					.marketing {
						letter-spacing: 0.9px;
					}

					.exp {
						letter-spacing: 4px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.about-page {
		.switch-wrapper {
			.list-content {
				&.bottom-content {
					bottom: -3px;

					li {
						margin: 0 16px !important;
					}
				}
			}
		}

		.connect-section {
			.text-content {
				padding: 0 10px 0 23px;

				.create-marketing {
					font-size: 18px;

					.marketing {
						letter-spacing: 1px;
					}

					.exp {
						letter-spacing: 5px;
						font-size: 24px;
					}
				}

				.description {
					font-size: 13px;
					line-height: 19px;
				}
			}

			.connect-text {
				.text {
					font-size: 36px;
					letter-spacing: 3px;
				}

				.line-content {
					width: 72px;
				}

				.reserver-quote {
					margin-top: -20px;
				}
			}
		}
	}
}