.management-wrapper {
	margin-top: 680px;

	.ceo-wrapper {
		width: 100%;
		min-height: 752px;
		padding: 20px;
		background-color: $yellow-main;

		.ceo-content {
			background-color: black;
			width: 100%;
			height: 100%;
			margin: 0;
			align-items: center;

			.image {
				height: 100%;

				.image-content {
					height: 100%;
					width: 90%;
					position: relative;
					left: 60px;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.introduce-content {
			.name-content {
				color: $yellow-main;
				font-family: 'Montserrat SemiBold';
				line-height: 0.7;
				text-transform: uppercase;

				.title {
					font-size: 43px;
				}

				.name {
					font-size: 82px;
				}
			}

			.position {
				color: white;
				font-family: 'Montserrat Regular';
				font-size: 41px;
				line-height: 2;
			}

			.slogan {
				color: $yellow-main;
				font-size: 21px;
				font-family: 'Montserrat SemiBoldItalic';
				line-height: 0.6;
				letter-spacing: 0.7px;

				.slogan1,
				.slogan2 {
					text-transform: uppercase;
				}

				.slogan2 {
					padding-left: 24px;
					white-space: nowrap;
				}

				.quote-text {
					font-family: 'Montserrat SemiBold';
					font-size: 53px;
				}
			}

			.description {
				margin-top: 40px;
				color: white;
				font-family: 'Montserrat Regular';
				font-size: 19px;
				text-align: justify;
				line-height: 1.5;
			}
		}

	}

	.emloyee-content {
		text-align: right;

		.name-content,
		.description {
			padding: 0;
		}

		.name-content {
			font-family: 'Montserrat SemiBold';
			height: 75%;

			.title {
				font-size: 30px;
				text-transform: uppercase;
			}

			.name {
				font-size: 46px;
				text-transform: uppercase;
			}

			.position {
				color: white;
				font-size: 13px;
				font-family: 'Montserrat Regular';
				line-height: 2;
			}
		}

		.description {
			display: inline-block;
			font-family: 'Montserrat SemiBold';
			text-transform: uppercase;
			font-size: 13px;

			.inner {
				transform: translateX(-50%) translateY(-50%) rotate(-90deg);
				white-space: nowrap;
				position: absolute;
				letter-spacing: 2.3px;

				&.left {
					left: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 1640px) {
	.management-wrapper {
		margin-top: 570px;

		.ceo-wrapper {
			height: 650px;

			.ceo-content {

				.image {

					.image-content {
						width: 100%;
						left: 20px;
					}
				}
			}

			.introduce-content {

				.position {
					font-size: 38px;
				}

				.slogan {
					font-size: 19px;
				}

				.description {
					margin-top: 30px;
					font-size: 19px;
				}
			}
		}

		.emloyee-content {

			.name-content {

				.title {
					font-size: 25px;
				}

				.name {
					font-size: 39px;
				}

				.position {
					font-size: 12px;
				}
			}

			.description {
				font-size: 12px;
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.management-wrapper {
		margin-top: 480px;

		.ceo-wrapper {
			min-height: 550px;

			.ceo-content {

				.image {
					padding: 0;

					.image-content {
						width: 85%;
						left: 50px;
					}
				}
			}

			.introduce-content {

				.name-content {
					.title {
						font-size: 30px;
					}

					.name {
						font-size: 64px;
					}
				}

				.position {
					font-size: 30px;
				}

				.slogan {
					font-size: 15px;

					.quote-text {
						font-size: 42px;
					}

					.slogan2 {
						padding-left: 20px;
					}
				}

				.description {
					font-size: 15px;
				}
			}
		}

		.emloyee-content {

			.name-content {

				.title {
					font-size: 22px;
				}

				.name {
					font-size: 33px;
				}

				.position {
					font-size: 11px;
				}
			}

			.description {
				font-size: 10px;
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.management-wrapper {
		margin-top: 495px;

		.ceo-wrapper {
			height: 500px;

			.ceo-content {

				.image {

					.image-content {
						width: 81%;
						left: 50px;
					}
				}
			}

			.introduce-content {

				.name-content {
					.title {
						font-size: 25px;
					}

					.name {
						font-size: 58px;
					}
				}

				.position {
					font-size: 22px;
				}

				.slogan {
					font-size: 14px;

					.quote-text {
						font-size: 25px;
					}

					.slogan2 {
						padding-left: 10px;
					}
				}

				.description {
					font-size: 14px;
				}
			}
		}

		.emloyee-content {

			.name-content {

				.title {
					font-size: 22px;
				}

				.name {
					font-size: 33px;
				}

				.position {
					font-size: 10px;
				}
			}

			.description {
				font-size: 10px;

				.inner {
					letter-spacing: 0.5px;
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.management-wrapper {
		.ceo-wrapper {
			.introduce-content {

				.slogan {
					font-size: 13px;

					.quote-text {
						font-size: 22px;
					}

					.slogan2 {
						padding-left: 10px;
					}
				}

				.description {
					font-size: 13px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.management-wrapper {
		margin-top: 380px;

		.ceo-wrapper {
			height: 400px;
			padding: 10px;

			.ceo-content {

				.image {

					.image-content {
						width: 100%;
						left: 15px;
					}
				}
			}

			.introduce-content {

				.name-content {
					.name {
						font-size: 50px;
					}

					.title {
						font-size: 30px;
					}
				}

				.position {
					font-size: 30px;
				}

				.slogan {
					font-size: 12px;

					.quote-text {
						font-size: 27px;
					}

					.slogan2 {
						padding-left: 10px;
					}
				}

				.description {
					font-size: 12px;
				}
			}
		}

		.emloyee-content {

			.name-content {

				.title {
					font-size: 28px;
				}

				.name {
					font-size: 37px;
				}

				.position {
					font-size: 12px;
				}
			}

			.description {
				font-size: 14px;

				.inner {
					letter-spacing: 0.5px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.management-wrapper {
		margin-top: 345px;

		.ceo-wrapper {
			height: 590px;

			.ceo-content {
				.image {
					.image-content {
						height: 95%;
						top: 29px;
					}
				}
			}

			.introduce-content {

				.name-content {
					.name {
						font-size: 45px;
					}

					.title {
						font-size: 25px;
					}
				}

				.position {
					font-size: 24px;
				}

				.slogan {
					line-height: 1.1;

					.slogan1 {
						white-space: nowrap;
					}

					.slogan2 {
						margin-top: 7px;
						white-space: pre-wrap;
					}
				}
			}
		}

		.emloyee-content {

			.name-content {
				width: 60%;
				height: 85%;

				.title {
					font-size: 32px;
				}

				.name {
					font-size: 31px;
				}

				.position {
					font-size: 10px;
				}
			}

			.description {
				font-size: 15px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.management-wrapper {
		margin-top: 540px;

		.ceo-wrapper {
			height: 660px;

			.ceo-content {
				position: relative;

				.image {
					position: absolute;

					.image-content {
						width: 220px;
						height: 300px;
						float: right;
						// margin-top: 20px;
						margin-right: 30px;
					}
				}
			}

			.introduce-content {
				position: relative;
				top: 60px;
				padding: 0 30px;

				.name-content {
					line-height: 1;

					.name {
						display: block;
						font-size: 40px;
						width: 90px;
					}

					.title {
						display: block;
						font-size: 20px;
					}
				}

				.position {
					font-size: 20px;
				}

				.slogan {
					line-height: 1.1;
					font-size: 10px;
					background-color: black;
					box-shadow: 1px -4em 4em 4em black;

					.quote-text {
						font-size: 17px;
					}

					.slogan1 {
						white-space: nowrap;
					}

					.slogan2 {
						margin-top: 5px;
						white-space: pre-wrap;
					}
				}

				.description {
					margin-top: 10px;
					font-size: 10px;
				}
			}
		}

		.emloyee-content {

			.name-content {
				width: 44%;

				.title {
					font-size: 15px;
				}

				.name {
					font-size: 25px;
				}

				.position {
					font-size: 11px;
				}
			}

			.description {
				font-size: 9px;
			}
		}
	}
}

@media screen and (max-width: 412px) {
	.management-wrapper {
		margin-top: 550px;

		.ceo-wrapper {

			.ceo-content {

				.image {

					.image-content {
						width: 200px;
						height: 280px;
						margin-right: 20px;
					}
				}
			}

			.introduce-content {
				padding: 0 20px;

				.name-content {

					.name {
						font-size: 33px;
					}

					.title {
						font-size: 17px;
					}
				}

				.position {
					font-size: 17px;
				}

				.slogan {
					font-size: 8px;
					box-shadow: 1px -4em 3em 2em black;
				}
			}
		}

		.emloyee-content {

			.name-content {

				.title {
					font-size: 12px;
				}

				.name {
					font-size: 23px;
				}

				.position {
					font-size: 9px;
				}
			}

			.description {
				font-size: 8px;
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.management-wrapper {
		margin-top: 470px;
	}
}

@media screen and (max-width: 320px) {
	.management-wrapper {
		margin-top: 370px;

		.ceo-wrapper {

			.ceo-content {

				.image {

					.image-content {
						width: 170px;
						height: 240px;
						margin-right: 20px;
					}
				}
			}

			.introduce-content {
				padding: 0 20px;

				.name-content {

					.name {
						font-size: 30px;
					}

					.title {
						font-size: 15px;
					}
				}

				.position {
					font-size: 15px;
				}
			}
		}

		.emloyee-content {

			.name-content {
				width: 33%;

				.title {
					font-size: 12px;
				}

				.name {
					font-size: 20px;
				}

				.position {
					font-size: 7px;
				}
			}

			.description {
				font-size: 8px;
			}
		}
	}
}