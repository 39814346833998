.osx-content {
	.filter-wrapper {

		.first-line,
		.second-line {
			display: -webkit-box;
			-webkit-box-align: baseline;

			.filter {
				display: -webkit-box;
				-webkit-box-align: center;
			}

			.line-content {
				display: -webkit-box;
			}
		}

		.second-line {
			.line-content {
				.line-dot {
					margin-right: -5px;
				}
			}
		}

		.line-content {
			.line-dot {
				margin-left: -16px;
			}
		}
	}
}

.creativeSol {
	.filter-wrapper {
		.second-line {
			.text {
				min-width: 786px;
			}
		}
	}
}

.filter-wrapper {
	margin-bottom: 300px;
	position: relative;
	z-index: 3;

	.line-content {

		.line-dot {
			font-size: 140px;
			margin-left: -10px;
		}

		.horizontal-line {
			position: relative;
			top: -4px;
			width: 100%;
			height: 3px;
			background: linear-gradient(90deg,
					rgba(129, 83, 208, 1) 0%,
					rgba(59, 232, 233, 1) 50%,
					rgba(129, 83, 208, 1) 100%);
			background-size: 300% auto;
			animation: shine 8s linear infinite;
		}
	}

	.first-line,
	.second-line {
		display: flex;
		align-items: baseline;
		background: linear-gradient(45deg,
				$violet-1 0%,
				$blue-1 12.5%,
				$violet-1 25%,
				$blue-1 37.5%,
				$violet-1 50%,
				$blue-1 62.5%,
				$violet-1 75%,
				$blue-1 87.5%,
				$violet-1 100%,
			);
		background-size: 300% auto;
		animation: shine 10s linear infinite;
		color: #000;
		background-clip: text;
		-webkit-text-fill-color: transparent;

		.text {
			font-family: 'Montserrat ExtraBold';
		}
	}

	.first-line {
		.text {
			font-size: 144px;
			line-height: 1.2;
		}

		.line-content {
			width: 15%;
			margin-right: 20px;
		}

		.filter {
			display: flex;
			align-items: center;
		}

		.vertical-line {
			width: 150px;
			margin: 50px -40px 0 -40px;
			height: 2px;
			transform: rotate(90deg);
			background: linear-gradient(90deg,
					$violet-1 0%,
					$blue-1 12.5%,
					$violet-1 25%,
					$blue-1 37.5%,
					$violet-1 50%,
					$blue-1 62.5%,
					$violet-1 75%,
					$blue-1 87.5%,
					$violet-1 100%);
			background-size: 2000% auto;
			animation: shine 10s linear infinite;
		}

		.btn-wrapper {
			padding: 1px;
			background: #05f5f3;
			margin-top: 30px;
			cursor: pointer;

			.btn-filter {
				background: black;
				padding: 20px;
				display: flex;
				align-items: center;

				.title {
					font-size: 32px;
					font-family: 'Montserrat Thin';
					background: linear-gradient(45deg,
							#05f5f3 0%,
							#05f5f3 25%,
							#05f5f3 50%,
							#05f5f3 75%,
							#05f5f3 100%,
						);
					background-size: 300% auto;
					animation: shine 3s linear infinite;
					color: #000;
					background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				.circle {
					width: 26px;
					height: 26px;
					border-radius: 50%;
					border: 2px solid #05f5f3;
					margin-left: 15px;
					position: relative;

					&:after {
						content: '';
						position: absolute;
						width: 16px;
						height: 16px;
						top: 3px;
						left: 3px;
						border-radius: 50%;
						background: #05f5f3;
					}
				}
			}
		}
	}

	.second-line {
		margin-left: calc(15% + 25px);
		margin-top: -10px;
		margin-bottom: 15px;

		.text {
			font-size: 80px;
			line-height: 1.2;

			&.hidden {
				visibility: hidden;
			}
		}

		.line-content {
			width: 100%;
			margin-left: 20px;

			.line-dot {
				margin-right: -9px;
				background: linear-gradient(45deg,
						$violet-1 0%,
						$blue-1 12.5%,
						$violet-1 25%,
						$blue-1 37.5%,
						$violet-1 50%,
						$blue-1 62.5%,
						$violet-1 75%,
						$blue-1 87.5%,
						$violet-1 100%,
					);
				background-size: 1000% auto;
				animation: shine 10s linear infinite;
				color: #000;
				background-clip: text;
			}
		}
	}

	.work-description {
		display: flex;
		margin-left: calc(15% + 40px);
		margin-right: 150px;

		.space {
			font-size: 80px;
			line-height: 1.2;
			visibility: hidden;
		}

		.description {
			font-size: 35px;
			font-family: 'Montserrat Thin';
			color: $yellow-main;
			margin-left: 20px;
			text-align: justify;
			line-height: 1.5;
			z-index: 2;

			.first-letter {
				float: left;
				font-family: 'Montserrat Bold';
				font-size: 94px;
				line-height: 1;
				padding-right: 25px;
			}
		}
	}

	.animate-dot {
		position: absolute;
		background: linear-gradient(45deg,
				#c004e0 0%,
				#08f2f2 12.5%,
				#c004e0 25%,
				#08f2f2 37.5%,
				#c004e0 50%,
				#08f2f2 62.5%,
				#c004e0 75%,
				#08f2f2 87.5%,
				#c004e0 100%);
		@include animate-dot(7s, 600%, 1);

		&.line-left-text {
			top: 56%;
			left: 6%;
		}

		&.line-right-text {
			right: 8%;
			bottom: 68%;
			padding-bottom: 10px;
		}
	}
}

@media screen and (max-width: 1640px) {
	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					min-width: 695px;
				}
			}
		}
	}

	.filter-wrapper {

		.first-line {
			.vertical-line {
				width: 120px;
				margin: 50px -30px 0 -30px;
			}

			.text {
				font-size: 130px;
			}

			.btn-wrapper {

				.btn-filter {

					.title {
						font-size: 30px;
					}
				}
			}
		}

		.second-line {
			.text {
				font-size: 72px;
			}
		}

		.work-description {

			.description {
				font-size: 30px;

				.first-letter {
					font-size: 84px;
				}
			}
		}

		.animate-dot {
			&.line-left-text {
				top: 54%;
				left: 5%;

				div {
					@include line-common (48px, 7px, 43px);
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					min-width: 635px;
				}
			}
		}
	}

	.filter-wrapper {
		margin-bottom: 200px;

		.first-line {
			.vertical-line {
				width: 100px;
				margin: 30px -10px 0 -10px;
			}

			.text {
				font-size: 100px;
			}

			.btn-wrapper {

				.btn-filter {

					.title {
						font-size: 26px;
					}
				}
			}
		}

		.second-line {
			.text {
				font-size: 65px;
			}
		}

		.work-description {

			.description {
				font-size: 26px;

				.first-letter {
					font-size: 74px;
				}
			}
		}

		.animate-dot {
			&.line-left-text {

				div {
					@include line-common (48px, 7px, 43px);
				}
			}

			&.line-right-text {
				bottom: 68%;
				padding-bottom: 10px;

				div {
					@include line-common (35px, 1px, 19px);
				}
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.osx-content {
		.filter-wrapper {
			margin-bottom: 150px;

			.first-line {

				.line-content {
					width: 12%;
					margin-right: 25px;
				}
			}
		}
	}

	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					min-width: 585px;
				}
			}
		}
	}

	.filter-wrapper {
		.first-line {
			.vertical-line {
				margin: 20px -20px 0 -20px;
				height: 1px;
			}

			.text {
				font-size: 95px;
				line-height: 1.4;
			}

			.btn-wrapper {
				.btn-filter {
					.title {
						font-size: 26px;
					}
				}

				.btn-filter {
					.circle {
						width: 21px;
						height: 21px;

						&::after {
							width: 11px;
							height: 11px;
						}
					}
				}
			}

			.line-content {
				width: 13%;
				margin-right: 10px;
			}
		}

		.second-line {
			margin-left: calc(10% + 53px);

			.text {
				font-size: 60px;
			}
		}

		.work-description {
			margin-left: calc(10% + 15px);
			margin-right: 110px;

			.description {
				font-size: 24px;
				margin-left: 55px;

				.first-letter {
					font-size: 65px;
				}
			}
		}

		.animate-dot {
			&.line-left-text {
				top: 58%;

				div {
					@include line-common (40px, 5px, 38px);
				}
			}

			&.line-right-text {
				right: 6%;
				bottom: 60%;
				padding-bottom: 10px;

				div {
					@include line-common (35px, 1px, 19px);
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					min-width: 460px;
				}
			}
		}
	}

	.filter-wrapper {
		margin-bottom: 230px;

		.first-line {
			.text {
				font-size: 84px;
				line-height: 1.2;
			}

			.btn-wrapper {
				.btn-filter {
					padding: 15px;

					.title {
						font-size: 20px;
					}
				}

				.btn-filter {
					.circle {
						width: 20px;
						height: 20px;

						&::after {
							width: 10px;
							height: 10px;
						}
					}
				}
			}

			.filter {
				.line-content {
					width: 13%;
					margin-right: 10px;
				}

				.vertical-line {
					width: 90px;
					margin: 20px -20px 0 -20px;
					height: 1px;
				}
			}
		}

		.second-line {
			margin-left: calc(10% + 44px);

			.text {
				font-size: 47px;
			}
		}

		.work-description {
			margin-left: calc(10% + 15px);
			margin-right: 110px;

			.description {
				font-size: 20px;

				.first-letter {
					font-size: 56px;
				}
			}
		}

		.animate-dot {
			&.line-left-text {
				top: 54%;

				div {
					@include line-common (40px, 6px, 36px);
				}
			}

			&.line-right-text {
				bottom: 61%;

				div {
					@include line-common (20px, 1px, 14px);
				}
			}
		}
	}


	.osx-content {
		.filter-wrapper {
			.second-line {
				.line-content {
					.line-dot {
						margin-right: -10px;
					}
				}
			}

			.line-content {
				.line-dot {
					margin-left: -12px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					min-width: 400px;
				}
			}
		}
	}

	.filter-wrapper {
		.first-line {
			.text {
				font-size: 70px;
			}

			.btn-wrapper {
				margin-top: 15px;

				.btn-filter {
					.title {
						font-size: 18px;
					}
				}

				.btn-filter {
					padding: 10px;

					.circle {
						width: 15px;
						height: 15px;

						&::after {
							width: 9px;
							height: 9px;
							top: 1px;
							left: 1px;
						}
					}
				}
			}

			.line-content {
				width: 10%;
				margin-right: 10px;
			}

			.filter {
				.vertical-line {
					width: 85px;
					margin: 20px -25px 0 -25px;
					height: 1px;
				}
			}

		}

		.second-line {
			margin-left: calc(10% + 12px);

			.text {
				font-size: 41px;
			}
		}

		.work-description {
			margin-left: calc(10% + 15px);
			margin-right: 52px;

			.description {
				font-size: 20px;

				.first-letter {
					font-size: 60px;
				}
			}

			.space {
				font-size: 44px;
			}
		}

		.animate-dot {
			&.line-left-text {
				top: 47%;
				left: 3%;

				div {
					@include line-common (20px, 5px, 22px);
				}
			}

			&.line-right-text {
				transform: rotate(90deg);
				right: 12%;
				bottom: -45%;

				div {
					@include line-common (31px, 4px, 20px);
				}
			}
		}
	}

	.osx-content {
		.filter-wrapper {
			.first-line {
				.line-content {
					width: 8%;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {
	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					min-width: 170px;
				}
			}
		}
	}

	.works-page {

		.content-wrapper {
			margin-top: 40px;

			.filter-wrapper {
				margin-bottom: 50px;
				margin-top: -55px;

				.first-line {
					.text {
						font-size: 30px;
					}

					.btn-wrapper {
						margin-top: -1px;

						.btn-filter {
							.title {
								font-size: 10px;
							}

							.circle {
								margin-left: 3px;
							}
						}

						.btn-filter {
							padding: 5px;

							.circle {
								width: 12px;
								height: 12px;

								&::after {
									width: 6px;
									height: 6px;
									top: 1px;
									left: 1px;
								}
							}
						}

					}

					.filter {
						.vertical-line {
							width: 25px;
							margin: 2px -4px 0 -6px;
							height: 1px;
						}
					}

					.line-content {
						.line-dot {
							font-size: 44px;
						}

						.horizontal-line {
							top: -1.5px;
						}
					}

				}

				.second-line {
					margin-left: calc(10% + 10px);

					.text {
						font-size: 16px;
					}

					.line-content {
						.line-dot {
							margin-right: -3px;
							font-size: 44px;
							margin-top: 4px;
						}

						.horizontal-line {
							top: -1px;
						}
					}
				}

				.work-description {
					margin: 150px 45px 0 45px;

					.description {
						font-size: 12px;

						.first-letter {
							font-size: 30px;
							line-height: 1.2;
							padding-right: 20px;
						}
					}

					.space {
						display: none;
					}
				}

				.line-content {
					.horizontal-line {
						height: 1px;
					}

					.line-dot {
						font-size: 78px;
						margin-left: -4px;
					}
				}

				.animate-dot {
					&.line-left-text {
						top: 25%;
						left: 4%;

						div {
							@include line-common (11px, 2px, 10px);
						}
					}

					&.line-right-text {
						transform: unset;
						right: 9%;
						bottom: 50%;

						div {
							@include line-common (10px, 3px, 8px);
						}
					}
				}

			}
		}
	}

	.osx-content {
		.content-wrapper {
			.filter-wrapper {
				.second-line {
					margin-left: calc(10% + 5px);
				}
			}
		}

		.filter-wrapper {
			.first-line {
				.line-content {
					margin-right: 12px;
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					width: 160px;
				}
			}
		}
	}

	.works-page {
		.content-wrapper {
			.filter-wrapper {
				.work-description {
					margin: 130px 32px 0 32px;
				}

				.animate-dot {
					&.line-left-text {
						left: 6%;

						div {
							@include line-common (10px, 1px, 8px);
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.creativeSol {
		.filter-wrapper {
			.second-line {
				.text {
					width: 153%;
				}
			}
		}
	}
}