#universe {
	z-index: 1;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-position: center 40%;
	background-repeat: no-repeat;
	background-size: cover;

	#galaxy {
		position: relative;
		width: 100%;
		height: 100%;

		#solar-system {
			position: absolute;
			top: -150px;
			width: 100%;
			height: 100%;
			transform-style: preserve-3d;

			.orbit {
				position: absolute;
				top: 50%;
				left: 47%;
				border: 1px solid rgba(255, 255, 255, .2);
				border-radius: 50%;
				transform-style: preserve-3d;
				animation-name: orbit;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}

			#sun {
				z-index: 1;
				width: 140px;
				height: 140px;
				border-radius: 50%;
				background: $yellow-main;
				border-color: $yellow-main;
				font-size: 18px !important;
				font-weight: 700;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: 'Montserrat Bold';

				img {
					width: 100%;
					height: 100%;
				}
			}

			#sun,
			.planet {
				position: absolute;
				top: 50%;
				left: 47%;
				margin-top: -.5em;
				margin-left: -65px;
			}

			.planet {
				display: flex;
			}
		}
	}

	.pos {
		position: absolute;
		top: 50%;
		width: 2em;
		height: 2em;
		margin-top: -1em;
		margin-left: -1em;
		transform-style: preserve-3d;
		animation-name: invert;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

#earth {
	z-index: 8;
}

.view-3D .opening #sun,
.view-3D .opening .orbit,
.view-3D .opening .planet,
.view-3D .opening .pos {
	transition-duration: 4s;
}

#universe.opening #sun {
	box-shadow: 0 0 0 rgba(255, 160, 60, 0);
}

.view-3D.zoom-large #sun {
	transform-style: preserve-3d;
}

.view-3D.zoom-large .orbit {
	transform-style: preserve-3d;
}

.view-3D #solar-system {
	transform: rotateX(60deg);
}

.view-3D #sun {
	transform: rotateX(-70deg);
}

.view-3D .moon,
.view-3D .planet {
	transform: rotateX(15deg);
}

.zoom-large #solar-system {
	width: 100%;
}

.zoom-large.view-3D .scale-stretched #solar-system {
	font-size: 62%;
}

.zoom-large.view-3D .scale-d #solar-system {
	font-size: 48%;
}

.zoom-large.view-3D .scale-s #solar-system {
	font-size: 14%;
}

#neptune .planet,
#neptune .pos,
#neptune.orbit {
	animation-duration: 50s;
}

.scale-stretched #sun {
	font-size: 18em;
}

.scale-stretched #mercury .planet {
	font-size: 1.5em;
}

.scale-stretched #venus .planet {
	font-size: 3.72em
}

.scale-stretched #earth .planet {
	font-size: 3.92em;
}

.scale-stretched #earth .moon {
	font-size: 1.2em;
}

.scale-stretched #mars .planet {
	font-size: 2.9em;
}

.scale-stretched #jupiter .planet {
	font-size: 12em;
}

.scale-stretched #saturn .planet {
	font-size: 10.8em;
}

.scale-stretched #uranus .planet {
	font-size: 4.68em;
}

.scale-stretched #neptune .planet {
	font-size: 20em;
}

.scale-stretched #neptune.orbit {
	width: 190em;
	height: 190em;
	margin-top: -85em;
	margin-left: -95em;
}

#neptune .pos {
	left: 50%;
	top: 0;
}

#neptune .pos-2 {
	left: 10%;
	top: 80%;
}

#neptune .pos-5 {
	left: 10%;
	top: 20%;
}

#neptune .pos-3 {
	left: 100%;
	top: 50%;
}

#neptune .pos-4 {
	left: 50%;
	top: 100%;
}

.scale-stretched #neptune .planet {
	animation-name: scale-fontsize;
	animation-duration: 50s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.scale-stretched #neptune .planet-2 {
	animation-name: scale-fontsize-2;
}

.scale-stretched #neptune .planet-5 {
	animation-name: scale-fontsize-2;
}

.scale-stretched #neptune .planet-3 {
	animation-name: scale-fontsize-3;
}

.scale-stretched #neptune .planet-4 {
	animation-name: scale-fontsize-4;
}

.scaled.view-3D #neptune .planet {
	box-shadow: inset -12px 0 5px rgba(0, 0, 0, .5);
}

.client-image {
	dt {
		width: 1em;
		height: 1em;
		border-radius: 50%;
		transform-style: preserve-3d;
		background-repeat: no-repeat;
		background-size: cover;
		animation-iteration-count: infinite;
		animation-timing-function: linear;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}
}

.infos {
	position: absolute;
	display: block;
	opacity: 0;
	width: 400px;
	height: 100%;
	margin-top: -45%;
	margin-left: 90%;
	padding-left: 100%;
	transform-origin: 100% 100%;
	transform-style: preserve-3d;
	transform: rotateX(90deg);
}

.infos {
	span {
		font-size: 13px;
		color: #abbcea;
		line-height: 20px;
		display: block;
	}

	.quote {
		font-family: 'Montserrat Thin Italic';
		margin-bottom: 15px;
	}

	.name {
		font-family: 'Montserrat Bold';
	}

	.position,
	.operator {
		font-family: 'Montserrat Thin';
	}
}

dl.infos dt {
	position: absolute;
	left: 50px;
	margin-bottom: 26px;
	bottom: 30px;
	color: #fff;
	font-size: 14px;
	transform: rotateX(-72deg);
	text-shadow: 1px 1px 2px #000;
}

dl.infos dd:after {
	position: absolute;
	left: 50px;
	bottom: 30px;
	width: 300px;
	color: #fff;
	font-size: 22px;
	text-shadow: 1px 1px 2px #000;
}

dl.infos dd span:after {
	position: absolute;
	left: 50px;
	bottom: 14px;
	width: 300px;
	color: #fff;
	font-size: 11px;
	text-shadow: 1px 1px 2px #000;
}

.neptune #neptune .infos {
	display: block;
	opacity: 1;
	transform: rotateX(0);
}

.neptune #neptune.orbit {
	border: 1px solid rgba(255, 255, 255, .8);
}

.hide-UI dl.infos {
	opacity: 1 !important;
}

.hide-UI .orbit {
	border: 1px solid rgba(255, 255, 255, .2) !important;
}

.pos {
	transition-property: top, left;
}

#universe {
	height: 1000px;
	margin-bottom: 100px;
	margin-top: -80px;
}

#solar-system,
.orbit,
.planet {
	transition-property: width, height, top, left, margin-left, margin-top, webkit-transform, font-size;
	z-index: 1;
}

#sun {
	transition-property: width, height, webkit-transform;
}

#solar-system,
#sun,
.infos,
.orbit,
.planet,
.pos {
	transition-duration: .8s;
	transition-timing-function: ease-in-out;
}

#solar-system,
#sun,
.planet {
	transition-delay: 0s;
}

.pos {
	transition-delay: 1s;
}

.opening #solar-system,
.opening #sun,
.opening .infos,
.opening .orbit,
.opening .planet,
.opening .pos,
.opening dl.infos {
	transition-duration: 0s;
	transition-delay: 0s;
}

.opening .pos {
	transition-delay: 0s;
}

@media screen and (max-width:299px) {
	#universe {
		font-size: 20%;
	}
}

@media screen and (min-width:300px) {
	#universe {
		font-size: 24%;
	}
}

@media screen and (min-width:500px) {
	#universe {
		font-size: 36%;
	}
}

@media screen and (min-width:600px) {
	#universe {
		font-size: 44%;
	}
}

@media screen and (min-width:760px) {
	#universe {
		font-size: 58%;
	}
}

@media screen and (min-width:1000px) {
	#universe {
		font-size: 73%;
	}
}

@media screen and (min-width:1300px) {
	#universe {
		font-size: 100%;
	}
}

@media screen and (min-width:1600px) {
	.zoom-close .scale-d #solar-system {
		margin-left: -120%
	}
}

@media screen and (max-width: 1440px) {
	.zoom-large {
		&.view-3D {
			.scale-stretched {
				#solar-system {
					font-size: 53%;
				}
			}
		}
	}

	#universe {
		// margin-bottom: 0;
		margin-bottom: -100px;

		#galaxy {
			#solar-system {
				top: -15%;

				#sun {
					width: 120px;
					height: 120px;
					font-size: 14px !important;
				}

				.planet {
					margin-top: -55px;
					margin-left: -55px;

					.infos {
						width: 330px;
					}

					.client-image {
						dt {
							width: 1.3em;
							height: 1.3em;
						}
					}
				}

				.orbit {
					left: 46%;
				}
			}
		}
	}

	.scale-stretched {
		#neptune {
			.planet {
				font-size: 16em;
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.zoom-large {
		&.view-3D {
			.scale-stretched {
				#solar-system {
					font-size: 45%;
				}
			}
		}
	}

	#universe {
		margin-bottom: -200px;

		#galaxy {
			#solar-system {
				top: -18%;

				#sun {
					width: 110px;
					height: 110px;
				}

				.planet {
					margin-top: -55px;
					margin-left: -45px;

					.client-image {
						dt {
							width: 1.4em;
							height: 1.4em;
						}
					}
				}

				.orbit {
					left: 45%;
				}
			}
		}
	}

	.scale-stretched {
		#neptune {
			.planet {
				font-size: 16em;
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	#universe {

		#galaxy {
			#solar-system {

				.planet {
					margin-top: -40px;
					margin-left: -40px;

					.client-image {
						dt {
							width: 1.7em;
							height: 1.7em;
						}
					}

					.infos {

						width: 300px;

						span {
							font-size: 10px;
						}
					}
				}

				.orbit {
					width: 235em;
					height: 235em;
					margin-top: -96em;
					margin-left: -118em;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	#universe {

		#galaxy {
			#solar-system {

				.planet {

					.client-image {
						dt {
							width: 1.5em;
							height: 1.5em;
						}
					}
				}

				.orbit {
					width: 200em;
					height: 200em;
					margin-top: -90em;
					left: 50%;
				}

				#sun {
					width: 90px;
					height: 90px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	#universe {
		margin-bottom: 0;

		#galaxy {
			#solar-system {
				top: -23%;

				.planet {
					margin-top: -30px;
					margin-left: -30px;

					.client-image {
						z-index: 1;
					}

					.infos {
						span {
							font-size: 8px;
						}
					}
				}

				.orbit {
					width: 200em;
					height: 200em;
					margin-top: -90em;
					left: 55%;
				}

				#sun {
					width: 70px;
					height: 70px;
					left: 54%;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	/* Inherit from 800 */
}

@media screen and (max-width: 576px) {}

@media screen and (max-width: 320px) {}